import { Tag } from 'antd';
import { FormatMessage } from '../../hocs';
import PropTypes from 'prop-types';

const BooleanColumn = ({ name = 'active', label, width } = {}) => {
  return {
    title: <FormatMessage id={label ? label : name} />,
    dataIndex: name,
    key: name,
    width: width ? width : 180,
    render: (text) => {
      let color = text ? 'green' : 'volcano';
      return (
        <Tag color={color} key={text}>
          {text ? <FormatMessage id='active' /> : <FormatMessage id='passive' />}
        </Tag>
      );
    },
  };
};

BooleanColumn.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.number,
};

export default BooleanColumn;
