import { Radio, Form, Modal } from 'antd';
import React, { useEffect } from 'react';
import { CouponModalTypes } from '..';
import { TextItem, SwitchItem, NumberItem, TextEditorItem } from '../../../../components';
import DatePickerItem, { dateFormat } from '../../../../components/formItems/DatePickerItem';
import { FormatMessage } from '../../../../hocs';
import { successMessage } from '../../../elements/ToastMessage';
import moment from 'moment';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddEditCouponModal = ({ isVisible, type, item, onCancel, validationErrors, addCoupon, updateCoupon, isSaving, setValidationErrors }) => {
  const [form] = Form.useForm();
  const [amountOrPercentage, setAmountOrPercentage] = React.useState(item?.percentage ? "percentage" : "amount");

  React.useEffect(() => {
    form.resetFields();

    if (isVisible && type === CouponModalTypes.UPDATE) {
      const updatedItem = {
        ...item,
        percentage: item?.percentage ? item.percentage * 100 : 0,
        starts: item?.startsAt ? moment(item.startsAt, dateFormat) : '',
        valid: item?.validUntil ? moment(item.validUntil, dateFormat) : '',
      };

      form.setFieldsValue({ ...updatedItem });
    }

  }, [item, isVisible, type, form]);

  useEffect(() => {
    setAmountOrPercentage(item?.percentage ? "percentage" : "amount");
  }, [item?.percentage])

  const handleOk = async () => {
    try {
      if (type === CouponModalTypes.ADD) {
        form
          .validateFields()
          .then(async (data) => {
            const dto = {
              ...data,
              percentage: data.percentage ? data.percentage / 100 : null,
              startsAt: data.starts?.format('YYYY-MM-DD HH:mm') ?? item.startsAt ?? null,
              validUntil: data.valid?.format('YYYY-MM-DD HH:mm') ?? item.validUntil ?? null,
            };

            const response = await addCoupon(dto);

            if (response) {
              successMessage('coupons.addSuccessMessage');
              form.resetFields();
              onCancel();
            }
          })
          .catch((e) => {
            setValidationErrors(e.errorFields);
          });
      }

      if (type === CouponModalTypes.UPDATE) {
        form
          .validateFields()
          .then(async (data) => {
            const dto = {
              ...data,
              id: item.id,
              percentage: data.percentage ? data.percentage / 100 : null,
              startsAt: data.starts?.format('YYYY-MM-DD HH:mm') ?? item.startsAt ?? null,
              validUntil: data.valid?.format('YYYY-MM-DD HH:mm') ?? item.validUntil ?? null,
            };

            const response = await updateCoupon(dto);

            if (response) {
              onCancel();
            }
          })
          .catch((e) => console.log('Error when updated coupon', e));
      }
    } catch (e) {
      console.log('error from add or edit coupon modal');
    }
  };

  /**
   * @description Radio buttons options
   */
  const amountOrPercentageOptions = [
    { label: FormatMessage({ id: "amount" }), value: 'amount' },
    { label: FormatMessage({ id: "percentage" }), value: 'percentage' },
  ];

  const onCancelModal = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title={<FormatMessage id={type === CouponModalTypes.ADD ? 'coupons.addModalTitle' : 'coupons.editModalTitle'} />}
      visible={isVisible}
      closable
      centered
      onCancel={onCancelModal}
      confirmLoading={isSaving}
      onOk={() => handleOk()}
    >
      <Form form={form} name='control-ref' initialValues={{ ...item }} layout='horizontal'>
        <TextItem isRequired name='code' label='code' layout={{ ...formItemLayout }} validationErrors={validationErrors} />
        <TextEditorItem
          isRequired
          name='description'
          label='description'
          layout={{ ...formItemLayout }}
          validationErrors={validationErrors}
        />
        <NumberItem
          isRequired
          name='minAmount'
          label={FormatMessage({ id: 'minAmount' })}
          layout={{ ...formItemLayout }}
          validationErrors={validationErrors}
          inputProps={{ min: 0 }}
          rules={{ min: 0 }}
        />

        <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", marginBottom: "15px" }}>
          <Radio.Group
            options={amountOrPercentageOptions}
            name='amountOrPercentage'
            layout={{ ...formItemLayout }}
            value={amountOrPercentage}
            onChange={(e) => setAmountOrPercentage(e.target.value)}
          />
        </div>

        {amountOrPercentage === 'amount' && (
          <NumberItem
            isRequired={amountOrPercentage === 'amount'}
            name='amount'
            label={FormatMessage({ id: 'amount' })}
            validationErrors={validationErrors}
            layout={{ ...formItemLayout }}
            inputProps={{ min: 0 }}
            rules={{ min: 0 }}
          />
        )}

        {amountOrPercentage === 'percentage' && (
          <NumberItem
            isRequired={amountOrPercentage === 'percentage'}
            name='percentage'
            label={FormatMessage({ id: 'percentage' })}
            validationErrors={validationErrors}
            layout={{ ...formItemLayout }}
            inputProps={{ min: 1, max: 99 }}
            rules={{ min: 1, max: 99 }}
          />
        )}

        <DatePickerItem
          name='starts'
          label='startsAt'
          value={form.getFieldValue('starts')}
          validationErrors={validationErrors}
          layout={{ ...formItemLayout }}
        />

        <DatePickerItem
          name='valid'
          label='validUntil'
          value={form.getFieldValue('valid')}
          validationErrors={validationErrors}
          layout={{ ...formItemLayout }}
        />

        <SwitchItem 
          name='active' 
          label='active' 
          value={item.active} 
          validationErrors={validationErrors} 
          layout={{ ...formItemLayout }} 
        />

        <SwitchItem
          name='multipleUsage'
          label='multipleUsage'
          value={item.multipleUsage}
          validationErrors={validationErrors}
          layout={{ ...formItemLayout }}
        />

        <SwitchItem
          name='oneTimeOnly'
          label='oneTimeOnly'
          value={item.oneTimeOnly}
          validationErrors={validationErrors}
          layout={{ ...formItemLayout }}
        />
      </Form>
    </Modal>
  );
};

export default AddEditCouponModal;
