import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Input, Space, Button, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import intl from 'react-intl-universal';
import { FormatMessage } from '../../../hocs';
import GLayout from '../../gLayout';
import useChecklistCategories from '../../../hooks/useChecklistCategories';
import AddEditChecklistCategory from './_add';
import styles from './checklistCategories.module.css';
import { BasicColumn, BooleanColumn, IdColumn, ImageColumn } from '../../../components';

const { confirm } = Modal;
const { Search } = Input;

const ChecklistCategories = () => {
  const {
    filteredChecklistCategories,
    checklistCategoryKeyword,
    setChecklistCategoryKeyword,
    fetchChecklistCategories,
    saveChecklistCategory,
    loadingChecklistCategories,
    deleteChecklistCategory,
    setChecklistCategoryModal,
    checklistCategoryModal,
    isDeletingChecklistCategory,
    fetchInnerChecklistCategories,
    nestedData,
    isLoading,
    eventTypeEnums,
    validationErrors,
    setValidationErrors,
  } = useChecklistCategories();

  useEffect(() => {
    fetchChecklistCategories();
  }, []);

  useEffect(() => {
    console.log(nestedData);
  }, [nestedData]);

  const showDeleteConfirm = (record) => {
    confirm({
      title: <FormatMessage id='checklistCategories.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingChecklistCategory,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteChecklistCategory(record.id, record.parentId);
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const columns = [
    IdColumn(),
    BasicColumn({ name: 'name' }),
    {
      title: <FormatMessage id='eventType' />,
      dataIndex: 'eventType',
      key: 'eventType',
      render: (eventType) => {
        if (eventTypeEnums) {
          const translatedKey = eventTypeEnums.filter((e) => e.key === eventType)[0];
          return translatedKey?.value ? translatedKey?.value : eventType;
        } else {
          return eventType;
        }
      },
    },
    ImageColumn({ name: 'imageUri', label: 'image' }),
    {
      title: <FormatMessage id='categories.title' />,
      dataIndex: 'categories',
      key: 'categories',
      width: 180,
      render: (cats, data, a) => {
        return cats && cats.length > 0 && cats.map((c) => c.name).join(',');
      },
    },
    BasicColumn({ name: 'displayOrder' }),
    BooleanColumn(),
    {
      title: <FormatMessage id='actions' />,
      key: 'action',
      width: 180,
      render: (text, record) => (
        <Space size='middle'>
          <Tooltip title={<FormatMessage id='edit' />}>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setChecklistCategoryModal({
                  type: 'edit',
                  isVisible: true,
                  item: record,
                });
              }}
            />
          </Tooltip>

          <Tooltip title={<FormatMessage id='delete' />}>
            <Button danger onClick={() => showDeleteConfirm(record)} icon={<DeleteOutlined />} />
          </Tooltip>
          <Tooltip title={<FormatMessage id='checklistCategories.addModalTitle' />}>
            {!record.parentId && (
              <Button
                type='success'
                ghost
                onClick={() => {
                  setChecklistCategoryModal({
                    isVisible: true,
                    type: 'new',
                    item: {},
                    checklistCategory: record,
                  });
                }}
                icon={<PlusOutlined />}
              />
            )}
          </Tooltip>
        </Space>
      ),
    },
  ];

  const expandedRowRender = (record, i) => {
    const data = nestedData[record.id];
    return (
      <Table
        rowKey={(record) => record.id}
        bordered
        showHeader={false}
        loading={isLoading[record.id]}
        columns={columns}
        pagination={false}
        dataSource={data}
        rowClassName={styles.rowGrey}
      />
    );
  };

  const handleExpand = (expanded, record) => {
    fetchInnerChecklistCategories({ parentId: record.id });
  };

  return (
    <GLayout>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setChecklistCategoryKeyword(e.target.value)}
              value={checklistCategoryKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              disabled={loadingChecklistCategories}
              onClick={() =>
                setChecklistCategoryModal({
                  isVisible: true,
                  type: 'new',
                  item: {},
                })
              }
              style={{ float: 'right' }}
            >
              <FormatMessage id='checklistCategories.addModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        bordered
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={filteredChecklistCategories}
        loading={loadingChecklistCategories}
        scroll={{ x: 1500 }}
        expandable={{ expandedRowRender }}
        onExpand={handleExpand}
        sticky
      />
      {checklistCategoryModal.isVisible && (
        <AddEditChecklistCategory
          onHide={() => {
            setValidationErrors({});
            setChecklistCategoryModal({
              ...checklistCategoryModal,
              isVisible: false,
            });
          }}
          {...checklistCategoryModal}
          saveChecklistCategory={saveChecklistCategory}
          eventTypeEnums={eventTypeEnums}
          validationErrors={validationErrors}
        />
      )}
    </GLayout>
  );
};

export default ChecklistCategories;
