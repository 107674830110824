import { useState, useEffect } from "react";
import { apiGet, apiDelete, apiPost, apiPut } from "../utils/api";
import { RESPONSE_STATS } from "../utils/constants";
import { successMessage, errorMessage, errorResponseMessage } from "../views/elements/ToastMessage";
export default function useDealers(props) {
  const [dealers, setDealers] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [loadingDealers, setLoadingDealers] = useState(false);
  const [dealerFailed, setDealerFailed] = useState(false);
  const [isDeletingDealer, setIsDeletingDealer] = useState(false);
  const [savingDealer, setSavingDealer] = useState(false);
  const [filteredDealers, setFilteredDealers] = useState([]);
  const [dealerKeyword, setDealerKeyword] = useState("");
  const [pagination, setPagination] = useState({});
  const [dealerModal, setDealerModal] = useState({
    isVisible: false,
    type: "add",
    item: false,
  });

  const [saveDealerResult, setSaveDealerResult] = useState(RESPONSE_STATS.NOTR);
  const [editDealerResult, setEditDealerResult] = useState(RESPONSE_STATS.NOTR);
  const [deleteDealerResult, setDeleteDealerResult] = useState(
    RESPONSE_STATS.NOTR
  );

  const [dealerBrands, setDealerBrands] = useState([]);
  const [dealerBrandsLoading, setDealerBrandLoading] = useState(true);

  const [dealerBrandProducts, setDealerBrandProducts] = useState([]);
  const [dealerBrandProductsLoading, setDealerBrandProductsLoading] = useState([]);

  useEffect(() => {
    console.log(dealers);
    if (dealers && dealers.content) {
      console.log(dealers.content);
      setFilteredDealers(
        dealers.content.filter(
          (item) =>
            item.name?.toLowerCase().includes(dealerKeyword.toLowerCase()) || dealerKeyword.length === 0
        )
      );
    }
  }, [dealerKeyword, dealers]);

  const saveDealer = async (dealer, type) => {
    setSavingDealer(true);

    const AddressFieldsValidations = () => {

      let errors = {};

      const validationMap = {
        phone: () => {
          if (!(dealer.address.phone) || dealer.address.phone === '') {
            errors.phone = 'Telefon Numarası giriniz.';
          }
        },
        address: () => {
          if (!(dealer.address.address) || dealer.address.address === '') {
            errors.address = 'Adres giriniz.';
          }
        },
        city: () => {
          if (!(dealer.address.cityId)) {
            errors.cityId = 'Şehir giriniz.';
          }
        },
        countyId: () => {
          if (!(dealer.address.countyId)) {
            errors.countyId = 'İlçe giriniz.';
          }
        },
        districtId: () => {
          if (!(dealer.address.districtId)) {
            errors.districtId = 'Mahalle giriniz.';
          }
        }
      }

      Object.keys(validationMap).forEach(key => {
        validationMap[key]();
      });

      setValidationErrors(errors);
      return Object.keys(errors).length === 0;
    }


    const validateAddressFields = AddressFieldsValidations()
    if (!validateAddressFields) {
      setSavingDealer(false);
      return;
    }

    const response =
      type === "new"
        ? await apiPost("/dealers", dealer)
        : await apiPut("/dealers", dealer);
    setSavingDealer(false);
    if (response && !response.errors && !response.error) {
      setDealerModal({
        ...dealerModal,
        item: type === "edit" ? response.data : response,
        type: "edit",
        isVisible: false,
      });
      type === "new"
        ? setSaveDealerResult(RESPONSE_STATS.SUCCESS)
        : setEditDealerResult(RESPONSE_STATS.SUCCESS);
      setValidationErrors({});
      fetchDealers();

      if (type === "edit" && dealer.password) {
        const { password, userId } = dealer;

        const requestContent = {
          password,
          confirm: password
        }

        const passwordResponse = await apiPost(`/users/${userId}/set-password`, requestContent);
        if (passwordResponse && !passwordResponse.errors && !passwordResponse.error) {
          // herhangi bir mesaj vermeye gerek yok.
        } else if (passwordResponse.errors) {
          errorResponseMessage(passwordResponse.errors[0].message);
        } else if (passwordResponse.error) {
          errorResponseMessage(passwordResponse.error);
        }
      }

    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if (response.error) {
      errorResponseMessage(response.error);
    } else {
      type === "new"
        ? setSaveDealerResult(RESPONSE_STATS.FAIL)
        : setEditDealerResult(RESPONSE_STATS.FAIL);
      setValidationErrors({});
    }
  };

  const deleteDealer = async (id) => {
    setIsDeletingDealer(true);
    const response = await apiDelete("/dealers/" + id);
    setIsDeletingDealer(false);
    if (response) {
      setDeleteDealerResult(RESPONSE_STATS.SUCCESS);
      fetchDealers();
    } else {
      setDeleteDealerResult(RESPONSE_STATS.FAIL);
    }
  };

  const fetchDealers = async ({
    page = 0,
    pageSize = 10,
    asc = false,
  } = {}) => {
    setLoadingDealers(true);

    const response = await apiGet(
      `/dealers?page=${page}&pageSize=${pageSize}&asc=${asc}`
    );
    console.log(response);
    if (response === false) {
      setDealerFailed(true);
    } else {
      setDealers(response);
      if (response.totalElements < 11) {
        setPagination(false);
      } else {
        setPagination({
          current: response.page + 1,
          pageSize: response.pageSize,
          total: response.totalElements,
          showSizeChanger: true,
        });
      }
    }
    setLoadingDealers(false);
  };

  const getDealerBrandsOrProducts = async ({ dealerId, brandId = null }) => {

    const requestUrl = brandId ? `/dealers/${dealerId}/brands/${brandId}` : `/dealers/${dealerId}/brands`;
    const response = await apiGet(`${requestUrl}`);

    if(brandId) {
      setDealerBrandProducts(response);
      setDealerBrandProductsLoading(false); 
    } else {
      setDealerBrandLoading(false);
      setDealerBrands(response);
    }

    return response;
  }

  const getDealers = async () => {
    setLoadingDealers(true);
    setTimeout(() => {
      const response = [
        {
          id: 1,
          active: true,
          name: "test",
          taxOffice: "123123213",
          identityNumber: "123123213",
          lastLogin: null,
          email: "sadasds",
          website: "sadsad",
          phone: "431434",
          tradeRegisterNo: "123213123",
          mersisNo: "21233123",
          userId: 2,
        },
        {
          id: 2,
          active: true,
          name: "Hakan AS",
          taxOffice: "Yenimah Vergi Dairesi",
          identityNumber: "1234567890",
          lastLogin: null,
          email: "email@emaildeneme.com",
          website: "www.testttt.com.tr",
          phone: "5378985658",
          tradeRegisterNo: "712636123",
          mersisNo: "1234124123",
          userId: 3,
        },
      ];
      if (response === false) {
        setDealerFailed(true);
      } else {
        setDealers(response);
        if (response.totalElements < 11) {
          setPagination(false);
        } else {
          setPagination({
            current: response.page + 1,
            pageSize: response.pageSize,
            total: response.totalElements,
            showSizeChanger: true,
          });
        }
      }
      setLoadingDealers(false);
    }, 1500);
  };

  useEffect(() => {
    if (saveDealerResult === RESPONSE_STATS.SUCCESS) {
      successMessage("saveSuccessMessage");
    } else if (saveDealerResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setSaveDealerResult(RESPONSE_STATS.NOTR);
  }, [saveDealerResult]);

  useEffect(() => {
    if (editDealerResult === RESPONSE_STATS.SUCCESS) {
      successMessage("editSuccessMessage");
    } else if (editDealerResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setEditDealerResult(RESPONSE_STATS.NOTR);
  }, [editDealerResult]);

  useEffect(() => {
    if (deleteDealerResult === RESPONSE_STATS.SUCCESS) {
      successMessage("deleteSuccessMessage");
    } else if (deleteDealerResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setDeleteDealerResult(RESPONSE_STATS.NOTR);
  }, [deleteDealerResult]);

  return {
    dealers,
    fetchDealers,
    deleteDealer,
    loadingDealers,
    isDeletingDealer,
    dealerFailed,
    dealerModal,
    setDealerModal,
    filteredDealers,
    dealerKeyword,
    setDealerKeyword,
    saveDealer,
    savingDealer,
    saveDealerResult,
    pagination,
    getDealers,
    validationErrors,
    setValidationErrors,
    dealerBrandProducts,
    dealerBrandProductsLoading,
    dealerBrandsLoading,
    dealerBrands,
    getDealerBrandsOrProducts
  };
}
