import React from 'react';
import { Form, Input } from 'antd';
import { FormatMessage } from '../../hocs';
import PropTypes from 'prop-types';

const TextItem = ({ name = '', label = '', layout = null, onBlur = null, isRequired = false, type = 'text', validationErrors = null, disabled = false}) => {
  return (
    <Form.Item
      name={name}
      rules={[{ required: isRequired }]}
      label={<FormatMessage id={label ? label : name} />}
      validateStatus={validationErrors?.[name] && 'error'}
      help={validationErrors?.[name] ? FormatMessage({id: validationErrors?.[name]}) ?? validationErrors?.[name] : null}
      {...layout}
    >
      <Input onBlur={onBlur} type={type} disabled={disabled}/>
      
    </Form.Item>
  );
};

TextItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  layout: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  type: PropTypes.string,
  validationErrors: PropTypes.object
};

export default TextItem;
