import { useState, useEffect } from "react";
import { apiGet, apiDelete, apiPost, apiPut } from "../utils/api";
import { RESPONSE_STATS } from "../utils/constants";
import { successMessage, errorMessage, errorResponseMessage } from "../views/elements/ToastMessage";
export default function useTags(props) {
  const [tags, setTags] = useState([]);
  const [loadingTags, setLoadingTags] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [tagFailed, setTagFailed] = useState(false);
  const [deleteTagFailed, setDeleteTagFailed] = useState(false);
  const [isDeletingTag, setIsDeletingTag] = useState(false);
  const [filteredTags, setFilteredTags] = useState([]);
  const [tagKeyword, setTagKeyword] = useState("");
  const [tagModal, setTagModal] = useState({
    isVisible: false,
    type: "add",
    item: false,
  });
  const [pagination, setPagination] = useState({});

  const [savingTag, setSavingTag] = useState(false);

  useEffect(() => {
    if (tags && tags.content) {
      setFilteredTags(
        tags.content.filter(
          (item) => item.title?.toLowerCase().includes(tagKeyword.toLowerCase()) || tagKeyword.length === 0
        )
      );
    }
  }, [tagKeyword, tags]);

  const saveTag = async (tag, type) => {
    setSavingTag(true);
    const response =
      type === "new" ? await apiPost("/tags", tag) : await apiPut("/tags", tag);
    setSavingTag(false);
    if (response && !response.errors && !response.error) {
      setTagModal({
        ...tagModal,
        item: type === "edit" ? response.data : response,
        type: "edit",
        isVisible: false,
      });
      type === "new"
        ? successMessage("saveSuccessMessage")
        : successMessage("editSuccessMessage");
      setValidationErrors({});
      fetchTags();
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error)
    } else {
      setValidationErrors({});
      errorMessage("failMessage");
    }
  };

  const deleteTag = async (id) => {
    setIsDeletingTag(true);
    const response = await apiDelete("/tags/" + id);
    setIsDeletingTag(false);
    if (response) {
      successMessage("deleteSuccessMessage");
      fetchTags();
    } else {
      errorMessage("failMessage");
    }
  };

  const fetchTags = async ({ page = 0, pageSize = 10, asc = false } = {}) => {
    setLoadingTags(true);

    const response = await apiGet(
      `/tags?page=${page}&pageSize=${pageSize}&asc=${asc}`
    );
    if (response === false) {
      setTagFailed(true);
    } else {
      setTags(response);
      if (response.totalElements < 11) {
        setPagination(false);
      } else {
        setPagination({
          current: response.page + 1,
          pageSize: response.pageSize,
          total: response.totalElements,
          showSizeChanger: true,
        });
      }
    }
    setLoadingTags(false);
    return response;
  };

  return {
    tags,
    fetchTags,
    deleteTag,
    loadingTags,
    isDeletingTag,
    tagFailed,
    deleteTagFailed,
    tagModal,
    setTagModal,
    filteredTags,
    tagKeyword,
    setTagKeyword,
    saveTag,
    savingTag,
    pagination,
    validationErrors,
    setValidationErrors,
  };
}
