const Locale = {
  username: 'Kullanıcı Adı',
  password: 'Şifre',
  login: 'Giriş Yap',
  forgotPassword: 'Şifremi unuttum',
  addInterest: 'İlgi Alanı Ekle',
  editInterest: 'Ilgi Alanı Düzenle',
  logout: 'Çıkış',
  settings: 'Ayarlar',
  adminPanel: 'Yönetim Paneli',
  edit: 'Düzenle',
  remove: 'Sil',
  add: 'Ekle',
  imageUri: 'Resim',
  upload: 'Yukle',
  selectFile: 'Dosya Sec (Csv)',
  id: '#ID',
  title: 'Başlık',
  eventType: 'Etkinlik Tipi',
  displayOrder: 'Sıralama',
  actions: 'İşlem',
  delete: 'Sil',
  deleteThis: 'Bunu silmek mi istiyorsunuz ?',
  norevert: 'Bu işlem geri alınamaz',
  deleted: 'Bu öğre silindi',
  saveSuccessMessage: 'Kayit islemi basarili.',
  editSuccessMessage: 'Guncelleme islemi basarili.',
  deleteSuccessMessage: 'Silme islemi basarili.',
  failMessage: 'Islem basarisiz.',
  fileSizeLarger1MB: 'Dosya boyutu maksimum 1 MB olabilir',
  filePngJpg: 'Dosya formatı JPG ve ya PNG olmalıdır',
  yes: 'Evet',
  cancel: 'İptal',
  select: 'Seç',
  save: 'Kaydet',
  isActive: 'Aktif ?',
  dropFiles: 'Imaj Seç',
  image: 'Resim',
  imageMsg: 'Sadece jpg,png formatında dosyalar yükleyiniz',
  uploadError: 'Yükleme Hatası',
  saveError: 'Kaydedilemedi',
  saveOk: 'Başarıyla kaydedildi',
  dashboard: 'Pano',
  active: 'Aktif',
  passive: 'Pasif',
  search: 'Arama',
  featured: 'One Cikan',
  logo: 'Logo',
  description: 'Aciklama',
  code: 'Kod',
  oneTimeOnly: "Yalnızca Bir Kez",
  startsAt: "Başlangıç Tarihi",
  amount: "Tutar",
  minAmount: "Minimum Tutar",
  multipleUsage: "Çoklu Kullanım",
  percentage: "Yüzde",
  validUntil: "Geçerlilik Tarihi",
  starts: "Başlangıç Tarihi",
  valid: "Geçerlilik Tarihi",
  addBrand: 'Marka Ekle',
  name: 'Isim',
  email: 'Email',
  identityNumber: 'Kimlik Numarasi',
  mersisNo: 'Mersis Numarasi',
  phone: 'Telefon',
  tradeRegisterNo: 'Ticaret Sicil Numarasi',
  userId: 'Kullanici Id',
  dealerId: 'Satici Id',
  website: 'Website',
  lastLogin: 'Son Giris',
  visible: 'Gorunurluk',
  type: 'Tip',
  extraInfo: 'Ek Bilgi',
  showHome: 'Ana Menu Gosterim',
  showTopMenu: 'Ust Menu Gosterim',
  definitions: 'Tanimlar',
  catalogs: 'Kataloglar',
  okText: 'Evet',
  cancelText: 'Hayir',
  taxOffice: 'Vergi Dairesi',
  city: 'Sehir',
  county: 'Ilce',
  district: 'Mahalle',
  postalCode: 'Posta Kodu',
  address: 'Adres',
  changePassword: 'Sifre Degistir',
  confirmPassword: 'Sifre Tekrar',
  details: 'Detaylar',
  brand: 'Marka',
  dealer: 'Satici',
  product: 'Urun',
  category: 'Kategori',
  barcode: 'Barkod',
  vatType: 'KDV',
  quantity: 'Adet',
  recommendedQuantity: 'Onerilen Adet',
  listPrice: 'Liste Fiyati',
  estimatedDelivery: "Tahmini Teslim",
  shipmentAndReturn: "Kargo ve İade",
  couponApplicable: "İndirim Uygulansın Mı ?",
  sellingPrice: 'Satis Fiyati',
  status: 'Durum',
  quantityStock: 'Stok Miktari',
  content: 'Icerik',
  cms: 'CMS',
  slug: 'Anahtar',
  link: 'Link',
  featuredCategory: 'One Cikan Kategori',
  cargoType: 'Kargo Tipi',
  deliveryType: 'Teslimat Tipi',
  cargoFirm: 'Kargo Şirketi',
  checkInputField: "Bu alanı kontrol ediniz.",
  customerName: 'Müşeri Adı',
  text: 'Yorum',
  writtenAt: 'Tarih',
  approve: 'Onayla',
  reject: 'Reddet',
  NEW: "YENI",
  PUBLISHED: "YAYINLANDI",
  UNPUBLISHED: "YAYINLANMADI",
  blog:{
    menu:{
      header:'Blog',
      categories:'Gruplar',
      posts:'İçerikler'
    },
    categories:{
      delete:{
        success:{
          message:'Blog grubu silindi'
        },
        confirm:{
          title:'Blog grubunu silmek istediğinize emin misiniz?'
        }
      },

      form:{
        update:{
          title:'Blog Grup Düzenle'
        },
        create:{
          title:'Blog Grup Ekle'
        }
      }
    },

    posts:{
      list:{
        button:'Yeni İçerik Ekle'
      },
      delete:{
        success:{
          message:'Blog içeriği silindi'
        },
        confirm:{
          title:'Blog içeriğini silmek istediğinize emin misiniz?'
        }
      },

      form:{
        keywords:'Anahtar Kelimeler',
        update:{
          title:'Blog İçeriği Düzenle'
        },
        create:{
          title:'Blog İçeriği Ekle'
        }
      }
    }
    
  },
  loginPage: {
    usernameRequired: 'Kullanıcı Adı Gerekli Alan',
    passwordRequired: 'Şifre Gerekli Alan',
    wrongCredentials: 'Yanlış kullanıcı adı veya şifre'
  },
  leftMenu: {
    blog:'Blog',
    dashboard: 'Pano',
    interests: 'İlgi Alanları',
    brands: 'Markalar',
    dealers: 'Saticilar',
    badges: 'Rozetler',
    tags: 'Etiketler',
    variants: 'Varyantlar',
    attributes: 'Ozellikler',
    categories: 'Kategoriler',
    posts: 'İçerikler',
    checklistCategories: 'Kontrol Listesi',
    users: 'Kullanicilar',
    coupons: 'Kuponlar',
    faqs: 'SSS',
    products: 'Ürünler',
    inventories: 'Envanterler',
    cms: 'CMS',
    reviews: 'Yorumlar',
    commissions: 'Komisyon Oranları'
  },
  interests: {
    title: 'Ilgi Alanlari',
    editTag: 'Ilgi Alani Duzenle',
    addBrand: 'Ilgi Alani Ekle',
    editModalTitle: 'Ilgi Alani Duzenle',
    addModalTitle: 'Ilgi Alani Ekle',
    deleteConfirmMessage: 'Ilgi alanini silmek istediginize emin misiniz?',
    selectedChecklist: 'Secili Kontrol Listesi',
    defaultChecklist: 'Varsayilan Kontrol Listesi',
  },
  brands: {
    title: 'Markalar',
    editBrand: 'Marka Duzenle',
    addBrand: 'Marka Ekle',
    editModalTitle: 'Marka Duzenle',
    addModalTitle: 'Marka Ekle',
    deleteConfirmMessage: 'Markayi silmek istediginize emin misiniz?',
    estimatedDeliveryDays: 'Tahmini Kargoya Teslim'
  },
  dealers: {
    title: 'Saticilar',
    editDealer: 'Satici Duzenle',
    addDealer: 'Satici Ekle',
    editModalTitle: 'Satici Duzenle',
    addModalTitle: 'Satici Ekle',
    deleteConfirmMessage: 'Saticiyi silmek istediginize emin misiniz?',
  },
  badges: {
    title: 'Rozetler',
    editBadge: 'Rozet Duzenle',
    addBadge: 'Rozet Ekle',
    editModalTitle: 'Rozet Duzenle',
    addModalTitle: 'Rozet Ekle',
    deleteConfirmMessage: 'Rozeti silmek istediginize emin misiniz?',
  },
  tags: {
    title: 'Etiketler',
    editTag: 'Etiket Duzenle',
    addTag: 'Etiket Ekle',
    editModalTitle: 'Etiket Duzenle',
    addModalTitle: 'Etiket Ekle',
    deleteConfirmMessage: 'Etiketi silmek istediginize emin misiniz?',
  },
  variants: {
    title: 'Varyantlar',
    name: 'Varyant',
    editVariant: 'Varyant Duzenle',
    addVariant: 'Varyant Ekle',
    editModalTitle: 'Varyant Duzenle',
    addModalTitle: 'Varyant Ekle',
    deleteConfirmMessage: 'Varyanti silmek istediginize emin misiniz?',
  },
  variantValues: {
    title: 'Varyant Degerleri',
    editVariantValue: 'Varyant Degeri Duzenle',
    addVariantValue: 'Varyant Degeri Ekle',
    editModalTitle: 'Varyant Degeri Duzenle',
    addModalTitle: 'Varyant Degeri Ekle',
    deleteConfirmMessage: 'Varyant degerini silmek istediginize emin misiniz?',
  },
  attributes: {
    title: 'Ozellikler',
    name: 'Ozellik',
    editAttribute: 'Ozellik Duzenle',
    addAttribute: 'Ozellik Ekle',
    editModalTitle: 'Ozellik Duzenle',
    addModalTitle: 'Ozellik Ekle',
    deleteConfirmMessage: 'Ozelligi silmek istediginize emin misiniz?',
  },
  attributeValues: {
    title: 'Ozellik Degerleri',
    editAttributeValue: 'Ozellik Degeri Duzenle',
    addAttributeValue: 'Ozellik Degeri Ekle',
    editModalTitle: 'Ozellik Degeri Duzenle',
    addModalTitle: 'Ozellik Degeri Ekle',
    deleteConfirmMessage: 'Ozellik degerini silmek istediginize emin misiniz?',
  },
  categories: {
    title: 'Kategoriler',
    editCategory: 'Kategori Duzenle',
    addCategory: 'Kategori Ekle',
    editModalTitle: 'Kategori Duzenle',
    addModalTitle: 'Kategori Ekle',
    deleteConfirmMessage: 'kategoriyi silmek istediginize emin misiniz?',
    parentCategory: 'Üst Kategori',
  },
  checklistCategories: {
    title: 'Kontrol Listesi Kategorileri',
    editChecklistCategory: 'Kontrol Listesi Kategorisini Duzenle',
    addChecklistCategory: 'Kontrol Listesi Kategorisi Ekle',
    editModalTitle: 'Kontrol Listesi Kategorisini Duzenle',
    addModalTitle: 'Kontrol Listesi Kategorisi Ekle',
    deleteConfirmMessage: 'Kontrol Listesi Kategorisini silmek istediginize emin misiniz?',
    parentCategory: 'Ust Kategori',
    category: 'Kategori',
    addCategoryPlaceHolder: 'Kategori Ekle',
  },
  users: {
    title: 'Kullaniciler',
    editUser: 'Kullanici Duzenle',
    addUser: 'Kullanici Ekle',
    addModalTitle: 'Kullanici Ekle',
    editModalTitle: 'Kullanici Duzenle',
    deleteConfirmMessage: 'Kullaniciyi silmek istediginize emin misiniz?',
  },
  products: {
    title: 'Urunler',
    editProduct: 'Urun Duzenle',
    addProduct: 'Urun Ekle',
    addModalTitle: 'Urun Ekle',
    editModalTitle: 'Urun Duzenle',
    deleteConfirmMessage: 'Urunu silmek istediginize emin misiniz?',
    code: 'Urun Kodu',
    fullDesc: 'Uzun Aciklama',
    shortDesc: 'Kisa Aciklama',
    addImage: 'Resim Ekle',
    viewImage: 'Resimleri Goster',
    productScore: 'Ürün Sıralama Skoru',
    productVariantScore: 'Variant Skoru',
    acceptReturn: 'İade Edilebilir Mi?'
  },
  package:{
    shortDesc:'Kısa açıklama',
    duration:'Süre (Gün)'
  },
  inventories: {
    title: 'Evnaterler',
    editModalTitle: 'Envanter Duzenle',
    addModalTitle: 'Envanter Ekle',
    importModalTitle: 'Envanter Yükle',
    updateModalTitle: 'Envanter Güncelle',
    deleteConfirmMessage: 'Envanteri silmek istediginize emin misiniz?',
    upload: {
      csvTypeDescription: "Csv dosyanızın hangi ayırıcı ile ayrıldığını seçiniz.",
      radio: {
        semicolon: "Noktalı Virgül",
        comma: "Virgül"
      }
    }
  },
  faqs: {
    title: 'SSS',
    name: 'SSS',
    editModalTitle: 'SSS Duzenle',
    addModalTitle: 'SSS Ekle',
    deleteConfirmMessage: 'SSS silmek istediginize emin misiniz?',
    parentFaq: 'Ust SSS',
  },
  cmsPages: {
    title: 'Cms Sayfa',
    name: 'Cms Sayfa',
    editModalTitle: 'Cms Sayfa Duzenle',
    addModalTitle: 'Cms Sayfa Ekle',
    deleteConfirmMessage: 'Cms Sayfa silmek istediginize emin misiniz?',
    parentCmsPage: 'Ust Cms Sayfa',
  },
  cmsContents: {
    title: 'Cms Icerik',
    name: 'Cms Icerik',
    editModalTitle: 'Cms Icerik Duzenle',
    addModalTitle: 'Cms Icerik Ekle',
    deleteConfirmMessage: 'Cms Icerik silmek istediginize emin misiniz?',
    parentCmsPage: 'Cms Sayfasi',
  },
  coupons: {
    title: "Kupon",
    addButtonTitle: "Kupon Ekle",
    editModalTitle: "Kuponu Düzenle",
    addModalTitle: "Yeni Kupon Ekle",
    deleteConfirmMessage: "Bu kuponu silmek mi istiyorsunuz ?",
    addSuccessMessage: "Kupon başarılı bir şekilde oluşturuldu.",
    addErrorMessage: "Kupon oluşturulurken bir sorun oluştu !",
    updateSuccessMessage: "Kupon başarılı bir şekilde güncellendi.",
    updateErrorMessage: "Kupon güncellenirken bir sorun ile karşılaşıldı !"
  },
  reviews: {
    title: "Yorumlar",
    addButtonTitle: "Yorum Ekle",
    editModalTitle: "Yorumu Düzenle",
    addModalTitle: "Yeni Yorum Ekle",
    deleteConfirmMessage: "Bu yorumu silmek mi istiyorsunuz ?",
    addSuccessMessage: "Yorum başarılı bir şekilde oluşturuldu.",
    addErrorMessage: "Yorum oluşturulurken bir sorun oluştu !",
    updateSuccessMessage: "Yorum başarılı bir şekilde güncellendi.",
    updateErrorMessage: "Yorum güncellenirken bir sorun ile karşılaşıldı !"
  },
  NotEmpty: {
    user: {
      password: "Kullanıcı şifresi girilmesi zorunludur."
    }
  },
  transactions: {
    leftMenu: {
      label: "İşlemler"
    }
  },
  commissions: {
    title: "Komisyon Oranları",
    addButtonTitle: "Komisyon Oranı Ekle",
    table: {
      rowActions: {
        hint: {
          edit: "Düzenle",
          delete: "Sil",
          add: "Yeni Ekle"
        }
      }
    }
  },
  userNotFound: 'Kullanıcı bulunamadı',
  invalid_grant: "Geçersiz kullanıcı bilgisi",
  dealerName: 'Satıcı',
  ratio: 'Oran',
  brandTitle: 'Marka',
  brandImageUri: 'Marka Resmi',
  productTitle: 'Ürün',
  productImageUri: 'Ürün Görseli',
  sellingPriceIncVat: 'Satış Fiyatı',
  comissionRatio: 'Komisyon Oranı',
  listPriceIncVat: 'Liste Fiyatı',
  ratio: 'Oran',
};
export default Locale;
