import React, { useEffect } from 'react';
import { Table, Row, Col, Input, Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import intl from 'react-intl-universal';
import { FormatMessage } from '../../../hocs';
import GLayout from '../../gLayout';
import useBadges from '../../../hooks/useBadges';
import AddEditBadge from './_add';
import { ActionsColumn, BasicColumn, BooleanColumn, EnumColumn, IdColumn, ImageColumn } from '../../../components';
const { confirm } = Modal;
const { Search } = Input;

const Badges = () => {
  const {
    filteredBadges,
    badgeKeyword,
    setBadgeKeyword,
    fetchBadges,
    saveBadge,
    loadingBadges,
    deleteBadge,
    setBadgeModal,
    badgeModal,
    isDeletingBadge,
    typeEnums,
    pagination,
    validationErrors,
    setValidationErrors,
  } = useBadges();

  useEffect(() => {
    fetchBadges();
  }, []);

  const handleTableChange = ({ current, pageSize }) => {
    fetchBadges({ page: current - 1, pageSize: pageSize });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: <FormatMessage id='badges.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingBadge,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteBadge(id);
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const columns = [
    IdColumn(),
    BasicColumn({ name: 'name' }),
    EnumColumn({ name: 'type', enums: typeEnums }),
    ImageColumn({ name: 'imageUri', label: 'image' }),
    BooleanColumn(),
    ActionsColumn({
      onClickEdit: setBadgeModal,
      onClickDelete: showDeleteConfirm,
    }),
  ];

  return (
    <GLayout>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setBadgeKeyword(e.target.value)}
              value={badgeKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              disabled={loadingBadges}
              onClick={() =>
                setBadgeModal({
                  ...badgeModal,
                  isVisible: true,
                  type: 'new',
                  item: {},
                })
              }
              style={{ float: 'right' }}
            >
              <FormatMessage id='badges.addModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={filteredBadges}
        loading={loadingBadges}
        scroll={{ x: 1500 }}
        onChange={handleTableChange}
        pagination={pagination}
        sticky
      />
      {badgeModal.isVisible && (
        <AddEditBadge
          onHide={() => {
            setBadgeModal({ ...badgeModal, isVisible: false });
            setValidationErrors({});
          }}
          {...badgeModal}
          saveBadge={saveBadge}
          typeEnums={typeEnums}
          validationErrors={validationErrors}
        />
      )}
    </GLayout>
  );
};

export default Badges;
