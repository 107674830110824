import { useEffect, useState } from 'react';
import { apiGet, apiPost, apiPut } from '../utils/api';
import { RESPONSE_STATS } from '../utils/constants';
import { errorMessage, errorResponseMessage, successMessage } from '../views/elements/ToastMessage';

export default function useProductMedias() {
  const [productMedias, setProductMedias] = useState([]);
  const [loadingProductMedias, setLoadingProductMedias] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [productMediaFailed, setProductMediaFailed] = useState(false);
  const [filteredProductMedias, setFilteredProductMedias] = useState([]);
  const [productMediaKeyword, setProductMediaKeyword] = useState('');
  const [productImageModal, setProductImageModal] = useState({
    isVisible: false,
    type: 'add',
    item: false,
  });

  const [savingProductMedia, setSavingProductMedia] = useState(false);
  const [saveProductMediaResult, setSaveProductMediaResult] = useState(RESPONSE_STATS.NOTR);
  const [editProductMediaResult, setEditProductMediaResult] = useState(RESPONSE_STATS.NOTR);
  const [deleteProductMediaResult, setDeleteProductMediaResult] = useState(RESPONSE_STATS.NOTR);

  useEffect(() => {
    if (productMedias && productMedias.content) {
      setFilteredProductMedias(
        productMedias.content?.filter((item) => item.title.toLowerCase().includes(productMediaKeyword.toLowerCase()) || productMediaKeyword.length === 0)
      );
    }
  }, [productMediaKeyword, productMedias]);

  const saveProductMedia = async (productMedia, type) => {
    setSavingProductMedia(true);
    const response = type === 'new' ? await apiPost('/productMedias', productMedia) : await apiPut('/productMedias', productMedia);
    setSavingProductMedia(false);
    if (response && !response.errors && !response.error) {
      type === 'new' ? setSaveProductMediaResult(RESPONSE_STATS.SUCCESS) : setEditProductMediaResult(RESPONSE_STATS.SUCCESS);
      setValidationErrors({});
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error);
    } else {
      setValidationErrors({});
      type === 'new' ? setSaveProductMediaResult(RESPONSE_STATS.FAIL) : setEditProductMediaResult(RESPONSE_STATS.FAIL);
    }
  };

  const saveProductMedias = async (productMedias) => {
    setSavingProductMedia(true);
    const response = await apiPost('/productmedias/multiple', productMedias);
    setSavingProductMedia(false);
    if (response && !response.errors) {
      setSaveProductMediaResult(RESPONSE_STATS.SUCCESS);
      fetchProductMedias({ productId: productMedias.productId, variantValueId: productMedias.variantValueId });
      setValidationErrors({});
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else {
      setValidationErrors({});
      setSaveProductMediaResult(RESPONSE_STATS.FAIL);
    }
  };

  const fetchProductMedias = async ({ productId = '', variantValueId = '' } = {}) => {
    setLoadingProductMedias(true);

    const response = await apiGet(`/productmedias/filter?productId=${productId}&variantValueId=${variantValueId}`);
    if (response === false) {
      setProductMediaFailed(true);
    } else {
      setProductMedias(response);
    }
    setLoadingProductMedias(false);
  };

  const fetchProductMediasByProductId = async ({ productId = ''} = {}) => {
    setLoadingProductMedias(true);

    const response = await apiGet(`/productmedias/findAll?productId=${productId}`);
    if (response === false) {
      setProductMediaFailed(true);
    } else {
      setProductMedias(response);
    }
    setLoadingProductMedias(false);
  };

  useEffect(() => {
    if (saveProductMediaResult === RESPONSE_STATS.SUCCESS) {
      successMessage('saveSuccessMessage');
    } else if (saveProductMediaResult === RESPONSE_STATS.FAIL) {
      errorMessage('failMessage');
    }
    setSaveProductMediaResult(RESPONSE_STATS.NOTR);
  }, [saveProductMediaResult]);

  useEffect(() => {
    if (editProductMediaResult === RESPONSE_STATS.SUCCESS) {
      successMessage('editSuccessMessage');
    } else if (editProductMediaResult === RESPONSE_STATS.FAIL) {
      errorMessage('failMessage');
    }
    setEditProductMediaResult(RESPONSE_STATS.NOTR);
  }, [editProductMediaResult]);

  useEffect(() => {
    if (deleteProductMediaResult === RESPONSE_STATS.SUCCESS) {
      successMessage('deleteSuccessMessage');
    } else if (deleteProductMediaResult === RESPONSE_STATS.FAIL) {
      errorMessage('failMessage');
    }
    setDeleteProductMediaResult(RESPONSE_STATS.NOTR);
  }, [deleteProductMediaResult]);

  return {
    productMedias,
    fetchProductMedias,
    fetchProductMediasByProductId,
    loadingProductMedias,
    productMediaFailed,
    filteredProductMedias,
    productMediaKeyword,
    setProductMediaKeyword,
    saveProductMedia,
    saveProductMedias,
    savingProductMedia,
    saveProductMediaResult,
    validationErrors,
    setValidationErrors,
    productImageModal,
    setProductImageModal,
  };
}
