import React, { useEffect } from 'react';
import { Table, Row, Col, Tag, Input, Button, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import intl from 'react-intl-universal';
import { FormatMessage } from '../../../hocs';
import GLayout from '../../gLayout';
import useTags from '../../../hooks/useTags';
import AddEditTag from './_add';
import { ActionsColumn, BasicColumn, BooleanColumn, IdColumn } from '../../../components';
const { confirm } = Modal;
const { Search } = Input;

const Tags = () => {
  const {
    filteredTags,
    tagKeyword,
    setTagKeyword,
    fetchTags,
    saveTag,
    loadingTags,
    deleteTag,
    setTagModal,
    tagModal,
    isDeletingTag,
    tags,
    pagination,
    validationErrors,
    setValidationErrors,
  } = useTags();

  useEffect(() => {
    fetchTags();
  }, []);

  const handleTableChange = ({ current, pageSize }) => {
    fetchTags({ page: current - 1, pageSize: pageSize });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: <FormatMessage id='tags.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingTag,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteTag(id);
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const columns = [
    IdColumn(),
    BasicColumn({ name: 'title' }),
    BooleanColumn(),
    {
      title: <FormatMessage id='visible' />,
      dataIndex: 'visibleToUser',
      key: 'visibleToUser',
      render: (text) => {
        let color = text ? 'green' : 'volcano';
        return (
          <Tag color={color} key={text}>
            {text ? <FormatMessage id='active' /> : <FormatMessage id='passive' />}
          </Tag>
        );
      },
    },
    ActionsColumn({
      onClickEdit: setTagModal,
      onClickDelete: showDeleteConfirm,
    }),
  ];

  return (
    <GLayout>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setTagKeyword(e.target.value)}
              value={tagKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              disabled={loadingTags}
              onClick={() => {
                setTagModal({ isVisible: true, type: 'new', item: {} });
              }}
              style={{ float: 'right' }}
            >
              <FormatMessage id='tags.addModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={filteredTags}
        loading={loadingTags}
        scroll={{ x: 1500 }}
        onChange={handleTableChange}
        pagination={pagination}
        sticky
      />
      {tagModal.isVisible && (
        <AddEditTag
          onHide={() => {
            setTagModal({ ...tagModal, isVisible: false });
            setValidationErrors({});
          }}
          {...tagModal}
          saveTag={saveTag}
          validationErrors={validationErrors}
        />
      )}
    </GLayout>
  );
};

export default Tags;
