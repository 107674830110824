import React, { useEffect, useState } from 'react';
import { Button, Modal, Typography, Row, Col, Input, Table, Divider } from 'antd';
import useVariants from '../../../hooks/useVariants';
import GLayout from '../../gLayout';
import './index.css';
import intl from 'react-intl-universal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AddEditVariant from './_add';
import { FormatMessage } from '../../../hocs';
import VariantValues from './values';
import { ActionsColumn, BasicColumn, BooleanColumn, IdColumn } from '../../../components';
const { confirm } = Modal;
const { Search } = Input;
const { Text, Link } = Typography;

const Variants = () => {
  const {
    filteredVariants,
    fetchVariants,
    variantModal,
    saveVariant,
    setInterestModal,
    setVariantModal,
    isDeletingVariant,
    deleteVariant,
    setVariantKeyword,
    loadingVariants,
    variantKeyword,
    pagination,
    validationErrors,
    setValidationErrors,
  } = useVariants();

  const [selectedVariant, setSelectedVariant] = useState(null);

  useEffect(() => {
    fetchVariants();
  }, []);

  useEffect(() => {
    if (filteredVariants && filteredVariants.length > 0) {
      setSelectedVariant(filteredVariants[0]);
    } else {
      setSelectedVariant(null);
    }
  }, [filteredVariants]);

  const handleTableChange = ({ current, pageSize }) => {
    fetchVariants({ page: current - 1, pageSize: pageSize });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: <FormatMessage id='variants.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingVariant,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteVariant(id);
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const columns = [
    IdColumn(),
    BasicColumn({ name: 'title' }),
    BooleanColumn(),
    ActionsColumn({
      onClickEdit: setVariantModal,
      onClickDelete: showDeleteConfirm,
    }),
  ];

  return (
    <GLayout>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setVariantKeyword(e.target.value)}
              value={variantKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              disabled={loadingVariants}
              onClick={() => setVariantModal({ isVisible: true, type: 'new', item: {} })}
              style={{ float: 'right' }}
            >
              <FormatMessage id='variants.addModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        id='variants-table'
        rowKey='id'
        bordered
        loading={loadingVariants}
        title={() => <FormatMessage id='variants.title' />}
        dataSource={filteredVariants}
        columns={columns}
        onChange={handleTableChange}
        pagination={pagination}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setSelectedVariant(record);
            },
          };
        }}
        rowClassName={(record, index) => {
          if (selectedVariant?.id === record.id) {
            return 'selected-row';
          }
        }}
      />
      {selectedVariant && (
        <>
          <Divider />
          <VariantValues variant={selectedVariant} />
        </>
      )}
      {variantModal.isVisible && (
        <AddEditVariant
          onHide={() => {
            setValidationErrors({});
            setVariantModal({ ...variantModal, isVisible: false });
          }}
          {...variantModal}
          saveVariant={saveVariant}
          validationErrors={validationErrors}
        />
      )}
    </GLayout>
  );
};

export default Variants;
