import React, { useEffect } from 'react';
import { Table, Row, Col, Input, Button, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import intl from 'react-intl-universal';
import { FormatMessage } from '../../../hocs';
import GLayout from '../../gLayout';
import useInventories from '../../../hooks/useInventories';
import AddEditInventory from './_add';
import { RESPONSE_STATS } from '../../../utils/constants';
import useDropDowns from '../../../hooks/useDropDowns';
import { ActionsColumn, BasicColumn, BooleanColumn, IdColumn } from '../../../components';
import ImportInventory from './_import';
import UpdateInventory from './_update_inventory';
const { confirm } = Modal;
const { Search } = Input;

const Inventories = () => {
  const {
    filteredInventories,
    inventoryKeyword,
    setInventoryKeyword,
    fetchInventoriesPage,
    saveInventory,
    isLoadingInventories,
    deleteInventory,
    setInventoryModal,
    inventoryModal,
    setImportInventoryModal,
    importInventoryModal,
    updateInventoryModal, 
    setUpdateInventoryModal,
    isDeletingInventory,
    inventories,
    pagination,
    fetchPagination,
    inventoryStatusEnums,
    deleteInventoryResult,
    saveInventoryResult,
    editInventoryResult,
    validationErrors,
    setValidationErrors,
  } = useInventories();

  const { fetchVariantValuesDropDown, variantValuesDropDown, fetchProductsDropDown, productsDropDown } = useDropDowns();

  useEffect(() => {
    fetchInventoriesPage();
    fetchVariantValuesDropDown();
    fetchProductsDropDown();
  }, []);

  useEffect(() => {
    if (saveInventoryResult === RESPONSE_STATS.SUCCESS || editInventoryResult === RESPONSE_STATS.SUCCESS) {
      fetchInventoriesPage();
    }
  }, [saveInventoryResult, editInventoryResult]);

  useEffect(() => {
    if (deleteInventoryResult === RESPONSE_STATS.SUCCESS) {
      fetchInventoriesPage();
    }
  }, [deleteInventoryResult]);

  const handleTableChange = ({ current, pageSize }) => {
    if(inventoryKeyword === ""){
      fetchInventoriesPage({ page: current - 1, pageSize: pageSize });
    } else {
      fetchPagination(current, pageSize, inventoryKeyword)
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: <FormatMessage id='inventories.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingInventory,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteInventory(id);
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const columns = [
    IdColumn(),
    BasicColumn({ name: 'productTitle', label: 'product' }),
    BasicColumn({ name: 'quantityStock' }),
    BasicColumn({ name: 'barcode' }),
    BasicColumn({ name: 'sellingPriceIncVat', label: 'sellingPrice' }),
    BasicColumn({ name: 'status' }),
    {
      title: <FormatMessage id={'variantValues.title'} />,
      dataIndex: 'variantValues',
      key: 'variantValues',
      ellipsis: {
        showTitle: false,
      },
      render: (values) => {
        return values.map((v, index) => {
          let text = `${v.variantTitle}/${v.variantValueTitle}`;
          if (index + 1 !== values.length) {
            text = text + ', ';
          }
          console.log(text);
          return (
            <Tooltip placement='topLeft' title={text}>
              {text}
            </Tooltip>
          );
        });
      },
    },
    BooleanColumn(),
    ActionsColumn({
      onClickEdit: setInventoryModal,
      onClickDelete: showDeleteConfirm,
    }),
  ];

  return (
    <GLayout>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setInventoryKeyword(e.target.value)}
              value={inventoryKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              disabled={isLoadingInventories}
              onClick={() => setInventoryModal({ isVisible: true, type: 'new', item: {} })}
              style={{ float: 'right' }}
            >
              <FormatMessage id='inventories.addModalTitle' />
            </Button>
            <Button
              key='2'
              type='primary'
              disabled={isLoadingInventories}
              onClick={() => setImportInventoryModal({ isVisible: true })}
              style={{ float: 'right', marginRight: 10 }}
            >
              <FormatMessage id='inventories.importModalTitle' />
            </Button>

            <Button
              key='2'
              type='primary'
              disabled={isLoadingInventories}
              onClick={() => setUpdateInventoryModal({ isVisible: true })}
              style={{ float: 'right', marginRight: 10 }}
            >
              <FormatMessage id='inventories.updateModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={filteredInventories}
        loading={isLoadingInventories}
        scroll={{ x: 1500 }}
        onChange={handleTableChange}
        pagination={pagination}
        sticky
      />
      {inventoryModal.isVisible && (
        <AddEditInventory
          onHide={() => {
            setValidationErrors({});
            setInventoryModal({ ...inventoryModal, isVisible: false });
          }}
          {...inventoryModal}
          saveInventory={saveInventory}
          inventoryStatusEnums={inventoryStatusEnums}
          variantValues={variantValuesDropDown}
          validationErrors={validationErrors}
          products={productsDropDown}
        />
      )}
      {importInventoryModal.isVisible && (
        <ImportInventory
          onHide={() => {
            setImportInventoryModal({ ...importInventoryModal, isVisible: false });
          }}
          {...importInventoryModal}
        />
      )}

      {updateInventoryModal.isVisible && (
        <UpdateInventory
          onHide={() => {
            setUpdateInventoryModal({ ...updateInventoryModal, isVisible: false });
          }}
          {...updateInventoryModal}
        />
      )}
    </GLayout>
  );
};

export default Inventories;
