import { FormatMessage } from '../../hocs';
import PropTypes from 'prop-types';

const IdColumn = ({ width = null } = {}) => {
  return {
    title: <FormatMessage id='id' />,
    dataIndex: 'id',
    key: 'id',
    width: width ? width : 100,
  };
};

IdColumn.propTypes = {
  width: PropTypes.number,
};

export default IdColumn;
