import React, { useEffect } from 'react';
import { Table, Row, Col, Tag, Input, Space, Button, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';

import intl from 'react-intl-universal';
import { FormatMessage } from '../../../hocs';
import GLayout from '../../gLayout';
import useDealers from '../../../hooks/useDealers';
import AddEditDealer from './_add';
import { ActionsColumn, BasicColumn, BooleanColumn, IdColumn } from '../../../components';
const { confirm } = Modal;
const { Search } = Input;

const Dealers = () => {
  const {
    filteredDealers,
    dealerKeyword,
    setDealerKeyword,
    fetchDealers,
    saveDealer,
    loadingDealers,
    deleteDealer,
    setDealerModal,
    dealerModal,
    isDeletingDealer,
    pagination,
    validationErrors,
    setValidationErrors,
  } = useDealers();

  useEffect(() => {
    fetchDealers();
  }, []);

  const handleTableChange = ({ current, pageSize }) => {
    fetchDealers({ page: current - 1, pageSize: pageSize });
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: <FormatMessage id='dealers.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingDealer,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteDealer(id);
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const columns = [
    IdColumn(),
    BasicColumn({ name: 'name' }),
    {
      title: <FormatMessage id='email' />,
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
      showSorterTooltip: true,
    },
    BasicColumn({ name: 'identityNumber' }),
    BasicColumn({ name: 'mersisNo' }),
    {
      title: <FormatMessage id='phone' />,
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
      showSorterTooltip: true,
      render: (text, record) => record.address?.phone,
    },
    BasicColumn({ name: 'tradeRegisterNo' }),
    BasicColumn({ name: 'userId' }),
    BasicColumn({ name: 'website' }),
    BasicColumn({ name: 'lastLogin' }),
    BooleanColumn(),
    ActionsColumn({
      onClickEdit: setDealerModal,
      onClickDelete: showDeleteConfirm,
    }),
  ];

  return (
    <GLayout>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setDealerKeyword(e.target.value)}
              value={dealerKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              onClick={() => setDealerModal({ isVisible: true, type: 'new', item: {} })}
              style={{ float: 'right' }}
            >
              <FormatMessage id='dealers.addModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={filteredDealers}
        loading={loadingDealers}
        scroll={{ x: 1500 }}
        onChange={handleTableChange}
        pagination={pagination}
        sticky
      />
      {dealerModal.isVisible && (
        <AddEditDealer
          onHide={() => {
            setValidationErrors({});
            setDealerModal({ ...dealerModal, isVisible: false });
          }}
          {...dealerModal}
          saveDealer={saveDealer}
          validationErrors={validationErrors}
        />
      )}
    </GLayout>
  );
};

export default Dealers;
