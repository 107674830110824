import React, { useEffect } from 'react';
import { Table, Row, Col, Input, Button, Modal, Space, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined, FileImageOutlined, ProfileOutlined } from '@ant-design/icons';

import intl from 'react-intl-universal';
import { FormatMessage } from '../../../hocs';
import GLayout from '../../gLayout';
import useProducts from '../../../hooks/useProducts';
import useDropDowns from '../../../hooks/useDropDowns';
import AddEditProduct from './_add';
import AddEditImageProduct from './_add.image';
import useEnums from '../../../hooks/useEnums';
import ViewImageProduct from './_view.image';
import { BasicColumn, BooleanColumn, IdColumn } from '../../../components';

const { confirm } = Modal;
const { Search } = Input;

const Products = () => {
  const {
    filteredProducts,
    productKeyword,
    setProductKeyword,
    fetchProducts,
    saveProduct,
    loadingProducts,
    deleteProduct,
    setProductModal,
    productModal,
    isDeletingProduct,
    pagination,
    fetchPagination,
    productStatusEnums,
    productDeliveryTypeEnums,
    productCargoTypeEnums,
    validationErrors,
    setValidationErrors,
    addImageModal,
    setAddImageModal,
    viewImageModal,
    setViewImageModal,
  } = useProducts();

  const { variantValuesDropDown, fetchVariantValuesDropDown } = useDropDowns();

  const { productVatTypes, fetchProductVatTypes } = useEnums();
  const { allDropDowns, fetchAllDropDowns } = useDropDowns();

  useEffect(() => {
    fetchProducts();
    fetchAllDropDowns({ categoryLevel: 2, variantName: 'color' });
    fetchProductVatTypes();
    fetchVariantValuesDropDown();
  }, []);

  const handleTableChange = ({ current, pageSize }) => {
    if(productKeyword === "") {
      fetchProducts({ page: current - 1, pageSize: pageSize });
    } else {
      fetchPagination(current, pageSize, productKeyword)
    }
  };

  const showDeleteConfirm = (id) => {
    confirm({
      title: <FormatMessage id='products.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingProduct,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteProduct(id);
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const columns = [
    IdColumn(),
    BasicColumn({ name: 'title' }),
    BasicColumn({ name: 'code', label: 'products.code' }),
    BasicColumn({ name: 'brandTitle', label: 'brand' }),
    BasicColumn({ name: 'categoryName', label: 'category' }),
    BasicColumn({ name: 'sellingPriceIncVat', label: 'sellingPrice' }),
    BooleanColumn(),
    {
      title: <FormatMessage id='actions' />,
      key: 'action',
      width: 220,
      render: (text, record) => (
        <Space size='middle'>
          <Tooltip title={<FormatMessage id='edit' />}>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setProductModal({ type: 'edit', isVisible: true, item: record });
              }}
            />
          </Tooltip>

          <Tooltip title={<FormatMessage id='delete' />}>
            <Button danger onClick={() => showDeleteConfirm(record.id)} icon={<DeleteOutlined />} />
          </Tooltip>
          <Tooltip title={<FormatMessage id='products.addImage' />}>
            <Button
              icon={<FileImageOutlined />}
              onClick={() => {
                setAddImageModal({
                  isVisible: true,
                  item: record,
                });
              }}
            />
          </Tooltip>
          <Tooltip title={<FormatMessage id='products.viewImage' />}>
            <Button
              icon={<ProfileOutlined />}
              onClick={() => {
                setViewImageModal({
                  isVisible: true,
                  item: record,
                });
              }}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <GLayout>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setProductKeyword(e.target.value)}
              value={productKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              disabled={loadingProducts}
              onClick={() => setProductModal({ isVisible: true, type: 'new', item: {} })}
              style={{ float: 'right' }}
            >
              <FormatMessage id='products.addModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={filteredProducts}
        loading={loadingProducts}
        scroll={{ x: 1500 }}
        onChange={handleTableChange}
        pagination={pagination}
        sticky
      />
      {productModal.isVisible && (
        <AddEditProduct
          onHide={() => {
            setProductModal({ ...productModal, isVisible: false });
            setValidationErrors({});
          }}
          {...productModal}
          saveProduct={saveProduct}
          productStatusEnums={productStatusEnums}
          productDeliveryTypeEnums={productDeliveryTypeEnums}
          productCargoTypeEnums={productCargoTypeEnums}
          productVatTypes={productVatTypes}
          dropDowns={allDropDowns}
          validationErrors={validationErrors}
        />
      )}
      {addImageModal.isVisible && (
        <AddEditImageProduct
          onHide={() => {
            setAddImageModal({ ...addImageModal, isVisible: false });
            setValidationErrors({});
          }}
          {...addImageModal}
          //saveProduct={saveProduct}
          variantValues={allDropDowns.variantValues}
          validationErrors={validationErrors}
        />
      )}
      {viewImageModal.isVisible && (
        <ViewImageProduct
          onHide={() => {
            setViewImageModal({ ...viewImageModal, isVisible: false });
            setValidationErrors({});
          }}
          variantValues={allDropDowns.variantValues}
          {...viewImageModal}
        />
      )}
    </GLayout>
  );
};

export default Products;
