import React from 'react';
import { Form, Select } from 'antd';
import { FormatMessage } from '../../hocs';
import PropTypes from 'prop-types';

const SelectItem = ({
  name = '',
  label = '',
  layout = null,
  isRequired = false,
  isLoading = false,
  placeholder = '',
  search = true,
  children,
  validationErrors = null,
  defaultValue = false,
  allowClear = false,
  onChange,
  isDisabled,
  onSearch,
  style,
  ...rest
}) => {
  return (
    <Form.Item
      name={name}
      {...layout}
      label={label ? <FormatMessage id={label} />: ''}
      validateStatus={validationErrors?.[name] && 'error'}
      help={validationErrors?.[name]}
      rules={[{ required: isRequired }]}
    >
      {search ? (
        <Select
          disabled={isLoading || isDisabled}
          loading={isLoading}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChange}
          allowClear={allowClear}
          defaultActiveFirstOption
          onSearch={onSearch}
          showSearch
          style={style}
          optionFilterProp='children'
          filterOption={(input, option) => option.children.toLocaleLowerCase('tr-TR').indexOf(input.toLocaleLowerCase('tr-TR')) >= 0}
          {...rest}
        >
          {children}
        </Select>
      ) : (
        <Select disabled={isLoading} loading={isLoading} placeholder={placeholder} defaultValue={defaultValue}>
          {children}
        </Select>
      )}
    </Form.Item>
  );
};

SelectItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  layout: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  search: PropTypes.bool,
  defaultValue: PropTypes.bool,
  validationErrors: PropTypes.object,
  onChange: PropTypes.func
};

export default SelectItem;
