import React, { useState } from 'react';
import useCoupons from '../../../hooks/useCoupons';
import { FormatMessage } from '../../../hocs';
import { Table, Row, Col, Space, Button, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { BasicColumn, BooleanColumn, IdColumn } from '../../../components';
import GLayout from '../../gLayout';
import AddEditCouponModal from './modal/_add';

export const CouponModalTypes = {
  ADD: 'ADD',
  UPDATE: 'UPDATE',
};

const initialCouponModalState = {
  isVisible: false,
  type: CouponModalTypes.ADD,
  item: {},
};

const Coupons = () => {
  const { confirm } = Modal;

  const {
    fetchCoupons,
    coupons,
    isFetchingCoupons,
    deleteCoupon,
    isDeletingCoupon,
    addCoupon,
    isSaving,
    updateCoupon,
    validationErrors,
    setValidationErrors,
  } = useCoupons();

  const [couponModal, setCouponModal] = useState({ ...initialCouponModalState });

  // fetch all coupons
  React.useLayoutEffect(() => {
    fetchCoupons();
  }, []);

  // Delete confirm
  const showDeleteConfirm = (record) => {
    confirm({
      title: <FormatMessage id='coupons.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingCoupon,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteCoupon(record.id, record.parentId);
          resolve();
        })().catch((e) => console.log('Oops errors!', e));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  // table columns
  const columns = [
    IdColumn(),
    BasicColumn({ name: 'code' }),
    BasicColumn({ name: 'description', width: 250, renderType: 'html' }),
    BasicColumn({ name: 'minAmount', width: 150 }),
    BasicColumn({ name: 'amount', width: 100 }),
    BasicColumn({ name: 'percentage', width: 100 }),
    BooleanColumn({ name: 'active', width: 100 }),
    BooleanColumn({ name: 'multipleUsage', width: 100 }),
    BooleanColumn({ name: 'oneTimeOnly', width: 100 }),
    BasicColumn({ name: 'startsAt' }),
    BasicColumn({ name: 'validUntil' }),
    {
      title: <FormatMessage id='actions' />,
      key: 'action',
      width: 180,
      render: (text, record) => (
        <Space size='middle'>
          <Tooltip title={<FormatMessage id='edit' />}>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setCouponModal({
                  type: CouponModalTypes.UPDATE,
                  isVisible: true,
                  item: record,
                });
              }}
            />
          </Tooltip>

          <Tooltip title={<FormatMessage id='delete' />}>
            <Button
              danger
              onClick={() => {
                showDeleteConfirm(record);
              }}
              icon={<DeleteOutlined />}
            ></Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  /* coupon modal actions */
  // onCancel
  const onAddOrUpdateModalCancel = () => {
    setCouponModal({ ...initialCouponModalState });
  };

  return (
    <GLayout>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}></Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              disabled={isFetchingCoupons}
              onClick={() =>
                setCouponModal({
                  isVisible: true,
                  type: CouponModalTypes.ADD,
                  item: {},
                })
              }
              style={{ float: 'right' }}
            >
              <FormatMessage id='coupons.addButtonTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={coupons}
        loading={isFetchingCoupons}
        scroll={{ x: 1500 }}
        pagination={coupons && coupons.length > 25}
        sticky
      />

      <AddEditCouponModal
        {...couponModal}
        onCancel={onAddOrUpdateModalCancel}
        addCoupon={addCoupon}
        updateCoupon={updateCoupon}
        isSaving={isSaving}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
      />
    </GLayout>
  );
};

export default Coupons;
