import Cookies from "js-cookie";
import { SET_LOCALE } from "../actions/types";

const INITIAL_STATE = Cookies.get("lang") || process.env.REACT_APP_DEFAULT_LANG;

const localeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOCALE:
      Cookies.set("lang",action.payload);
      return action.payload;
    default:
      return state;
  }
};

export default localeReducer;
