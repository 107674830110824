import { useState } from 'react';
import { apiGet } from '../utils/api';

export default function useDropDowns() {
  const [brandsDropDown, setBrandsDropDown] = useState([]);
  const [attributeValuesDropDown, setAttributeValuesDropDown] = useState([]);
  const [variantValuesDropDown, setVariantValuesDropDown] = useState([]);
  const [dealersDropDown, setDealersDropDown] = useState([]);
  const [productsDropDown, setProductsDropDown] = useState([]);
  const [badgesDropDown, setBadgesDropDown] = useState([]);
  const [tagsDropDown, setTagsDropDown] = useState([]);
  const [categoriesDropDown, setCategoriesDropDown] = useState([]);

  const [allDropDowns, setAllDropDowns] = useState({});

  const [isLoadingBrands, setIsBrandsLoading] = useState(false);
  const [isLoadingAttributeValues, setIsLoadingAttributeValues] = useState(false);
  const [isLoadingVariantValues, setIsLoadingVariantValues] = useState(false);
  const [isLoadingDealers, setIsLoadingDealers] = useState(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const [isLoadingBadges, setIsLoadingBadges] = useState(false);
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  const fetchBadgesDropDown = async () => {
    setIsLoadingBadges(true);
    const response = await apiGet(`/dropdowns/badges`);
    setIsLoadingBadges(false);
    if (response) {
      setBadgesDropDown(response);
      return response;
    }
  };

  const fetchTagsDropDown = async () => {
    setIsLoadingTags(true);
    const response = await apiGet(`/dropdowns/tags`);
    setIsLoadingTags(false);
    if (response) {
      setTagsDropDown(response);
      return response;
    }
  };

  const fetchBrandsDropDown = async () => {
    setIsBrandsLoading(true);
    const response = await apiGet(`/dropdowns/brands`);
    setIsBrandsLoading(false);
    if (response) {
      setBrandsDropDown(response);
      return response;
    }
  };

  const fetchAttributeValuesDropDown = async () => {
    setIsLoadingAttributeValues(true);
    const response = await apiGet(`/dropdowns/attribute-values`);
    setIsLoadingAttributeValues(false);
    if (response) {
      setAttributeValuesDropDown(response);
      return response;
    }
  };

  const fetchVariantValuesDropDown = async () => {
    setIsLoadingVariantValues(true);
    const response = await apiGet(`/dropdowns/variant-values`);
    setIsLoadingVariantValues(false);
    if (response) {
      setVariantValuesDropDown(response);
      return response;
    }
  };

  const fetchDealersDropDown = async () => {
    setIsLoadingDealers(true);
    const response = await apiGet(`/dropdowns/dealers`);
    setIsLoadingDealers(false);
    if (response) {
      setDealersDropDown(response);
      return response;
    }
  };

  const fetchProductsDropDown = async () => {
    setIsLoadingProducts(true);
    const response = await apiGet(`/dropdowns/products`);
    setIsLoadingProducts(false);
    if (response) {
      setProductsDropDown(response);
      return response;
    }
  };

  const fetchCategoriesDropDown = async ({ level }) => {
    setIsLoadingCategories(true);
    const response = await apiGet(`/dropdowns/categories?level=${level}`);
    setIsLoadingCategories(false);
    if (response) {
      setCategoriesDropDown(response);
      return response;
    }
  };

  const fetchAllDropDowns = async ({ categoryLevel = 0, variantName }) => {
    const res = await Promise.all([
      apiGet(`/dropdowns/products`),
      apiGet(`/dropdowns/dealers`),
      apiGet(`/dropdowns/variant-values?variantName=${variantName}`),
      apiGet(`/dropdowns/attribute-values`),
      apiGet(`/dropdowns/brands`),
      apiGet(`/dropdowns/tags`),
      apiGet(`/dropdowns/badges`),
      apiGet(`/dropdowns/categories?level=${categoryLevel}`),
    ]);
    setAllDropDowns({
      products: res[0],
      dealers: res[1],
      variantValues: res[2],
      attributeValues: res[3],
      brands: res[4],
      tags: res[5],
      badges: res[6],
      categories: res[7]
    });
  };

  return {
    brandsDropDown,
    productsDropDown,
    variantValuesDropDown,
    attributeValuesDropDown,
    dealersDropDown,
    badgesDropDown,
    tagsDropDown,
    categoriesDropDown,
    allDropDowns,
    fetchBrandsDropDown,
    fetchAttributeValuesDropDown,
    fetchDealersDropDown,
    fetchVariantValuesDropDown,
    fetchProductsDropDown,
    fetchBadgesDropDown,
    fetchTagsDropDown,
    fetchCategoriesDropDown,
    fetchAllDropDowns,
    isLoadingBrands,
    isLoadingDealers,
    isLoadingProducts,
    isLoadingAttributeValues,
    isLoadingVariantValues,
    isLoadingBadges,
    isLoadingTags,
    isLoadingCategories,
  };
}
