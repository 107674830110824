import React, { memo } from 'react';
import { Form, Switch } from 'antd';
import { FormatMessage } from '../../hocs';
import PropTypes from 'prop-types';

const SwitchItem = ({ name = '', label = '', value = null, layout = null, validationErrors = null }) => {
  return (
    <Form.Item
      name={name}
      rules={[{ required: false }]}
      initialValue={value ?? false}
      label={<FormatMessage id={label ? label : name} />}
      validateStatus={validationErrors?.[name] && 'error'}
      help={validationErrors?.[name]}
      {...layout}
    >
      <Switch defaultChecked={value} />
    </Form.Item>
  );
};

SwitchItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool,
  layout: PropTypes.object.isRequired,
  validationErrors: PropTypes.object
};

export default SwitchItem;
