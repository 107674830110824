import { FormatMessage } from '../../hocs';
import PropTypes from 'prop-types';
import moment from 'moment'

const DateColumn = ({ name = 'active', label, width } = {}) => {
  return {
    title: <FormatMessage id={label ? label : name} />,
    dataIndex: name,
    key: name,
    width: width ? width : 180,
    render: (text) => {
      let date = text ? moment(text).format('DD/MM/YYYY HH:MM') : text;
      return date;
    },
  };
};

DateColumn.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.number,
};

export default DateColumn;
