import { useState, useEffect } from 'react';
import { apiGet, apiDelete, apiPost, apiPut } from '../utils/api';
import _ from 'lodash';
import { successMessage, errorMessage, errorResponseMessage } from '../views/elements/ToastMessage';
export default function useCategories(props) {
  const [categories, setCategories] = useState([]);
  const [hierarchyCategories, setHierarchyCategories] = useState([]);
  const [hierarchyMappedCategories, setHierarchyMappedCategories] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [, setLoadingHierarchyCategories] = useState(false);
  const [categoryFailed, setCategoryFailed] = useState(false);
  const [, setHierarchyCategoryFailed] = useState(false);
  const [isDeletingCategory, setIsDeletingCategory] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [categoryKeyword, setCategoryKeyword] = useState('');
  const [categoryModal, setCategoryModal] = useState({
    isVisible: false,
    type: 'add',
    item: false,
    category: {},
  });
  const [nestedData, setNestedData] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [isParentCategoriesLoaded,setIsParentCategoriesLoaded] = useState(false)
  const [parentCategories,setParentCategories] = useState([]);

  const [savingCategory, setSavingCategory] = useState(false);

  useEffect(() => {
    if (categories) {
      setFilteredCategories(categories.filter((item) => item.name?.toLowerCase().includes(categoryKeyword.toLowerCase()) || categoryKeyword.length === 0));
    }
  }, [categoryKeyword, categories]);

  const saveCategory = async (category, type) => {
    setSavingCategory(true);
    const response = type === 'new' ? await apiPost('/categories', category) : await apiPut('/categories', category);
    setSavingCategory(false);
    if (response && !response.errors && !response.error) {
      setCategoryModal({
        ...categoryModal,
        item: type === 'edit' ? response.data : response,
        type: 'edit',
        isVisible: false,
      });
      type === 'new' ? successMessage('saveSuccessMessage') : successMessage('editSuccessMessage');
      setValidationErrors({});
      fetchCategories();
      if (category.parentId) {
        await fetchInnerCategories({ parentId: category.parentId });
      }
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error)
    } else {
      setValidationErrors({});
      errorMessage('failMessage');
    }
  };

  const deleteCategory = async (id, parentId) => {
    setIsDeletingCategory(true);
    const response = await apiDelete('/categories/' + id);
    setIsDeletingCategory(false);
    if (response) {
      successMessage('deleteSuccessMessage');
      if (parentId) {
        await fetchInnerCategories({ parentId: parentId });
      } else {
        fetchCategories();
      }
    } else {
      errorMessage('failMessage');
    }
  };

  const fetchCategories = async ({ parentId = '', isShowTop = '', isShowHome = '', isActive = '' } = {}) => {
    setLoadingCategories(true);

    const response = await apiGet(
      `/categories/filter?parentId=${parentId}&isShowTop=${isShowTop}&isShowHome=${isShowHome}&isActive=${isActive}`
    );
    if (response === false) {
      setCategoryFailed(true);
    } else {
      if (!_.isEmpty(response)) {
        const sorted = response.sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
        setCategories(sorted);
      }
    }
    setLoadingCategories(false);
    return response;
  };

  const fetchInnerCategories = async ({ parentId = '', isShowTop = '', isShowHome = '', isActive = '' } = {}) => {
    setIsLoading({
      [parentId]: true,
    });

    const res = await apiGet(
      `/categories/filter?parentId=${parentId}&isShowTop=${isShowTop}&isShowHome=${isShowHome}&isActive=${isActive}`
    );
    setNestedData((state) => ({
      ...state,
      [parentId]: [...res],
    }));
    setIsLoading({
      [parentId]: false,
    });
  };

  const fetchHierarchyCategories = async () => {
    setLoadingHierarchyCategories(true);

    const response = await apiGet(`/categories/hierarchy`);
    if (response === false) {
      setHierarchyCategoryFailed(true);
    } else {
      setHierarchyCategories(response);
    }
    setLoadingHierarchyCategories(false);
    return response;
  };

  const fetchHierarchyMappedCategories = async () => {
    setLoadingHierarchyCategories(true);

    const response = await apiGet(`/categories/hierarchy-with-mapped`);
    if (response === false) {
      setHierarchyCategoryFailed(true);
    } else {
      setHierarchyMappedCategories(response);
    }
    setLoadingHierarchyCategories(false);
    return response;
  };

  const fetchSameDirectoryParentCategories = async (parentId) => {

    setIsParentCategoriesLoaded(prev => {
      prev = false;
      
      return prev;
    })

    const response = await apiGet(`/categories/${parentId}/siblings`);
    
    if(response === false) {
      setParentCategories([])
    } else {
      setParentCategories(response)
    }

    setIsParentCategoriesLoaded(prev => {
      prev = true;

      return prev;
    })

    return response;
  }

  return {
    categories,
    fetchCategories,
    deleteCategory,
    loadingCategories,
    isDeletingCategory,
    categoryFailed,
    categoryModal,
    setCategoryModal,
    filteredCategories,
    categoryKeyword,
    setCategoryKeyword,
    saveCategory,
    savingCategory,
    fetchInnerCategories,
    nestedData,
    isLoading,
    hierarchyCategories,
    fetchHierarchyCategories,
    fetchHierarchyMappedCategories,
    hierarchyMappedCategories,
    validationErrors,
    setValidationErrors,
    fetchSameDirectoryParentCategories,
    parentCategories,
    setParentCategories,
    isParentCategoriesLoaded
  };
}
