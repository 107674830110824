import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import intl from 'react-intl-universal';
import { useSelector } from 'react-redux';
import { PrivateRoute } from './hocs/PrivateRoute';
import './App.css';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import 'antd-button-color/dist/css/style.css';
import routes from './routes';
import { Spin } from 'antd';

import LocaleEN from './lang/en';
import LocaleTR from './lang/tr';

const locales = {
  'en-US': LocaleEN,
  'tr-TR': LocaleTR,
};

function App() {
  const currentLocale = useSelector((state) => state.locale);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    intl.init({ currentLocale, locales }).then(() => {
      setLoading(false);
    });
  }, [currentLocale]);

  return (
    <div className='App'>
      <Router>
        <React.Suspense loading={loading} fallback={<Spin spinning={loading} delay={2000} />}>
          <Switch>
            {routes.map(
              (route) =>
                route.component &&
                (route.isPrivate ? (
                  <PrivateRoute key={route.path} path={route.path} exact={route.exact} component={route.component} />
                ) : (
                  <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
                ))
            )}
          </Switch>
        </React.Suspense>
      </Router>
      {/* <BasicLayout/> */}
    </div>
  );
}

export default App;
