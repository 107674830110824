import React, { useState } from 'react';
import { Form, Modal, Upload, Button, Radio } from 'antd';
import _ from 'lodash';
import { UploadOutlined } from '@ant-design/icons';
import { FormatMessage } from '../../../hocs';
import { getToken } from '../../../utils/api';
import axios from 'axios';
import { errorMessage } from '../../../views/elements/ToastMessage';
import './import.css';

const UPLOAD_CSV_TYPES = {
  COMMA: 'comma',
  SEMICOLON: 'semicolon',
};

const ImportInventory = ({ isVisible, type, item, onHide, saveInventory }) => {
  const token = getToken();
  const [form] = Form.useForm();
  const [uploadingFile, setUploadingFile] = useState({
    fileList: [],
    uploading: false,
  });
  const [csvType, setCsvType] = useState(UPLOAD_CSV_TYPES.SEMICOLON);

  const upload = () => {
    const { fileList } = uploadingFile;
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('file', file);
    });
    setUploadingFile({
      ...uploadingFile,
      uploading: true,
    });

    const apiUrl = `${process.env.REACT_APP_API_URL}/inventories/import${csvType === UPLOAD_CSV_TYPES.COMMA ? '?seperatorChar=,' : ''}`;

    axios
      .post(apiUrl, formData, {
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', new Date().toISOString() + '.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
        setUploadingFile({
          ...uploadingFile,
          uploading: false,
        });
      })
      .catch((error) => {
        if (error.response.data.errors && error.response.data.errors[0]) {
          errorMessage(error.response.data.errors[0].key);
        } else {
          errorMessage('failMessage');
        }
        setUploadingFile({
          ...uploadingFile,
          uploading: false,
        });
      });
  };

  const props = {
    onRemove: (file) => {
      setUploadingFile((prev) => {
        const index = prev.fileList.indexOf(file);
        const newFileList = prev.fileList.slice();
        newFileList.splice(index, 1);
        return {
          ...prev,
          fileList: newFileList,
        };
      });
    },
    beforeUpload: (file) => {
      setUploadingFile((prev) => {
        return {
          ...prev,
          fileList: [file],
        };
      });
      return false;
    },
    fileList: uploadingFile.fileList,
  };

  const onCsvTypeChange = (e) => {
    setCsvType(e.target.value);
  };

  return (
    <Modal
      title={<FormatMessage id='inventories.importModalTitle' />}
      width={400}
      visible={isVisible}
      onOk={() => upload()}
      okText={<FormatMessage id='upload' />}
      okButtonProps={{
        disabled: uploadingFile.fileList.length === 0,
        loading: uploadingFile.uploading,
      }}
      onCancel={() => {
        onHide();
      }}
      cancelText={<FormatMessage id='cancel' />}
    >
      <Form form={form} name='control-ref'>
        <div className='d-flex flex-column'>
          <p><FormatMessage id="inventories.upload.csvTypeDescription"/></p>
          <Radio.Group onChange={onCsvTypeChange} value={csvType}>
            <Radio value={UPLOAD_CSV_TYPES.SEMICOLON}>
              <FormatMessage id='inventories.upload.radio.semicolon' />
            </Radio>
            <Radio value={UPLOAD_CSV_TYPES.COMMA}>
              <FormatMessage id='inventories.upload.radio.comma' />
            </Radio>
          </Radio.Group>

          <br/>

          <Upload {...props}>
            <Button icon={<UploadOutlined />}>
              {' '}
              <FormatMessage id='selectFile' />
            </Button>
          </Upload>
        </div>
      </Form>
    </Modal>
  );
};

export default ImportInventory;
