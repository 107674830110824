import { Route, Redirect } from 'react-router-dom';
import store from 'store';

export const PrivateRoute = ({ component, path, exact }) => {
  const authorization = store.get('_t');
  if (authorization) {
    return <Route exact={exact} path={path} component={component} />;
  }

  return <Redirect to="/" />;
};

export default PrivateRoute;