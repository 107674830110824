import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import { FormatMessage } from '../../../../hocs';
import { TextItem, SwitchItem, SpanItem, NumberItem, TextEditorItem } from '../../../../components';
import SingleImageItem from '../../../../components/formItems/SingleImageItem';

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddEditCmsContent = ({ isVisible, type, item, onHide, saveCmsContent, cmsContent, cmsPage, typeEnums, validationErrors }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  console.log(item);

  useEffect(() => {
    if (isVisible && type === 'edit') {
      form.setFieldsValue({ ...item });
    } else form.resetFields();
  }, [item, isVisible, type, form]);

  const handleOk = () => {
    if (type === 'new') {
      form
        .validateFields()
        .then(async (values) => {
          setIsLoading(true);
          const req = {
            cmsPageId: cmsPage.id,
            ...values,
          };
          await saveCmsContent(req, 'new');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    } else if (type === 'edit') {
      form
        .validateFields()
        .then(async (values) => {
          const data = {
            ...values,
            id: item.id,
            cmsPageId: cmsPage.id,
          };
          setIsLoading(true);
          await saveCmsContent(data, 'edit');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }
  };

  return (
    <Modal
      title={type === 'edit' ? <FormatMessage id='cmsContents.editModalTitle' /> : <FormatMessage id='cmsContents.addModalTitle' />}
      visible={isVisible}
      confirmLoading={isLoading}
      onOk={() => handleOk()}
      width={1000}
      okText={<FormatMessage id='save' />}
      onCancel={() => {
        /* delete uploadPropsImage.defaultFileList;
        uploadPropsImage.fileList = []; */
        onHide();
      }}
      cancelText={<FormatMessage id='cancel' />}
    >
      <Form form={form} name='control-ref' initialValues={{ ...item }} layout='horizontal'>
        {cmsPage && <SpanItem value={cmsPage.title} label='cmsContents.parentCmsPage' layout={{ ...formItemLayout }} />}
        <TextItem name='title' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <TextEditorItem name='content' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <TextItem name='link' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <NumberItem name='displayOrder' label='displayOrder' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <SwitchItem name='active' label='active' validationErrors={validationErrors} value={item.active} layout={{ ...formItemLayout }} />
        <SingleImageItem
          name='imageId'
          label='image'
          layout={{ ...formItemLayout }}
          validationErrors={validationErrors}
          uri={item?.imageUri}
          dir='cmsContents'
        />
      </Form>
    </Modal>
  );
};

export default AddEditCmsContent;
