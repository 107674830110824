import { useState, useEffect } from 'react';
import { apiGet, apiDelete, apiPost, apiPut } from '../utils/api';
import _ from 'lodash';
import { successMessage, errorMessage, errorResponseMessage } from '../views/elements/ToastMessage';
export default function useFaqs(props) {
  const [faqs, setFaqs] = useState([]);
  const [hierarchyFaqs, setHierarchyFaqs] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [loadingFaqs, setLoadingFaqs] = useState(false);
  const [, setLoadingHierarchyFaqs] = useState(false);
  const [faqFailed, setFaqFailed] = useState(false);
  const [, setHierarchyFaqFailed] = useState(false);
  const [isDeletingFaq, setIsDeletingFaq] = useState(false);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [faqKeyword, setFaqKeyword] = useState('');
  const [faqModal, setFaqModal] = useState({
    isVisible: false,
    type: 'add',
    item: false,
    faq: {},
  });
  const [nestedData, setNestedData] = useState({});
  const [isLoading, setIsLoading] = useState({});

  const [savingFaq, setSavingFaq] = useState(false);

  useEffect(() => {
    if (faqs) {
      setFilteredFaqs(faqs.filter((item) => item.title.toLowerCase().includes(faqKeyword.toLowerCase()) || faqKeyword.length === 0));
    }
  }, [faqKeyword, faqs]);

  const saveFaq = async (faq, type) => {
    setSavingFaq(true);
    const response = type === 'new' ? await apiPost('/faqs', faq) : await apiPut('/faqs', faq);
    setSavingFaq(false);
    if (response && !response.errors && !response.error) {
      setFaqModal({
        ...faqModal,
        item: type === 'edit' ? response.data : response,
        type: 'edit',
        isVisible: false,
      });
      type === 'new' ? successMessage('saveSuccessMessage') : successMessage('editSuccessMessage');
      setValidationErrors({});
      fetchFaqs();
      if (faq.parentId) {
        await fetchInnerFaqs({ parentId: faq.parentId });
      }
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error)
    } else {
      setValidationErrors({});
      errorMessage('failMessage');
    }
  };

  const deleteFaq = async (id, parentId) => {
    setIsDeletingFaq(true);
    const response = await apiDelete('/faqs/' + id);
    setIsDeletingFaq(false);
    if (response) {
      successMessage('deleteSuccessMessage');
      if (parentId) {
        await fetchInnerFaqs({ parentId: parentId });
      } else {
        fetchFaqs();
      }
    } else {
      errorMessage('failMessage');
    }
  };

  const fetchFaqs = async ({ parentId = '', isShowTop = '', isShowHome = '', isActive = '' } = {}) => {
    setLoadingFaqs(true);

    const response = await apiGet(`/faqs/filter?parentId=${parentId}&isShowTop=${isShowTop}&isShowHome=${isShowHome}&isActive=${isActive}`);
    if (response === false) {
      setFaqFailed(true);
    } else {
      if (!_.isEmpty(response)) {
        const sorted = response.sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
        setFaqs(sorted);
      }
    }
    setLoadingFaqs(false);
    return response;
  };

  const fetchInnerFaqs = async ({ parentId = '', isFeatured = '', isActive = '' } = {}) => {
    setIsLoading({
      [parentId]: true,
    });

    const res = await apiGet(`/faqs/filter?parentId=${parentId}&isFeatured=${isFeatured}&isActive=${isActive}`);
    setNestedData((state) => ({
      ...state,
      [parentId]: [...res],
    }));
    setIsLoading({
      [parentId]: false,
    });
  };

  const fetchHierarchyFaqs = async () => {
    setLoadingHierarchyFaqs(true);

    const response = await apiGet(`/faqs/hierarchy`);
    if (response === false) {
      setHierarchyFaqFailed(true);
    } else {
      setHierarchyFaqs(response);
    }
    setLoadingHierarchyFaqs(false);
    return response;
  };

  return {
    faqs,
    fetchFaqs,
    deleteFaq,
    loadingFaqs,
    isDeletingFaq,
    faqFailed,
    faqModal,
    setFaqModal,
    filteredFaqs,
    faqKeyword,
    setFaqKeyword,
    saveFaq,
    savingFaq,
    fetchInnerFaqs,
    nestedData,
    isLoading,
    hierarchyFaqs,
    fetchHierarchyFaqs,
    validationErrors,
    setValidationErrors,
  };
}
