import { useState } from 'react';
import { MissingDataError } from 'react-intl';
import { apiDelete, apiGet, apiPost, apiPut } from '../utils/api';
import { errorMessage, errorResponseMessage } from '../views/elements/ToastMessage';

const useCoupons = () => {
  const [fetchedCoupons, setFetchedCoupons] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [isFetchingCoupons, setIsFetchingCoupons] = useState(false);
  const [isFetchingCouponsFailed, setIsFetchingCouponsFailed] = useState(false);
  const [fetchingErrorContainer, setFetchingErrorContainer] = useState({});
  const [isDeletingCoupon, setIsDeletingCoupon] = useState(false);
  const [deleteErrorContainer, setDeleteErrorContainer] = useState({});
  const [deleteResponse, setDeleteResponse] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [saveResponse, setSaveResponse] = useState({});
  const [updateResponse, setUpdateResponse] = useState({});
  const [validationErrors, setValidationErrors] = useState([])

  const ErrorTypes = Object.freeze({
    INTERNAL: 'internal',
    THROWED: 'throw',
  });

  const fetchCoupons = async (asc = false, orderBy = 'id', page = 0, pageSize = 25) => {
    try {
      setIsFetchingCoupons(true);
      const response = await apiGet(`/coupons?asc=${asc}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}`);

      if (!response) {
        setIsFetchingCouponsFailed(true);
        setIsFetchingCoupons(false);

        if (typeof response === 'object') {
          setFetchingErrorContainer({
            ...response,
            type: ErrorTypes.INTERNAL,
          });
          return;
        }
      }

      setFetchedCoupons(response);
      setIsFetchingCoupons(false);

      // content
      setCoupons(response?.content ?? []);

      return response;
    } catch (e) {
      setFetchingErrorContainer({
        type: ErrorTypes.THROWED,
        message: e.message,
        code: e.errorCode,
      });

      setIsFetchingCoupons(false);
    }
  };

  // Delete
  const deleteCoupon = async (id) => {
    try {
      setIsDeletingCoupon(true);

      if (!id) {
        throw new MissingDataError('Silinecek kayıt belirtilmedi !', { defaultMessage: 'Parameter Error' });
      }

      const response = await apiDelete(`/coupons/${id}`);

      if (!response) {
        throw new Error('Silinme işleminde bir sorun oluştu.', { defaultMessage: 'Error while deleting coupon' });
      }

      setDeleteResponse(response);
      setIsDeletingCoupon(false);

      fetchCoupons();
    } catch (e) {
      setDeleteErrorContainer({ ...e });
      setIsDeletingCoupon(false);
    }
  };

  const addCoupon = async (formData) => {
    try {
      setIsSaving(true);
      const response = await apiPost('/coupons', { ...formData });

      if (response && !response.errors && !response.error) {
        
        setIsSaving(false);
        setSaveResponse(response);
        await fetchCoupons();
        return response;

      } else if (response.errors) {

        const validErrors = {};
        response.errors.forEach((e) => {
          validErrors[e.key] = e.value;
        });
        setValidationErrors(validErrors);

      } else if (response.error) {
        
        errorMessage(response.error);
        setIsSaving(false);
        return;

      }

    } catch (e) {
      setIsSaving(false);
      console.log('coupon-add-error', e)
    }
  };

  const updateCoupon = async (formData) => {
    try {
      setIsSaving(true);

      const response = await apiPut(`/coupons`, { ...formData });

      if(response && !response.errors && !response.error) {
       
        setUpdateResponse(response);
        fetchCoupons();
        setIsSaving(false);

        return response;

      } else if (response.errors) {
        
        const validErrors = {};
        
        response.errors.forEach((e) => {
          validErrors[e.key] = e.value;
        });

        setValidationErrors(validErrors);
        setIsSaving(false);

      } else if (response.error) {
        errorResponseMessage(response.error);
        setIsSaving(false);
      }

    } catch (e) {
      setIsSaving(false);
      console.log('coupon-update-error', e)
    }
  };

  return {
    coupons,
    fetchCoupons,
    fetchedCoupons,
    isFetchingCoupons,
    isFetchingCouponsFailed,
    fetchingErrorContainer,
    isDeletingCoupon,
    deleteCoupon,
    deleteErrorContainer,
    deleteResponse,
    addCoupon,
    isSaving,
    saveResponse,
    updateCoupon,
    updateResponse,
    validationErrors,
    setValidationErrors
  };
};

export default useCoupons;
