import { FormatMessage } from '../../hocs';
import PropTypes from 'prop-types';
import { entriesIn } from 'lodash';

const EnumColumns = ({ name, label, enums, ellipsis = true }) => {
  return {
    title: <FormatMessage id={label ? label : name} />,
    dataIndex: name,
    key: name,
    render: (type) => {
      if (enums) {
        const translatedKey = enums.filter((e) => e.key === type)[0];
        return translatedKey?.value ? translatedKey?.value : type;
      } else {
        return type;
      }
    },
    ellipsis,
  };
};

EnumColumns.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  enums: PropTypes.object,
  ellipsis: PropTypes.bool,
};

export default EnumColumns;
