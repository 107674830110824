import React, { useEffect, useState } from 'react';
import { Form, Select, Modal, TreeSelect } from 'antd';
import { FormatMessage } from '../../../hocs';
import intl from 'react-intl-universal';
import _ from 'lodash';
import 'react-quill/dist/quill.snow.css';
import { NumberItem, SelectItem, SwitchItem, TagsItem, TextEditorItem, TextItem } from '../../../components';
import useProductColorVariants from '../../../hooks/useProductColorVariants'
import useCategories from '../../../hooks/useCategories';
import { preparedTreeData } from '../checklist-categories/_add';


const { Option } = Select;

const { SHOW_PARENT } = TreeSelect

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddEditProduct = ({
  isVisible,
  type,
  item,
  onHide,
  saveProduct,
  productStatusEnums,
  productDeliveryTypeEnums,
  productCargoTypeEnums,
  dropDowns,
  productVatTypes,
  validationErrors,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchHierarchyMappedCategories, hierarchyMappedCategories } = useCategories();
  const { fetchProductVariants, productVariants } = useProductColorVariants();
  
  useEffect(() => {
    if (isVisible && type === 'edit') {
      form.setFieldsValue({
        ...item,
        badges: !_.isEmpty(item.badges) ? _.map(item.badges, 'id') : [],
        tags: !_.isEmpty(item.tags) ? _.map(item.tags, 'id') : [],
        attributeValues: !_.isEmpty(item.attributeValues) ? _.map(item.attributeValues, 'valueId') : [],
      });

     
     
      
    } else {
      form.resetFields();
    }
  }, [item, isVisible, type, form]);

  


  useEffect(() => {
    fetchHierarchyMappedCategories();
    
  }, []);

  useEffect(() => {
    if(item && item.id){
      fetchProductVariants({productId:item.id});
    }
    
  }, []);

 
  

  const handleOk = () => {
    if (type === 'new') {
      form
        .validateFields()
        .then(async (values) => {
          setIsLoading(true);
          const request = {
            ...values,
            unit: 'PIECE',
          };
          await saveProduct(request, 'new');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    } else if (type === 'edit') {
      form
        .validateFields()
        .then(async (values) => {
          const productScores = document.querySelectorAll(".scores-input-wrapper input");
          
          let productScoreValues = [];
          productScores.forEach(productScore=>{
            let nameAttr = productScore.getAttribute('name');
            let name = nameAttr.split("_");
            let id = name[1];

            if(productScore.value!=''){
              productScoreValues.push({
                variantValueId:id,
                score:productScore.value * 1
              })
            }
              
          

            
            

            
          });
          
          const data = {
            ...values,
            id: item.id,
            unit: 'PIECE',
            variantScores:productScoreValues
          };
          
          setIsLoading(true);
          await saveProduct(data, 'edit');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }
  };


  const tProps = {
    treeCheckable: false,
    showCheckedStrategy: SHOW_PARENT,
    treeDefaultExpandAll: true,
    style: {
      width: '100%',
    },
  };


  
  return (
    <Modal
      title={type === 'edit' ? <FormatMessage id='products.editModalTitle' /> : <FormatMessage id='products.addModalTitle' />}
      width={1000}
      visible={isVisible}
      confirmLoading={isLoading}
      onOk={() => handleOk()}
      okText={<FormatMessage id='save' />}
      onCancel={() => {
        onHide();
      }}
      cancelText={<FormatMessage id='cancel' />}
    >
      <Form form={form} name='control-ref' layout='horizontal' style={{ overflow: 'auto', height: '70vh' }}>
        <TextItem name='title' label='title' layout={{ ...formItemLayout }} validationErrors={validationErrors} />
        <TextItem name='code' label='products.code' layout={{ ...formItemLayout }} validationErrors={validationErrors} />
        <TextEditorItem editorClassName="long-description" name='fullDesc' label='products.fullDesc' layout={{ ...formItemLayout }} validationErrors={validationErrors} />
        {/* <TextEditorItem name='shortDesc' label='products.shortDesc' layout={{ ...formItemLayout }} validationErrors={validationErrors} />
        <TextEditorItem name='details' label='details' layout={{ ...formItemLayout }} validationErrors={validationErrors} /> */}
        <SelectItem
          name='brandId'
          label='brand'
          placeholder={intl.formatMessage({ id: 'brand' })}
          layout={{ ...formItemLayout }}
          search
          validationErrors={validationErrors}
        >
          {dropDowns.brands.map((c) => (
            <Option value={c.id} disabled={!c.active}>{c.title}</Option>
          ))}
        </SelectItem>
        <SelectItem
          name='dealerId'
          label='dealer'
          placeholder={intl.formatMessage({ id: 'dealer' })}
          layout={{ ...formItemLayout }}
          search
          validationErrors={validationErrors}
        >
          {dropDowns.dealers.map((c) => (
            <Option value={c.id} disabled={!c.active}>{c.title}</Option>
          ))}
        </SelectItem>
        {/* <SelectItem
          name='categoryId'
          label='category'
          placeholder={intl.formatMessage({ id: 'category' })}
          layout={{ ...formItemLayout }}
          search
          validationErrors={validationErrors}
        >
          {dropDowns.categories.map((c) => (
            <Option value={c.id}>{c.title}</Option>
          ))}
        </SelectItem> */}

        <Form.Item
          name='categoryId'
          label={<FormatMessage id='checklistCategories.category' />}
          validateStatus={validationErrors?.categories && 'error'}
          help={validationErrors?.categories}
          {...formItemLayout}
        >
          <TreeSelect
            {...tProps}
            treeData={preparedTreeData(hierarchyMappedCategories, false)}
            placeholder={intl.formatMessage({
              id: 'checklistCategories.addCategoryPlaceHolder',
            })}
            autoClearSearchValue={true}
          />
        </Form.Item>

        <SelectItem
          name='vatType'
          label='vatType'
          placeholder={intl.formatMessage({ id: 'vatType' })}
          layout={{ ...formItemLayout }}
          validationErrors={validationErrors}
        >
          {productVatTypes.map((c) => (
            <Option value={c.key}>{c.value}</Option>
          ))}
        </SelectItem>
        <SelectItem
          name='status'
          label='status'
          placeholder={intl.formatMessage({ id: 'status' })}
          layout={{ ...formItemLayout }}
          validationErrors={validationErrors}
        >
          {productStatusEnums.map((c) => (
            <Option value={c.key}>{c.value}</Option>
          ))}
        </SelectItem>
        <SelectItem
          name='deliveryType'
          label='deliveryType'
          placeholder={intl.formatMessage({ id: 'deliveryType' })}
          layout={{ ...formItemLayout }}
          validationErrors={validationErrors}
        >
          {productDeliveryTypeEnums.map((c) => (
            <Option value={c.key}>{c.value}</Option>
          ))}
        </SelectItem>
        <SelectItem
          name='cargoType'
          label='cargoType'
          placeholder={intl.formatMessage({ id: 'cargoType' })}
          layout={{ ...formItemLayout }}
          validationErrors={validationErrors}
        >
          {productCargoTypeEnums.map((c) => (
            <Option value={c.key}>{c.value}</Option>
          ))}
        </SelectItem>
        <NumberItem
          name='listPriceIncVat'
          label='listPrice'
          step='0.01'
          layout={{ ...formItemLayout }}
          validationErrors={validationErrors}
        />
        <NumberItem
          name='sellingPriceIncVat'
          label='sellingPrice'
          step='0.01'
          layout={{ ...formItemLayout }}
          validationErrors={validationErrors}
        />

        <TextItem
          name='estimatedDelivery'
          label='estimatedDelivery'
          layout={{ ...formItemLayout }}
          validationErrors={validationErrors} />

        <TextEditorItem
          editorClassName="long-description"
          name='shipmentAndReturn'
          label='shipmentAndReturn'
          layout={{ ...formItemLayout }}
          validationErrors={validationErrors} />

        <SwitchItem
          name='couponApplicable'
          label='couponApplicable'
          value={item.couponApplicable} layout={{ ...formItemLayout }}
          validationErrors={validationErrors} />

        <SwitchItem
          name='active'
          label='active'
          value={item.active} layout={{ ...formItemLayout }}
          validationErrors={validationErrors} />

        <TagsItem
          name='attributeValues'
          label='attributeValues.title'
          layout={{ ...formItemLayout }}
          placeholder={intl.formatMessage({ id: 'attributeValues.title' })}
          validationErrors={validationErrors}
        >
          {dropDowns.attributeValues.map((c) => (
            <Option value={c.id} disabled={!c.active}>{c.title}</Option>
          ))}
        </TagsItem>
        <TagsItem
          name='tags'
          label='tags.title'
          layout={{ ...formItemLayout }}
          placeholder={intl.formatMessage({ id: 'tags.title' })}
          validationErrors={validationErrors}
        >
          {dropDowns.tags.map((c) => (
            <Option value={c.id} disabled={!c.active}>{c.title}</Option>
          ))}
        </TagsItem>
        <TagsItem
          name='badges'
          label='badges.title'
          layout={{ ...formItemLayout }}
          placeholder={intl.formatMessage({ id: 'badges.title' })}
          validationErrors={validationErrors}
        >
          {dropDowns.badges.map((c) => (
            <Option value={c.id} disabled={!c.active}>{c.title}</Option>
          ))}
        </TagsItem>
        <NumberItem name='estimatedDeliveryDays' label={<FormatMessage id={'brands.estimatedDeliveryDays'} />} validationErrors={validationErrors} layout={{ ...formItemLayout }} />

        <NumberItem name='score' label={<FormatMessage id={'products.productScore'} />} validationErrors={validationErrors} layout={{ ...formItemLayout }} />

        <SwitchItem
          name='acceptReturn'
          label={'products.acceptReturn'}
          value={(!item || !item.id) ? true : item.acceptReturn} layout={{ ...formItemLayout }}
          validationErrors={validationErrors} />

          {productVariants.length>0 && <>
          
            <hr />
        <h3>Variant Skorları</h3> 

        <div className='scores-input-wrapper'>
        
        
       
                

                {productVariants.map((productVariant)=>{
                  let nameX = 'productVariantScore_'+productVariant.variantValueId;
                  let variantData = item.variantScores.find((variant)=>{return variant.variantValueId==productVariant.variantValueId});
                  let variantScoreX = variantData ? variantData.score : '';
                  
                  return (
                    <>
                      
                      
                       <NumberItem
                        label={<FormatMessage nonLocalize={true} id={productVariant.title} />}
                        name={nameX}
                        step='1'
                        layout={{ ...formItemLayout }}
                        inputProps={{class:'ant-input-number-input pr-s', name: nameX, defaultValue:variantScoreX }}
                        validationErrors={validationErrors}
                      />
                    </>
                  )
                })}
        </div>


          </>}
        
      </Form>
    </Modal>
  );
};

export default AddEditProduct;
