import React, { useEffect,useState } from 'react';
import { Table, Row, Col, Input, Space, Button, Modal, Tooltip, Divider } from 'antd';
import { ExclamationCircleOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import intl from 'react-intl-universal';
import { FormatMessage } from '../../../hocs';
import GLayout from '../../gLayout';
import useCmsPages from '../../../hooks/useCmsPages';
import AddEditCmsPage from './_add';
import styles from './cmsPages.module.css';
import { BasicColumn, BooleanColumn, IdColumn } from '../../../components';
import CmsContents from './cms-contents';

const { confirm } = Modal;
const { Search } = Input;

const CmsPages = () => {
  const {
    filteredCmsPages,
    cmsPageKeyword,
    setCmsPageKeyword,
    fetchCmsPages,
    saveCmsPage,
    loadingCmsPages,
    deleteCmsPage,
    setCmsPageModal,
    cmsPageModal,
    isDeletingCmsPage,
    cmsPages,
    fetchInnerCmsPages,
    nestedData,
    isLoading,
    validationErrors,
    setValidationErrors,
  } = useCmsPages();

  const [selectedCmsPage, setSelectedCmsPage] = useState(null);

  useEffect(() => {
    fetchCmsPages();
  }, []);

  useEffect(() => {
    if (filteredCmsPages && filteredCmsPages.length > 0) {
      setSelectedCmsPage(filteredCmsPages[0]);
    } else {
      setSelectedCmsPage(null);
    }
  }, [filteredCmsPages]);

  const showDeleteConfirm = (record) => {
    confirm({
      title: <FormatMessage id='cmsPages.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingCmsPage,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteCmsPage(record.id, record.parentId);
          //await fetchNestedCmsPages(record.parentId);

          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const columns = ({ level = 0 }) => [
    IdColumn(),
    BasicColumn({ name: 'title' }),
    BasicColumn({ name: 'content' }),
    BasicColumn({ name: 'slug' }),
    BooleanColumn(),
    {
      title: <FormatMessage id='actions' />,
      key: 'action',
      width: 180,
      render: (text, record) => (
        <Space size='middle'>
          <Tooltip title={<FormatMessage id='edit' />}>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setCmsPageModal({
                  type: 'edit',
                  isVisible: true,
                  item: record,
                });
              }}
            />
          </Tooltip>

          <Tooltip title={<FormatMessage id='delete' />}>
            <Button danger onClick={() => showDeleteConfirm(record)} icon={<DeleteOutlined />}></Button>
          </Tooltip>
          {level < 1 && (
            <Tooltip title={<FormatMessage id='cmsPages.addModalTitle' />}>
              <Button
                type='success'
                ghost
                onClick={() =>
                  setCmsPageModal({
                    isVisible: true,
                    type: 'new',
                    item: {},
                    cmsPage: record,
                  })
                }
                icon={<PlusOutlined />}
              ></Button>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    const level = record.parentId ? 1 : 0;
    const data = nestedData[record.id];
    return (
      <Table
        bordered
        showHeader={false}
        loading={isLoading[record.id]}
        columns={columns({ level: level })}
        pagination={false}
        dataSource={data}
        rowKey={(record) => record.id}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setSelectedCmsPage(record);
            },
          };
        }}
        rowClassName={(record, index) => {
          if (selectedCmsPage?.id === record.id) {
            console.log(styles.scs);
            return styles.selectedRow + ' ' + styles.pointerRow;
          }
          return styles.pointerRow;
        }}
        expandable={level < 1 && { expandedRowRender }}
        onExpand={level < 1 && handleExpand}
      />
    );
  };

  const handleExpand = async (expanded, record) => {
    console.log(expanded);
    fetchInnerCmsPages({ parentId: record.id });
  };

  return (
    <GLayout>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setCmsPageKeyword(e.target.value)}
              value={cmsPageKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              disabled={loadingCmsPages}
              onClick={() =>
                setCmsPageModal({
                  isVisible: true,
                  type: 'new',
                  item: {},
                })
              }
              style={{ float: 'right' }}
            >
              <FormatMessage id='cmsPages.addModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        bordered
        rowKey={(record) => record.id}
        columns={columns({ level: 0 })}
        dataSource={filteredCmsPages}
        loading={loadingCmsPages}
        scroll={{ x: 1500 }}
        expandable={{ expandedRowRender }}
        onExpand={handleExpand}
        pagination={filteredCmsPages.length > 10 ? true : false}
        sticky
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setSelectedCmsPage(record);
            },
          };
        }}
        rowClassName={(record, index) => {
          if (selectedCmsPage?.id === record.id) {
            console.log(styles.scs);
            return styles.selectedRow + ' ' + styles.pointerRow;
          }
          return styles.pointerRow;
        }}
      />
      {selectedCmsPage && (
        <>
          <Divider />
          <CmsContents cmsPage={selectedCmsPage}/>
        </>
      )}
      {cmsPageModal.isVisible && (
        <AddEditCmsPage
          onHide={() => {
            setValidationErrors({});
            setCmsPageModal({ ...cmsPageModal, isVisible: false });
          }}
          {...cmsPageModal}
          saveCmsPage={saveCmsPage}
          validationErrors={validationErrors}
        />
      )}
    </GLayout>
  );
};

export default CmsPages;
