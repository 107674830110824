import intl from 'react-intl-universal';

const currentLocale = intl.determineLocale({
  urlLocaleKey: 'lang',
  cookieLocaleKey: 'lang',
});

const FormatMessage = ({ id, options, nonLocalize, upperCase }) => (
  
  upperCase ? (nonLocalize ? id : intl.get(id, options || {}).toLocaleUpperCase(currentLocale)) : nonLocalize ? id : (intl.get(id, options || {}))
);

export default FormatMessage;
