import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import { FormatMessage } from '../../../hocs';
import { TextItem, SwitchItem, SpanItem } from '../../../components';

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddEditCmsPage = ({ isVisible, type, item, onHide, saveCmsPage, cmsPage, typeEnums, validationErrors }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isVisible && type === 'edit') {
      form.setFieldsValue({ ...item });
    } else form.resetFields();
  }, [item, isVisible, type, form]);

  const handleOk = () => {
    if (type === 'new') {
      form
        .validateFields()
        .then(async (values) => {
          setIsLoading(true);
          const req = cmsPage
            ? {
                parentId: cmsPage.id,
                ...values,
              }
            : { ...values };
          await saveCmsPage(req, 'new');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    } else if (type === 'edit') {
      form
        .validateFields()
        .then(async (values) => {
          const data = {
            ...values,
            id: item.id,
            parentId: item.parentId,
          };
          setIsLoading(true);
          await saveCmsPage(data, 'edit');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }
  };

  return (
    <Modal
      title={type === 'edit' ? <FormatMessage id='cmsPages.editModalTitle' /> : <FormatMessage id='cmsPages.addModalTitle' />}
      visible={isVisible}
      confirmLoading={isLoading}
      onOk={() => handleOk()}
      okText={<FormatMessage id='save' />}
      onCancel={onHide}
      cancelText={<FormatMessage id='cancel' />}
    >
      <Form form={form} name='control-ref' initialValues={{ ...item }} layout='horizontal'>
        {cmsPage && <SpanItem value={cmsPage.title} label='cmsPages.parentCmsPage' layout={{ ...formItemLayout }} />}
        <TextItem name='title' label='title' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <TextItem name='content' label='content' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <TextItem name='slug' label='slug' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <SwitchItem name='active' label='active' validationErrors={validationErrors} value={item.active} layout={{ ...formItemLayout }} />
      </Form>
    </Modal>
  );
};

export default AddEditCmsPage;
