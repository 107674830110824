import { SET_USER,SET_USER_TYPE } from '../actions/types';

const INITIAL_STATE = {
  user: false,
  userType : null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
