import { useState, useEffect } from "react";
import { apiGet, apiDelete, apiPost, apiPut } from "../utils/api";
import { RESPONSE_STATS } from "../utils/constants";
import { successMessage, errorMessage, errorResponseMessage } from "../views/elements/ToastMessage";
export default function useBadges(props) {
  const [badges, setBadges] = useState([]);
  const [loadingBadges, setLoadingBadges] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [badgeFailed, setBadgeFailed] = useState(false);
  const [isDeletingBadge, setIsDeletingBadge] = useState(false);
  const [filteredBadges, setFilteredBadges] = useState([]);
  const [badgeKeyword, setBadgeKeyword] = useState("");
  const [typeEnums, setTypeEnums] = useState([]);
  const [pagination, setPagination] = useState({});
  const [badgeModal, setBadgeModal] = useState({
    isVisible: false,
    type: "add",
    item: false,
  });

  const [savingBadge, setSavingBadge] = useState(false);
  const [saveBadgeResult, setSaveBadgeResult] = useState(RESPONSE_STATS.NOTR);
  const [editBadgeResult, setEditBadgeResult] = useState(RESPONSE_STATS.NOTR);
  const [deleteBadgeResult, setDeleteBadgeResult] = useState(
    RESPONSE_STATS.NOTR
  );

  useEffect(() => {
    if (badges && badges.content) {
      setFilteredBadges(
        badges.content?.filter(
          (item) =>
            item.name.toLowerCase().includes(badgeKeyword.toLowerCase()) || badgeKeyword.length === 0
        )
      );
    }
  }, [badgeKeyword, badges]);

  const fetchTypeEnums = async () => {
    const response = await apiGet("/enums/BadgeType");
    setTypeEnums(response);
  };

  const saveBadge = async (badge, type) => {
    setSavingBadge(true);
    const response =
      type === "new"
        ? await apiPost("/badges", badge)
        : await apiPut("/badges", badge);
    setSavingBadge(false);
    if (response && !response.errors && !response.error) {
      setBadgeModal({
        ...badgeModal,
        item: type === "edit" ? response.data : response,
        type: "edit",
        isVisible: false,
      });
      console.log("tyep " + type);
      type === "new"
        ? setSaveBadgeResult(RESPONSE_STATS.SUCCESS)
        : setEditBadgeResult(RESPONSE_STATS.SUCCESS);
      setValidationErrors({});
      fetchBadges();
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error);
    } else {
      setValidationErrors({});
      type === "new"
        ? setSaveBadgeResult(RESPONSE_STATS.FAIL)
        : setEditBadgeResult(RESPONSE_STATS.FAIL);
    }
  };

  const deleteBadge = async (id) => {
    setIsDeletingBadge(true);
    const response = await apiDelete("/badges/" + id);
    setIsDeletingBadge(false);
    if (response) {
      setDeleteBadgeResult(RESPONSE_STATS.SUCCESS);
      fetchBadges();
    } else {
      setDeleteBadgeResult(RESPONSE_STATS.FAIL);
    }
  };

  const fetchBadges = async ({ page = 0, pageSize = 10, asc = false } = {}) => {
    setLoadingBadges(true);

    const response = await apiGet(
      `/badges?page=${page}&pageSize=${pageSize}&asc=${asc}`
    );
    if (response === false) {
      setBadgeFailed(true);
    } else {
      setBadges(response);
      if (response.totalElements < 11) {
        setPagination(false);
      } else {
        setPagination({
          current: response.page + 1,
          pageSize: response.pageSize,
          total: response.totalElements,
          showSizeChanger: true,
        });
      }
    }
    await fetchTypeEnums();
    setLoadingBadges(false);
    return response;
  };

  useEffect(() => {
    if (saveBadgeResult === RESPONSE_STATS.SUCCESS) {
      successMessage("saveSuccessMessage");
    } else if (saveBadgeResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setSaveBadgeResult(RESPONSE_STATS.NOTR);
  }, [saveBadgeResult]);

  useEffect(() => {
    if (editBadgeResult === RESPONSE_STATS.SUCCESS) {
      successMessage("editSuccessMessage");
    } else if (editBadgeResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setEditBadgeResult(RESPONSE_STATS.NOTR);
  }, [editBadgeResult]);

  useEffect(() => {
    if (deleteBadgeResult === RESPONSE_STATS.SUCCESS) {
      successMessage("deleteSuccessMessage");
    } else if (deleteBadgeResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setDeleteBadgeResult(RESPONSE_STATS.NOTR);
  }, [deleteBadgeResult]);

  return {
    badges,
    fetchBadges,
    deleteBadge,
    loadingBadges,
    isDeletingBadge,
    badgeFailed,
    badgeModal,
    setBadgeModal,
    filteredBadges,
    badgeKeyword,
    setBadgeKeyword,
    saveBadge,
    savingBadge,
    saveBadgeResult,
    typeEnums,
    pagination,
    validationErrors,
    setValidationErrors,
  };
}
