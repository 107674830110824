import { Space, Tooltip, Button } from 'antd';
import { FormatMessage } from '../../hocs';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const ApproveRejectColumn = ({ onApproveClick, onRejectClick, width, loading, checkKey, checkField }) => {
  return {
    title: <FormatMessage id='actions' />,
    key: 'action',
    width: width ? width : 180,
    render: (text, record) => {
      if (checkKey && checkField) {
        if (checkKey !== text[checkField]) {
          return <FormatMessage id={text[checkField]} />;
        }
      }
      return (
        <Space size='middle'>
          <Tooltip title={<FormatMessage id='approve' />}>
            <Button
              icon={<CheckOutlined />}
              loading={loading}
              onClick={() => {
                onApproveClick({ type: 'approve', record });
              }}
            />
          </Tooltip>

          <Tooltip title={<FormatMessage id='reject' />}>
            <Button loading={loading} danger onClick={() => onRejectClick({ type: 'reject', record })} icon={<CloseOutlined />}></Button>
          </Tooltip>
        </Space>
      );
    },
  };
};

ApproveRejectColumn.propTypes = {
  onApproveClick: PropTypes.func,
  onRejectClick: PropTypes.func,
  width: PropTypes.number,
};

export default ApproveRejectColumn;
