import { FormatMessage } from '../../hocs';
import ItemImage from '../../views/elements/ItemImage';
import PropTypes from 'prop-types';

const ImageColumn = ({ name, label, preview = false }) => {
  return {
    title: <FormatMessage id={label ? label : name} />,
    dataIndex: name,
    key: name,
    render: (imageUri) => {
      return imageUri && <ItemImage width={25} height={25} preview={preview} src={imageUri} />;
    },
  };
};

ImageColumn.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  preview: PropTypes.bool,
};

export default ImageColumn;
