import React, { useEffect, useState } from 'react';
import { Form, Select, Modal } from 'antd';
import intl from 'react-intl-universal';
import _ from 'lodash';
import { FormatMessage } from '../../../hocs';
import { SwitchItem, NumberItem, TextItem, TagsItem, SelectItem } from '../../../components';
import useProducts from '../../../hooks/useProducts';
const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddEditInventory = ({
  isVisible,
  type,
  item,
  onHide,
  saveInventory,
  inventoryStatusEnums,
  variantValues,
  products,
  validationErrors,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isVisible && type === 'edit') {
      form.setFieldsValue({
        ...item,
        variantValues: !_.isEmpty(item.variantValues) ? _.map(item.variantValues, 'variantValueId') : [],
      });
    } else {
      form.resetFields();
    }
  }, []);

  const handleOk = () => {
    if (type === 'new') {
      form
        .validateFields()
        .then(async (values) => {
          setIsLoading(true);
          console.log(values);
          await saveInventory(values, 'new');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    } else if (type === 'edit') {
      form
        .validateFields()
        .then(async (values) => {
          const data = {
            ...values,
            id: item.id,
          };
          setIsLoading(true);
          await saveInventory(data, 'edit');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }
  };
  console.log(form.getFieldsValue(true));
  return (
    <Modal
      title={type === 'edit' ? <FormatMessage id='inventories.editModalTitle' /> : <FormatMessage id='inventories.addModalTitle' />}
      width={800}
      visible={isVisible}
      confirmLoading={isLoading}
      onOk={() => handleOk()}
      okText={<FormatMessage id='save' />}
      onCancel={() => {
        onHide();
      }}
      cancelText={<FormatMessage id='cancel' />}
    >
      <Form form={form} name='control-ref' layout='horizontal'>
        <SelectItem
          name='productId'
          label='product'
          validationErrors={validationErrors}
          search
          layout={{ ...formItemLayout }}
        >
          {products.map((p) => (
            <Option value={p.id} disabled={!p.active}>
              {p.title}
            </Option>
          ))}
        </SelectItem>
        <TextItem name='title' label='title' layout={{ ...formItemLayout }} validationErrors={validationErrors} />
        <TextItem name='barcode' label='barcode' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <NumberItem name='quantityStock' label='quantity' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <NumberItem
          name='recommendedQuantity'
          label='recommendedQuantity'
          validationErrors={validationErrors}
          layout={{ ...formItemLayout }}
        />
        <NumberItem
          name='listPriceIncVat'
          label='listPrice'
          step='0.01'
          validationErrors={validationErrors}
          layout={{ ...formItemLayout }}
        />
        <NumberItem
          name='sellingPriceIncVat'
          label='sellingPrice'
          step='0.01'
          validationErrors={validationErrors}
          layout={{ ...formItemLayout }}
        />

        <NumberItem
          name='comissionRatio'
          label='comissionRatio'
          step='0.01'
          validationErrors={validationErrors}
          layout={{ ...formItemLayout }}
        />

        <TagsItem
          name='variantValues'
          label='variantValues.title'
          validationErrors={validationErrors}
          layout={{ ...formItemLayout }}
          placeholder={intl.formatMessage({ id: 'variantValues.title' })}
        >
          {variantValues.map((c) => (
            <Option value={c.id} disabled={!c.active}>
              {c.title}
            </Option>
          ))}
        </TagsItem>
        <SelectItem name='status' label='status' layout={{ ...formItemLayout }} validationErrors={validationErrors}>
          {inventoryStatusEnums.map((i) => (
            <Option value={i.key}>{i.value}</Option>
          ))}
        </SelectItem>
        <SwitchItem name='active' label='active' validationErrors={validationErrors} value={item.active} layout={{ ...formItemLayout }} />
        <TextItem name='shortDesc' label='package.shortDesc' layout={{ ...formItemLayout }} validationErrors={validationErrors} />
        <NumberItem
          name='duration'
          label='package.duration'
          step='1'
          validationErrors={validationErrors}
          layout={{ ...formItemLayout }}
        />
      </Form>
    </Modal>
  );
};

export default AddEditInventory;
