import React from 'react';
import { Form, Upload, Button, message } from 'antd';
import { FormatMessage } from '../../hocs';
import { UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { getToken } from '../../utils/api';
import { errorMessage } from '../../views/elements/ToastMessage';

const SingleImageItem = ({ name = '', label = '', layout = null, validationErrors = null, uri = '', dir }) => {
  const token = getToken();
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      errorMessage('filePngJpg');
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      errorMessage('fileSizeLarger1MB');
    }
    return isJpgOrPng && isLt1M ? true : Upload.LIST_IGNORE;
  }
  const uploadPropsImage = {
    name: 'file',
    action: process.env.REACT_APP_API_URL + '/files',
    maxCount: 1,
    listType: 'picture',
    beforeUpload: beforeUpload,
    defaultFileList: uri
      ? [
          {
            url: `${uri}`,
            name: 'image.png',
          },
        ]
      : [],
    headers: {
      authorization: 'Bearer ' + token,
      dir: dir,
    },
    previewVisible: true,
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        console.log(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
        console.log(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Form.Item
      name={name}
      label={<FormatMessage id={label ? label : name} />}
      validateStatus={validationErrors?.[name] && 'error'}
      help={validationErrors?.[name]}
      getValueFromEvent={(e) => e && e.file?.response?.id}
      {...layout}
    >
      <Upload {...uploadPropsImage}>
        <Button icon={<UploadOutlined />}>
          {' '}
          <FormatMessage id='dropFiles' />
        </Button>
      </Upload>
    </Form.Item>
  );
};

SingleImageItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  layout: PropTypes.object.isRequired,
  uri: PropTypes.string,
  dir: PropTypes.string.isRequired,
  validationErrors: PropTypes.object,
};

export default SingleImageItem;
