import { useEffect, useState } from 'react';
import { apiGet, apiPost, apiPut } from '../utils/api';

export default function useProductColorVariants() {
  const [productVariants, setProductVariants] = useState([]);
  const [loadingProductMedias, setLoadingProductMedias] = useState(false);
  const [loadingProductFailed, setProductMediaFailed] = useState(false);


  const fetchProductVariants = async ({ productId = ''} = {}) => {
   

    const response = await apiGet(`/inventories/color-variants/${productId}`);
    setLoadingProductMedias(true);
    if (response === false) {
        setProductMediaFailed(true);
    } else {
      setProductVariants(response);
    }
    setLoadingProductMedias(false);
   
  };

  

  return {
    fetchProductVariants,
    productVariants
  };
}
