import { useState, useEffect } from "react";
import { apiGet, apiDelete, apiPost, apiPut } from "../utils/api";
import _ from "lodash";
import { RESPONSE_STATS } from "../utils/constants";
import { successMessage, errorMessage, errorResponseMessage } from "../views/elements/ToastMessage";
export default function useUsers() {
  const [pageUsers, setPageUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isSavingUser, setIsSavingUser] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);
  const [isChangingPassword, setIsChangingPassword] = useState(false);

  const [pagination, setPagination] = useState({});
  const [userKeyword, setUserKeyword] = useState("");

  const [userModal, setUserModal] = useState({
    isVisible: false,
    type: "add",
    item: false,
  });
  const [changePasswordModal, setChangePasswordModal] = useState({
    isVisible: false,
    userId: null,
  });

  const [fetchUserResult, setFetchUserResult] = useState(RESPONSE_STATS.NOTR);
  const [saveUserResult, setSaveUserResult] = useState(RESPONSE_STATS.NOTR);
  const [editUserResult, setEditUserResult] = useState(RESPONSE_STATS.NOTR);
  const [deleteUserResult, setDeleteUserResult] = useState(RESPONSE_STATS.NOTR);
  const [changePasswordResult, setChangePasswordResult] = useState(
    RESPONSE_STATS.NOTR
  );

  useEffect(() => {
    if (users && users.content) {
      setFilteredUsers(
        users.content?.filter(
          (item) => item.email.toLowerCase().includes(userKeyword.toLowerCase()) || userKeyword.length === 0
        )
      );
    }
  }, [userKeyword, users]);

  const saveUser = async (user, type) => {
    setIsSavingUser(true);
    setSaveUserResult(RESPONSE_STATS.NOTR);
    setEditUserResult(RESPONSE_STATS.NOTR);
    const response =
      type === "new"
        ? await apiPost("/users", user)
        : await apiPut("/users", user);
    setIsSavingUser(false);
    if (response && !response.errors && !response.error) {
      setUserModal({
        ...userModal,
        item: type === "edit" ? response.data : response,
        type: "edit",
        isVisible: false,
      });
      type === "new"
        ? setSaveUserResult(RESPONSE_STATS.SUCCESS)
        : setEditUserResult(RESPONSE_STATS.SUCCESS);
      setValidationErrors({});
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error);
    } else {
      setValidationErrors({});
      type === "new"
        ? setSaveUserResult(RESPONSE_STATS.FAIL)
        : setEditUserResult(RESPONSE_STATS.FAIL);
    }
  };

  const changePassword = async (userId, { confirm = "", password = "" }) => {
    setIsChangingPassword(true);
    setChangePasswordResult(RESPONSE_STATS.NOTR);
    const request = {
      confirm: confirm,
      password: password,
    };
    const response = await apiPost(`/users/${userId}/set-password`, request);
    setIsChangingPassword(false);
    if (response && !response.errors) {
      setChangePasswordModal({
        isVisible: false,
      });
      setChangePasswordResult(RESPONSE_STATS.SUCCESS);
      setValidationErrors({});
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else {
      setValidationErrors({});
      setChangePasswordResult(RESPONSE_STATS.FAIL);
    }
  };

  const deleteUser = async (id) => {
    setIsDeletingUser(true);
    setDeleteUserResult(RESPONSE_STATS.NOTR);
    const response = await apiDelete("/users/" + id);
    setIsDeletingUser(false);
    if (response) {
      setDeleteUserResult(RESPONSE_STATS.SUCCESS);
    } else {
      setDeleteUserResult(RESPONSE_STATS.FAIL);
    }
  };

  const fetchPageUsers = async ({
    page = 0,
    pageSize = 10,
    asc = false,
  } = {}) => {
    setIsLoadingUsers(true);
    setFetchUserResult(RESPONSE_STATS.NOTR);
    const response = await apiGet(
      `/users?page=${page}&pageSize=${pageSize}&asc=${asc}`
    );
    if (response === false) {
      setFetchUserResult(RESPONSE_STATS.FAIL);
    } else {
      setFetchUserResult(RESPONSE_STATS.SUCCESS);
      setPageUsers(response);
      if (response.totalElements < 11) {
        setPagination(false);
      } else {
        setPagination({
          current: response.page + 1,
          pageSize: response.pageSize,
          total: response.totalElements,
          showSizeChanger: true,
        });
      }
    }
    setIsLoadingUsers(false);
  };

  const fetchUsers = async ({ type, asc = false }) => {
    setIsLoadingUsers(true);
    setFetchUserResult(RESPONSE_STATS.NOTR);
    const response = await apiGet(`/users/filter?type=${type}&asc=${asc}`);
    if (response === false) {
      setFetchUserResult(RESPONSE_STATS.FAIL);
    } else {
      setUsers(response);
      if (response.totalElements < 11) {
        setPagination(false);
      } else {
        setPagination({
          current: response.page + 1,
          pageSize: response.pageSize,
          total: response.totalElements,
          showSizeChanger: true,
        });
      }
      setFetchUserResult(RESPONSE_STATS.SUCCESS);
    }
    setIsLoadingUsers(false);
  };

  useEffect(() => {
    if (saveUserResult === RESPONSE_STATS.SUCCESS) {
      successMessage("saveSuccessMessage");
    } else if (saveUserResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
  }, [saveUserResult]);

  useEffect(() => {
    if (editUserResult === RESPONSE_STATS.SUCCESS) {
      successMessage("editSuccessMessage");
    } else if (editUserResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
  }, [editUserResult]);

  useEffect(() => {
    if (deleteUserResult === RESPONSE_STATS.SUCCESS) {
      successMessage("deleteSuccessMessage");
    } else if (deleteUserResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
  }, [deleteUserResult]);

  useEffect(() => {
    if (changePasswordResult === RESPONSE_STATS.SUCCESS) {
      successMessage("editSuccessMessage");
    } else if (changePasswordResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
  }, [changePasswordResult]);

  return {
    users,
    fetchUsers,
    deleteUser,
    isLoadingUsers,
    isDeletingUser,
    userModal,
    setUserModal,
    filteredUsers,
    userKeyword,
    setUserKeyword,
    saveUser,
    isSavingUser,
    pagination,
    changePassword,
    isChangingPassword,
    changePasswordModal,
    setChangePasswordModal,
    saveUserResult,
    editUserResult,
    deleteUserResult,
    validationErrors,
    setValidationErrors,
  };
}
