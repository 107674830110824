import { useState, useEffect } from 'react';
import { apiGet, apiDelete, apiPost, apiPut } from '../utils/api';
import _ from 'lodash';
import { successMessage, errorMessage, errorResponseMessage } from '../views/elements/ToastMessage';

export const EVENT_TYPES = {
  WEDDING:"WEDDING",
  BIRTH:"BIRTH"
}

export default function useChecklistCategories(props) {
  const [checklistCategories, setChecklistCategories] = useState([]);
  const [hierarchyChecklistCategories, setHierarchyChecklistCategories] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [loadingChecklistCategories, setLoadingChecklistCategories] = useState(false);
  const [checklistCategoryFailed, setChecklistCategoryFailed] = useState(false);
  const [isDeletingChecklistCategory, setIsDeletingChecklistCategory] = useState(false);
  const [eventTypeEnums, setEventTypeEnums] = useState([]);
  const [filteredChecklistCategories, setFilteredChecklistCategories] = useState([]);
  const [checklistCategoryKeyword, setChecklistCategoryKeyword] = useState('');
  const [checklistCategoryModal, setChecklistCategoryModal] = useState({
    isVisible: false,
    type: 'add',
    item: false,
    checklistCategory: {},
  });
  const [nestedData, setNestedData] = useState({});
  const [isLoading, setIsLoading] = useState({});

  const [savingChecklistCategory, setSavingChecklistCategory] = useState(false);

  useEffect(() => {
    if (checklistCategories) {
      setFilteredChecklistCategories(
        checklistCategories.filter((item) => item.name?.toLowerCase().includes(checklistCategoryKeyword.toLowerCase()) || checklistCategoryKeyword.length === 0)
      );
    }
  }, [checklistCategoryKeyword, checklistCategories]);

  const fetchEventTypeEnums = async () => {
    const response = await apiGet('/enums/EventType');
    setEventTypeEnums(response);
  };

  const saveChecklistCategory = async (checklistCategory, type) => {
    setSavingChecklistCategory(true);
    const response =
      type === 'new' ? await apiPost('/checklistcategories', checklistCategory) : await apiPut('/checklistcategories', checklistCategory);
    setSavingChecklistCategory(false);
    if (response && !response.errors && !response.error) {
      setChecklistCategoryModal({
        ...checklistCategoryModal,
        item: type === 'edit' ? response.data : response,
        type: 'edit',
        isVisible: false,
      });
      type === 'new' ? successMessage('saveSuccessMessage') : successMessage('editSuccessMessage');
      fetchChecklistCategories();
      if (checklistCategory.parentId) {
        await fetchInnerChecklistCategories({
          parentId: checklistCategory.parentId,
        });
      }
      setValidationErrors({});
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error)
    } else {
      setValidationErrors({});
      errorMessage('failMessage');
    }
  };

  const deleteChecklistCategory = async (id, parentId) => {
    setIsDeletingChecklistCategory(true);
    const response = await apiDelete('/checklistcategories/' + id);
    setIsDeletingChecklistCategory(false);
    if (response) {
      successMessage('deleteSuccessMessage');
      if (parentId) {
        await fetchInnerChecklistCategories({ parentId: parentId });
      } else {
        fetchChecklistCategories();
      }
    } else {
      errorMessage('failMessage');
    }
  };

  const fetchHierarchyChecklistCategories = async (eventType = EVENT_TYPES.WEDDING, pageSize=1000 ) => {
    const response = await apiGet(`/checklistcategories?eventType=${eventType}&pageSize=${pageSize}`);
    if (response === false) {
      setChecklistCategoryFailed(true);
      return false;
    } else {
      setHierarchyChecklistCategories([...response.content]);
      return response.content;
    }
  };

  const fetchChecklistCategories = async ({ parentId = '', isShowTop = '', isShowHome = '', isActive = '' } = {}) => {
    setLoadingChecklistCategories(true);

    const response = await apiGet(
      `/checklistcategories/filter?parentId=${parentId}&isShowTop=${isShowTop}&isShowHome=${isShowHome}&isActive=${isActive}`
    );
    if (response === false) {
      setChecklistCategoryFailed(true);
    } else {
      if (!_.isEmpty(response)) {
        const sorted = response.sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
        setChecklistCategories(sorted);
      } else {
        setChecklistCategories([]);
      }
    }
    await fetchEventTypeEnums();
    setLoadingChecklistCategories(false);
  };

  const fetchInnerChecklistCategories = async ({ parentId = '', isShowTop = '', isShowHome = '', isActive = '' } = {}) => {
    setIsLoading({
      [parentId]: true,
    });

    const res = await apiGet(
      `/checklistcategories/filter?parentId=${parentId}&isShowTop=${isShowTop}&isShowHome=${isShowHome}&isActive=${isActive}`
    );
    setNestedData((state) => ({
      ...state,
      [parentId]: [...res],
    }));
    setIsLoading({
      [parentId]: false,
    });
  };

  return {
    checklistCategories,
    fetchChecklistCategories,
    deleteChecklistCategory,
    loadingChecklistCategories,
    isDeletingChecklistCategory,
    checklistCategoryFailed,
    checklistCategoryModal,
    setChecklistCategoryModal,
    filteredChecklistCategories,
    checklistCategoryKeyword,
    setChecklistCategoryKeyword,
    saveChecklistCategory,
    savingChecklistCategory,
    fetchInnerChecklistCategories,
    eventTypeEnums,
    nestedData,
    isLoading,
    validationErrors,
    setValidationErrors,
    hierarchyChecklistCategories,
    fetchHierarchyChecklistCategories,
  };
}
