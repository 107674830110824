import React, { useEffect, useState } from 'react';
import { Button, Modal, Row, Col, Input, Table, Divider } from 'antd';
import useAttributes from '../../../hooks/useAttributes';
import GLayout from '../../gLayout';
import styles from './attributes.module.css';
import intl from 'react-intl-universal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import AddEditAttribute from './_add';
import { FormatMessage } from '../../../hocs';
import AttributeValues from './values';
import { ActionsColumn, BasicColumn, BooleanColumn, IdColumn } from '../../../components';

const { confirm } = Modal;
const { Search } = Input;

const Attributes = () => {
  const {
    filteredAttributes,
    fetchAttributes,
    attributeModal,
    saveAttribute,
    setAttributeModal,
    isDeletingAttribute,
    deleteAttribute,
    setAttributeKeyword,
    loadingAttributes,
    attributeKeyword,
    pagination,
    validationErrors,
    setValidationErrors,
  } = useAttributes();

  const [selectedAttribute, setSelectedAttribute] = useState(null);

  useEffect(() => {
    fetchAttributes();
  }, []);

  const handleTableChange = ({ current, pageSize }) => {
    fetchAttributes({ page: current - 1, pageSize: pageSize });
  };

  useEffect(() => {
    if (filteredAttributes && filteredAttributes.length > 0) {
      setSelectedAttribute(filteredAttributes[0]);
    } else {
      setSelectedAttribute(null);
    }
  }, [filteredAttributes]);

  const showDeleteConfirm = (id) => {
    confirm({
      title: <FormatMessage id='attributes.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingAttribute,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteAttribute(id);
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const columns = [
    IdColumn(),
    BasicColumn({ name: 'title' }),
    BooleanColumn(),
    ActionsColumn({
      onClickEdit: setAttributeModal,
      onClickDelete: showDeleteConfirm,
    }),
  ];

  return (
    <GLayout>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setAttributeKeyword(e.target.value)}
              value={attributeKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              onClick={() => setAttributeModal({ isVisible: true, type: 'new', item: {} })}
              disabled={loadingAttributes}
              style={{ float: 'right' }}
            >
              <FormatMessage id='attributes.addModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        id='attributes-table'
        rowKey='id'
        bordered
        loading={loadingAttributes}
        title={() => <FormatMessage id='attributes.title' />}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setSelectedAttribute(record);
            },
          };
        }}
        dataSource={filteredAttributes}
        columns={columns}
        onChange={handleTableChange}
        pagination={pagination}
        //rowClassName={styles.selectedRow}
        rowClassName={(record, index) => {
          if (selectedAttribute?.id === record.id) {
            console.log(styles.scs);
            return styles.selectedRow + ' ' + styles.pointerRow;
          }
          return styles.pointerRow;
        }}
      />
      {selectedAttribute && (
        <>
          <Divider />
          <AttributeValues attribute={selectedAttribute} />
        </>
      )}
      {attributeModal.isVisible && (
        <AddEditAttribute
          onHide={() => {
            setAttributeModal({ ...attributeModal, isVisible: false });
            setValidationErrors({});
          }}
          {...attributeModal}
          saveAttribute={saveAttribute}
          validationErrors={validationErrors}
        />
      )}
    </GLayout>
  );
};

export default Attributes;
