import { useState } from "react";
import { apiGet } from "../utils/api";

export default function useCities(props) {
  const [cities, setCities] = useState([]);
  const [isFetchingCities, setIsFetchingCities] = useState(false);
  const [fetchingCitiesFailed, setFetchingCitiesFailed] = useState(false);

  const fetchCities = async ({ countryId = 1}) => {
    setIsFetchingCities(true);
    const response = await apiGet(`/cities/filter/${countryId}`);
    if (response === false) {
      setFetchingCitiesFailed(true);
    } else {
      setCities(response);
    }
    setIsFetchingCities(false);
  };

  return {
    cities,
    fetchCities,
    isFetchingCities,
    fetchingCitiesFailed,
  };
}
