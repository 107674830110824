import {
  OPEN_ADD_EDIT_INTEREST_MODAL,
  CLOSE_ADD_EDIT_INTEREST_MODAL,
  FETCH_INTERESTS_BEGIN,
  FETCH_INTERESTS_SUCCESS,
  FETCH_INTERESTS_FAILURE,
  SAVE_INTEREST_BEGIN,
  SAVE_INTEREST_SUCCESS,
  SAVE_INTEREST_FAILURE,
  DELETE_INTEREST_BEGIN,
  DELETE_INTEREST_SUCCESS,
  DELETE_INTEREST_FAILURE,
} from "../actions/types";

import { message } from "antd";

const INITIAL_STATE = {
  interests: null,
  searchText: "",
  isLoading: false,
  error: null,
  modalProps: {
    type: "new",
    isVisible: false,
    item: {},
    isLoading: false,
    error: null,
  },
  deleteState: {
    isDeleting: false,
    error: null,
  },
};

const interestReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_ADD_EDIT_INTEREST_MODAL: {
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          ...action.payload,
        },
      };
    }
    case CLOSE_ADD_EDIT_INTEREST_MODAL: {
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          ...action.payload,
        },
      };
    }
    case FETCH_INTERESTS_BEGIN: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case FETCH_INTERESTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.interests,
      };
    }
    case FETCH_INTERESTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.error,
      };
    }
    case SAVE_INTEREST_BEGIN: {
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          isLoading: true,
        },
      };
    }
    case SAVE_INTEREST_SUCCESS: {
      message.success(`Kayit islemi basarili`);
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          isLoading: false
        },
      };
    }
    case SAVE_INTEREST_FAILURE: {
      message.error(`Kayit islemi basarisiz`);
      return {
        ...state,
        modalProps: {
          ...state.modalProps,
          isLoading: false,
          error: action.payload.error
        },
      };
    }
    case DELETE_INTEREST_BEGIN: {
      return {
        ...state,
        deleteState: {
          isDeleting: true,
          error: null,
        },
      };
    }
    case DELETE_INTEREST_SUCCESS: {
      message.success(`Silme islemi basarili`);
      return {
        ...state,
        modalProps: {
          isDeleting: false,
          error: null,
        },
      };
    }
    case DELETE_INTEREST_FAILURE: {
      message.error(`Silme islemi basarisiz`);
      return {
        ...state,
        modalProps: {
          isDeleting: true,
          error: action.payload.error,
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default interestReducer;