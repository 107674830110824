import React, { useEffect, useState } from 'react';
import { Form, Select, Modal } from 'antd';
import { SwitchItem, TextItem, SelectItem } from '../../../components';
import { FormatMessage } from '../../../hocs';
import { errorMessage } from '../../../views/elements/ToastMessage';
import SingleImageItem from '../../../components/formItems/SingleImageItem';
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddEditBadge = ({ isVisible, type, item, onHide, saveBadge, typeEnums, validationErrors }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isVisible && type === 'edit') {
      form.setFieldsValue({ ...item });
    } else form.resetFields();
  }, [item, isVisible, type, form]);

  const handleOk = () => {
    if (type === 'new') {
      form
        .validateFields()
        .then(async (values) => {
          setIsLoading(true);
          await saveBadge(values, 'new');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    } else if (type === 'edit') {
      form
        .validateFields()
        .then(async (values) => {
          const data = {
            ...values,
            id: item.id,
          };
          setIsLoading(true);
          await saveBadge(data, 'edit');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      //errorMessage('You can only upload JPG/PNG file!');
      errorMessage('filePngJpg');
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
      //errorMessage('You can only upload JPG/PNG file!');
      errorMessage('fileSizeLarger1MB');
    }
    return isJpgOrPng && isLt1M;
  }

  return (
    <Modal
      title={type === 'edit' ? <FormatMessage id='badges.editModalTitle' /> : <FormatMessage id='badges.addModalTitle' />}
      visible={isVisible}
      confirmLoading={isLoading}
      onOk={() => handleOk()}
      okText={<FormatMessage id='save' />}
      onCancel={onHide}
      cancelText={<FormatMessage id='cancel' />}
    >
      <Form form={form} name='control-ref' layout='horizontal'>
        <TextItem name='name' label='name' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <SelectItem name='type' label='type' validationErrors={validationErrors} layout={{ ...formItemLayout }}>
          {typeEnums && typeEnums.map((e) => <Option value={e.key}>{e.value}</Option>)}
        </SelectItem>
        <SwitchItem name='active' label='active' value={item.active} layout={{ ...formItemLayout }} />
        <SingleImageItem
          name='imageId'
          label='image'
          layout={{ ...formItemLayout }}
          validationErrors={validationErrors}
          //beforeUpload={beforeUpload}
          uri={item?.imageUri}
          dir='badges'
        />
      </Form>
    </Modal>
  );
};

export default AddEditBadge;
