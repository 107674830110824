import React from 'react';
import { Form } from 'antd';
import { FormatMessage } from '../../hocs';
import PropTypes from 'prop-types';

const SpanItem = ({ value = '', label = '', layout = null }) => {
  return (
    <Form.Item label={<FormatMessage id={label} />} {...layout}>
      <span className='ant-form-text'>{value}</span>
    </Form.Item>
  );
};

SpanItem.propTypes = {
  label: PropTypes.string.isRequired,
  layout: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
};

export default SpanItem;
