import { useState, useEffect } from 'react';
import { apiGet, apiDelete, apiPost, apiPut } from '../utils/api';
import { RESPONSE_STATS } from '../utils/constants';
import { successMessage, errorMessage, errorResponseMessage } from '../views/elements/ToastMessage';

export default function useCmsContents(props) {
  const [cmsContents, setCmsContents] = useState([]);
  const [nestedData, setNestedData] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [loadingCmsContents, setLoadingCmsContents] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [cmsContentFailed, setCmsContentFailed] = useState(false);
  const [isDeletingCmsContent, setIsDeletingCmsContent] = useState(false);
  const [filteredCmsContents, setfilteredCmsContents] = useState([]);
  const [cmsContentKeyword, setCmsContentKeyword] = useState('');
  const [cmsContentModal, setCmsContentModal] = useState({
    isVisible: false,
    type: 'add',
    item: false,
  });

  const [savingCmsContent, setSavingCmsContent] = useState(false);
  const [saveCmsContentResult, setSaveCmsContentResult] = useState(RESPONSE_STATS.NOTR);
  const [editCmsContentResult, setEditCmsContentResult] = useState(RESPONSE_STATS.NOTR);
  const [deleteCmsContentResult, setDeleteCmsContentResult] = useState(RESPONSE_STATS.NOTR);

  useEffect(() => {
    if (cmsContents) {
      setfilteredCmsContents(cmsContents.filter((item) => item.title?.toLowerCase().includes(cmsContentKeyword.toLowerCase()) || cmsContentKeyword.length === 0));
    }
  }, [cmsContentKeyword, cmsContents]);

  const saveCmsContent = async (cmsContent, type) => {
    setSavingCmsContent(true);
    const response = type === 'new' ? await apiPost('/cmscontents', cmsContent) : await apiPut('/cmscontents', cmsContent);
    setSavingCmsContent(false);
    if (response && !response.errors && !response.error) {
      setCmsContentModal({
        ...cmsContentModal,
        item: type === 'edit' ? response.data : response,
        type: 'edit',
        isVisible: false,
      });
      type === 'new' ? setSaveCmsContentResult(RESPONSE_STATS.SUCCESS) : setEditCmsContentResult(RESPONSE_STATS.SUCCESS);
      setValidationErrors({});
      await fetchCmsContents({ cmsPageId: cmsContent.cmsPageId });
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
      await fetchCmsContents({ cmsPageId: cmsContent.cmsPageId });
    } else if(response.error) {
      errorResponseMessage(response.error)
    } else {
      setValidationErrors({});
      type === 'new' ? setSaveCmsContentResult(RESPONSE_STATS.FAIL) : setEditCmsContentResult(RESPONSE_STATS.FAIL);
    }
  };

  const deleteCmsContent = async ({ id, cmsPageId }) => {
    setIsDeletingCmsContent(true);
    const response = await apiDelete('/cmscontents/' + id);
    setIsDeletingCmsContent(false);
    if (response) {
      setDeleteCmsContentResult(RESPONSE_STATS.SUCCESS);
      await fetchCmsContents({ cmsPageId: cmsPageId });
    } else {
      setDeleteCmsContentResult(RESPONSE_STATS.FAIL);
    }
  };

  const fetchCmsContents = async ({ cmsPageId = '', isActive = '' } = {}) => {
    setLoadingCmsContents(true);

    const response = await apiGet(`/cmscontents/filter?cmsPageId=${cmsPageId}&isActive=${isActive}`);
    if (response === false) {
      setCmsContentFailed(true);
    } else {
      setCmsContents(response);
    }
    setLoadingCmsContents(false);
  };

  const fetchInnerCmsContents = async ({ parentId = '', isActive = '' } = {}) => {
    setIsLoading({
      [parentId]: true,
    });

    const res = await apiGet(`/cmscontents/filter?parentId=${parentId}&isActive=${isActive}`);
    setNestedData((state) => ({
      ...state,
      [parentId]: [...res],
    }));
    setIsLoading({
      [parentId]: false,
    });
  };

  useEffect(() => {
    if (saveCmsContentResult === RESPONSE_STATS.SUCCESS) {
      successMessage('saveSuccessMessage');
    } else if (saveCmsContentResult === RESPONSE_STATS.FAIL) {
      errorMessage('failMessage');
    }
    setSaveCmsContentResult(RESPONSE_STATS.NOTR);
  }, [saveCmsContentResult]);

  useEffect(() => {
    if (editCmsContentResult === RESPONSE_STATS.SUCCESS) {
      successMessage('editSuccessMessage');
    } else if (editCmsContentResult === RESPONSE_STATS.FAIL) {
      errorMessage('failMessage');
    }
    setEditCmsContentResult(RESPONSE_STATS.NOTR);
  }, [editCmsContentResult]);

  useEffect(() => {
    if (deleteCmsContentResult === RESPONSE_STATS.SUCCESS) {
      successMessage('deleteSuccessMessage');
    } else if (deleteCmsContentResult === RESPONSE_STATS.FAIL) {
      errorMessage('failMessage');
    }
    setDeleteCmsContentResult(RESPONSE_STATS.NOTR);
  }, [deleteCmsContentResult]);

  return {
    cmsContents,
    fetchCmsContents,
    deleteCmsContent,
    loadingCmsContents,
    isDeletingCmsContent,
    cmsContentFailed,
    cmsContentModal,
    setCmsContentModal,
    filteredCmsContents,
    cmsContentKeyword,
    setCmsContentKeyword,
    saveCmsContent,
    savingCmsContent,
    saveCmsContentResult,
    validationErrors,
    setValidationErrors,
    fetchInnerCmsContents,
    nestedData,
    isLoading,
  };
}
