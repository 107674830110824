import { useEffect, useState } from "react"

export default function useSlug(){
    
    function convertToSlug(Text) {
        
        return Text.toLowerCase()
        .replace(/ /g, "-")
        .replace(/ı/g,"i")
        .replace(/İ/g,"i")
        .replace(/ö/g,"o")
        .replace(/ş/g,"s")
        .replace(/ç/g,"c")
        .replace(/ğ/g,"g")
        .replace(/ü/g,"u")
        .replace(/[^\w-]+/g, "");
    }


    return {
        convertToSlug
    }
}