import { useState, useEffect } from 'react';
import { apiGet, apiDelete, apiPost, apiPut } from '../utils/api';
import { RESPONSE_STATS } from '../utils/constants';
import { successMessage, errorMessage, errorResponseMessage } from '../views/elements/ToastMessage';

export default function useAttributes() {
  const [attributes, setAttributes] = useState([]);
  const [loadingAttributes, setLoadingAttributes] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [attributeFailed, setAttributeFailed] = useState(false);
  const [isDeletingAttribute, setIsDeletingAttribute] = useState(false);
  const [filteredAttributes, setFilteredAttributes] = useState([]);
  const [attributeKeyword, setAttributeKeyword] = useState('');
  const [pagination, setPagination] = useState({});
  const [attributeModal, setAttributeModal] = useState({
    isVisible: false,
    type: 'add',
    item: false,
  });

  const [savingAttribute, setSavingAttribute] = useState(false);
  const [saveAttributeResult, setSaveAttributeResult] = useState(RESPONSE_STATS.NOTR);
  const [editAttributeResult, setEditAttributeResult] = useState(RESPONSE_STATS.NOTR);
  const [deleteAttributeResult, setDeleteAttributeResult] = useState(RESPONSE_STATS.NOTR);

  useEffect(() => {
    if (attributes && attributes.content) {
      setFilteredAttributes(attributes.content?.filter((item) => item.title.toLowerCase().includes(attributeKeyword.toLowerCase()) || attributeKeyword.length === 0));
    }
  }, [attributeKeyword, attributes]);

  const saveAttribute = async (attribute, type) => {
    setSavingAttribute(true);
    const response = type === 'new' ? await apiPost('/attributes', attribute) : await apiPut('/attributes', attribute);
    setSavingAttribute(false);
    if (response && !response.errors && !response.error) {
      setAttributeModal({
        ...attributeModal,
        item: type === 'edit' ? response.data : response,
        type: 'edit',
        isVisible: false,
      });
      type === 'new' ? setSaveAttributeResult(RESPONSE_STATS.SUCCESS) : setEditAttributeResult(RESPONSE_STATS.SUCCESS);
      setValidationErrors({});
      fetchAttributes();
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error)
    } else {
      setValidationErrors({});
      type === 'new' ? setSaveAttributeResult(RESPONSE_STATS.FAIL) : setEditAttributeResult(RESPONSE_STATS.FAIL);
    }
  };

  const deleteAttribute = async (id) => {
    setIsDeletingAttribute(true);
    const response = await apiDelete('/attributes/' + id);
    setIsDeletingAttribute(false);
    if (response) {
      setDeleteAttributeResult(RESPONSE_STATS.SUCCESS);
      fetchAttributes();
    } else {
      setDeleteAttributeResult(RESPONSE_STATS.FAIL);
    }
  };

  const fetchAttributes = async ({ page = 0, pageSize = 10, asc = false } = {}) => {
    setLoadingAttributes(true);

    const response = await apiGet(`/attributes?page=${page}&pageSize=${pageSize}&asc=${asc}`);
    if (response === false) {
      setAttributeFailed(true);
    } else {
      setAttributes(response);
      if (response.totalElements < 11) {
        setPagination(false);
      } else {
        setPagination({
          current: response.page + 1,
          pageSize: response.pageSize,
          total: response.totalElements,
          showSizeChanger: true,
        });
      }
    }
    setLoadingAttributes(false);
  };

  useEffect(() => {
    if (saveAttributeResult === RESPONSE_STATS.SUCCESS) {
      successMessage('saveSuccessMessage');
    } else if (saveAttributeResult === RESPONSE_STATS.FAIL) {
      errorMessage('failMessage');
    }
    setSaveAttributeResult(RESPONSE_STATS.NOTR);
  }, [saveAttributeResult]);

  useEffect(() => {
    if (editAttributeResult === RESPONSE_STATS.SUCCESS) {
      successMessage('editSuccessMessage');
    } else if (editAttributeResult === RESPONSE_STATS.FAIL) {
      errorMessage('failMessage');
    }
    setEditAttributeResult(RESPONSE_STATS.NOTR);
  }, [editAttributeResult]);

  useEffect(() => {
    if (deleteAttributeResult === RESPONSE_STATS.SUCCESS) {
      successMessage('deleteSuccessMessage');
    } else if (deleteAttributeResult === RESPONSE_STATS.FAIL) {
      errorMessage('failMessage');
    }
    setDeleteAttributeResult(RESPONSE_STATS.NOTR);
  }, [deleteAttributeResult]);

  return {
    attributes,
    fetchAttributes,
    deleteAttribute,
    loadingAttributes,
    isDeletingAttribute,
    attributeFailed,
    attributeModal,
    setAttributeModal,
    filteredAttributes,
    attributeKeyword,
    setAttributeKeyword,
    saveAttribute,
    savingAttribute,
    saveAttributeResult,
    pagination,
    validationErrors,
    setValidationErrors,
  };
}
