import { useState } from "react";
import { apiGet } from "../utils/api";

export default function useDistricts(props) {
  const [districts, setDistricts] = useState([]);
  const [isFetchingDistricts, setIsFetchingDistricts] = useState(false);
  const [fetchingDistrictsFailed, setFetchingDistrictsFailed] = useState(false);

  const fetchDistricts = async ({ countyId }) => {
    setIsFetchingDistricts(true);
    const response = await apiGet(`/districts/filter/${countyId}`);
    if (response === false) {
      setFetchingDistrictsFailed(true);
    } else {
      setDistricts(response);
    }
    setIsFetchingDistricts(false);
  };

  return {
    districts,
    fetchDistricts,
    isFetchingDistricts,
    fetchingDistrictsFailed,
    setDistricts
  };
}
