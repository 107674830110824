import React, { useEffect, useState } from 'react';
import { Form, Select, Modal, TreeSelect } from 'antd';
import intl from 'react-intl-universal';
import { FormatMessage } from '../../../hocs';
import useCategories from '../../../hooks/useCategories';
import { SwitchItem, SpanItem, NumberItem, TextItem, SelectItem } from '../../../components';
import SingleImageItem from '../../../components/formItems/SingleImageItem';

const { Option } = Select;
const { SHOW_PARENT } = TreeSelect
const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 14,
  },
};

export const preparedTreeData = (cats) => {
  const dummy = [];
  cats.forEach((d) => {
    const o = {
      title: d.name,
      value: d.id,
      key: `${d.id}`,
      alreadyMapped: d.alreadyMapped,
      children: d.childs && d.childs.length > 0 ? preparedTreeData(d.childs) : undefined,
    };
    dummy.push(o);
  });
  return dummy;
};

const AddEditChecklistCategory = ({
  isVisible,
  type,
  item,
  onHide,
  saveChecklistCategory,
  checklistCategory,
  eventTypeEnums,
  validationErrors,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchHierarchyMappedCategories, hierarchyMappedCategories } = useCategories();

  useEffect(() => {
    fetchHierarchyMappedCategories();
  }, []);

  const tProps = {
    treeCheckable: true,
    showCheckedStrategy: SHOW_PARENT,
    style: {
      width: '100%',
    },
  };

  useEffect(() => {
    if (isVisible && type === 'edit') {
      form.setFieldsValue({
        ...item,
        categories: item.categories.map((c) => c.id),
      });
    } else form.resetFields();
  }, [item, isVisible, type, form]);

  const handleOk = () => {
    if (type === 'new') {
      form
        .validateFields()
        .then(async (values) => {
          setIsLoading(true);
          /* const cats = [];
          if (values.categories && values.categories.length > 0) {
            values.categories.forEach((v) => cats.push({ id: v }));
          } */
          const req = checklistCategory
            ? {
                parentId: checklistCategory.id,
                ...values,
              }
            : { ...values };
          console.log(req);
          await saveChecklistCategory(req, 'new');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    } else if (type === 'edit') {
      form
        .validateFields()
        .then(async (values) => {
          /* const cats = [];
          console.log(values);
          if (values.categories && values.categories.length > 0) {
            values.categories.forEach((v) => cats.push({ id: v }));
          } */
          const data = {
            ...values,
            id: item.id,
            parentId: item.parentId,
          };
          console.log(data);
          setIsLoading(true);
          await saveChecklistCategory(data, 'edit');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }
  };

  return (
    <Modal
      title={
        type === 'edit' ? (
          <FormatMessage id='checklistCategories.editModalTitle' />
        ) : (
          <FormatMessage id='checklistCategories.addModalTitle' />
        )
      }
      visible={isVisible}
      confirmLoading={isLoading}
      onOk={() => handleOk()}
      okText={<FormatMessage id='save' />}
      onCancel={onHide}
      cancelText={<FormatMessage id='cancel' />}
    >
      <Form form={form} name='control-ref' layout='horizontal'>
        {checklistCategory && (
          <SpanItem value={checklistCategory.name} label='checklistCategories.parentCategory' layout={{ ...formItemLayout }} />
        )}
        <TextItem name='name' label='name' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <NumberItem name='displayOrder' label='displayOrder' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <SelectItem name='eventType' label='eventType' validationErrors={validationErrors} layout={{ ...formItemLayout }}>
          {eventTypeEnums && eventTypeEnums.map((e) => <Option value={e.key}>{e.value}</Option>)}
        </SelectItem>
        <SwitchItem name='active' label='active' validationErrors={validationErrors} value={item.active} layout={{ ...formItemLayout }} />
        <SingleImageItem
          name='imageId'
          label='image'
          layout={{ ...formItemLayout }}
          validationErrors={validationErrors}
          uri={item?.imageUri}
          dir='checklist-categories'
        />

        <Form.Item
          name='categories'
          label={<FormatMessage id='checklistCategories.category' />}
          validateStatus={validationErrors?.categories && 'error'}
          help={validationErrors?.categories}
          {...formItemLayout}
        >
          <TreeSelect
            {...tProps}
            treeData={preparedTreeData(hierarchyMappedCategories)}
            placeholder={intl.formatMessage({
              id: 'checklistCategories.addCategoryPlaceHolder',
            })}
            autoClearSearchValue={true}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddEditChecklistCategory;
