import React, { useEffect } from 'react';
import { BasicColumn, IdColumn, ImageColumn } from '../../../components';
import useCommissions, { COMMISSIONS_MODAL_STATES, COMMISSIONS_MODAL_VALUE_SHOW_TYPE, COMMISSION_RECORD_TYPE, LOADING_STATES } from '../../../hooks/useCommissions';
import intl from 'react-intl-universal';
import GLayout from '../../gLayout';
import { Table, Row, Col, Select, Space, Button, Modal, Tooltip, Form } from 'antd';
import styles from './commissions.module.css'
import { FormatMessage } from '../../../hocs';
import AddEditCommissions from './_add';
import useDealers from '../../../hooks/useDealers';
import useBrands from '../../../hooks/useBrands';
import { EditOutlined, PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { SelectItem } from '../../../components';
import useProducts from '../../../hooks/useProducts';

const { Option } = Select;
const { confirm } = Modal;

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 14,
    },
};


const Commissions = () => {

    const [form] = Form.useForm();

    const {
        fetchLoading,
        brandLoading,
        commissions,
        fetchDealerBrands,
        brandData,
        fetchBrandProducts,
        brandProductsData,
        brandProductsLoading,
        commissionModalProps,
        onCommissionModalOpen,
        fetchCommissions,
        addCommissions,
        updateCommissions,
        deleteCommissions,
        getProductComission
    } = useCommissions();

    const {
        fetchDealers,
        filteredDealers,
        getDealerBrandsOrProducts
    } = useDealers();

    const {
        brandsPage,
        fetchBrandsPage,
    } = useBrands();

    const {
        fetchProducts,
        products
    } = useProducts();

    useEffect(() => {
        fetchDealers({ pageSize: 99999 })
        fetchBrandsPage({ pageSize: 99999 });
        fetchProducts({ pageSize: 99999 });
    }, []);

    const showDeleteConfirm = (record, level) => {
        confirm({
            title: "Bu kaydı silmek istiyor musunuz?",
            icon: <ExclamationCircleOutlined />,
            okText: <FormatMessage id='okText' />,
            okType: 'danger',
            cancelText: <FormatMessage id='cancelText' />,
            async onOk() {
                return (async (resolve, reject) => {
                    await deleteCommissions(record.id);
                    if (level === 'DEALER') {
                        await fetchCommissions({});
                    } else if (level === 'BRAND') {
                        await handleExpandBrand(true, record)
                    } else if (level === 'PRODUCT') {
                        await handleExpandProduct(true, record)
                    }
                    resolve();
                })().catch(() => console.log('Oops errors!'));
            },
            onCancel() {
                console.log('Close');
            },
        });
    };

    /**
     * Tablo sütun isimleri
     * 
     * @param {{level: 'DEALER' | 'BRAND' | 'PRODUCT'}} columnDTO 
     */
    const columns = ({ level = 'DEALER' }) => {
        const columnMap = {
            DEALER: [
                IdColumn(),
                BasicColumn({ name: 'dealerName' }),
                BasicColumn({ name: 'ratio' }),
                {
                    title: <FormatMessage id='actions' />,
                    key: 'action',
                    width: 180,
                    render: (text, record) => (
                        <Space size='middle'>
                            <Tooltip title={<FormatMessage id='edit' />}>
                                <Button icon={<EditOutlined />} onClick={() => { onEditButtonClick(record, COMMISSION_RECORD_TYPE.DEALER) }} />
                            </Tooltip>

                            <Tooltip title={<FormatMessage id='delete' />}>
                                <Button danger onClick={async () => showDeleteConfirm(record, 'DEALER')} icon={<DeleteOutlined />} />
                            </Tooltip>

                            <Tooltip title={<FormatMessage id='commissions.addButtonTitle' />}>
                                <Button type='success' ghost onClick={() => { onAddButtonClick(record, COMMISSION_RECORD_TYPE.BRAND) }} icon={<PlusOutlined />} />
                            </Tooltip>
                        </Space>
                    ),
                },
            ],
            BRAND: [
                IdColumn(),
                BasicColumn({ name: 'brandTitle' }),
                ImageColumn({ name: 'brandImageUri', label: 'brandImageUri' }),
                BasicColumn({ name: 'ratio' }),
                // BooleanColumn(),
                {
                    title: <FormatMessage id='actions' />,
                    key: 'action',
                    width: 180,
                    render: (text, record) => (
                        <Space size='middle'>
                            <Tooltip title={<FormatMessage id='edit' />}>
                                <Button icon={<EditOutlined />} onClick={() => { onEditButtonClick(record, COMMISSION_RECORD_TYPE.BRAND) }} />
                            </Tooltip>

                            <Tooltip title={<FormatMessage id='delete' />}>
                                <Button danger onClick={() => showDeleteConfirm(record, 'BRAND')} icon={<DeleteOutlined />} />
                            </Tooltip>

                            <Tooltip title={<FormatMessage id='commissions.addModalTitle' />}>
                                <Button type='success' ghost onClick={() => { onAddButtonClick(record, COMMISSION_RECORD_TYPE.PRODUCT) }} icon={<PlusOutlined />} />
                            </Tooltip>
                        </Space>
                    ),
                },
            ],
            PRODUCT: [
                IdColumn(),
                BasicColumn({ name: 'productTitle' }),
                ImageColumn({ name: 'productImageUri', label: 'productImageUri' }),
                BasicColumn({ name: 'ratio' }),
                BasicColumn({ name: 'listPriceIncVat' }),
                BasicColumn({ name: 'sellingPriceIncVat' }),
                // BooleanColumn(),
                {
                    title: <FormatMessage id='actions' />,
                    key: 'action',
                    width: 180,
                    render: (text, record) => (
                        <Space size='middle'>
                            <Tooltip title={<FormatMessage id='edit' />}>
                                <Button icon={<EditOutlined />} onClick={() => { onEditButtonClick(record, COMMISSION_RECORD_TYPE.PRODUCT) }} />
                            </Tooltip>

                            <Tooltip title={<FormatMessage id='delete' />}>
                                <Button danger onClick={() => showDeleteConfirm(record, 'PRODUCT')} icon={<DeleteOutlined />} />
                            </Tooltip>
                        </Space>
                    ),
                },
            ],
        }

        return columnMap[level];
    }

    /**
     * Brand için row'a tıklandığında bu fonksiyon çalışır.
     * 
     * ! Dealer'a tıklandığında.
     * 
     * @param {boolean} expanded 
     * @param {object} record 
     */
    const handleExpandBrand = async (expanded, record) => {
        if (expanded) {
            await fetchDealerBrands({ dealerId: record.dealerId });
        }
    }

    /**
     * Product için row açıldığında bu fonksiyon çalışır. 
     * 
     * ! Brand'e tıklandığında.
     * 
     * @param {boolean} expanded 
     * @param {object} record 
     */
    const handleExpandProduct = async (expanded, record) => {
        if (expanded) {
            await fetchBrandProducts({ dealerId: record.dealerId, brandId: record.brandId });
        }
    }

    /**
     * Row expand edildiğinde render olan table
     * 
     * @param {object} record 
     * @returns {ReactNode}
     */
    const expandedRowRender = (record, index, indent, expanded) => {

        return (
            <Table
                bordered
                rowKey={(record) => record.id}
                showHeader={true}
                loading={brandLoading}
                columns={columns({ level: 'BRAND' })}
                pagination={false}
                dataSource={brandData[record.dealerId]}
                rowClassName={(record, index) => {
                    return styles.rowGrey;
                }}
                expandable={{ expandedRowRender: productExpandRowRender }}
                onExpand={handleExpandProduct}
            />
        );
    }

    /**
     * Row expand edildiğinde render olan table
     * 
     * @param {object} record 
     * @returns {ReactNode}
     */
    const productExpandRowRender = (record) => {
        return (
            <Table
                bordered
                pagination={false}
                showHeader={true}
                rowKey={(record) => record.id}
                loading={brandProductsLoading}
                columns={columns({ level: 'PRODUCT' })}
                dataSource={brandProductsData[record.brandId]}
            />
        );
    }

    /**
     * Add butonuna tıklandığında bu fonksiyon çalışır.
     * 
     * @param {object} item 
     * @param {COMMISSION_RECORD_TYPE} recordType 
     */
    const onAddButtonClick = async (item, recordType,) => {
        await onAddCommissionButton(recordType, item);
    }

    /**
     * Modal için dataları oluşturur.
     * 
     * @param {COMMISSION_RECORD_TYPE} recordType 
     * @param {object} item 
     * @returns 
     */
    const prepareModalData = async (recordType, item) => {

        /**
         * Tüm dealer, brand ve product bilgileri alınır.
         */
        const selectDataMap = {
            [COMMISSION_RECORD_TYPE.DEALER]: async () => filteredDealers,
            [COMMISSION_RECORD_TYPE.BRAND]: async () => await getDealerBrandsOrProducts({ dealerId: item.dealerId }),
            [COMMISSION_RECORD_TYPE.PRODUCT]: async () => await getDealerBrandsOrProducts({ dealerId: item.dealerId, brandId: item.brandId }),
        }

        /**
         * Hangi alanlar zaten var.
         */
        const checkDataMap = {
            [COMMISSION_RECORD_TYPE.DEALER]: {
                id: "dealerId",
                fetch: async () => await fetchCommissions({ pageSize: 99999 }),
            },
            [COMMISSION_RECORD_TYPE.BRAND]: {
                id: "brandId",
                fetch: async () => await fetchDealerBrands({ dealerId: item.dealerId }),
            },
            [COMMISSION_RECORD_TYPE.PRODUCT]: {
                id: "productId",
                fetch: async () => await fetchBrandProducts({ dealerId: item.dealerId, brandId: item.brandId }),
            }
        }

        const selectData = await selectDataMap[recordType]();
        let checkData = await checkDataMap[recordType].fetch();

        if (recordType === COMMISSION_RECORD_TYPE.DEALER) {
            checkData = checkData;
        }

        const checkAlreadyHasCommissionRatio = []

        selectData.forEach(data => {
            const isExist = checkData.find(checkedData => {
                return checkedData[checkDataMap[recordType].id] === data.id;
            });

            data.disabled = isExist;

            checkAlreadyHasCommissionRatio.push(data);
            return data;
        })

        return {
            checkAlreadyHasCommissionRatio
        }

    }

    /**
     * Ekleme butonuna basıldığında çalışan fonksiyon.
     * 
     * @param {COMMISSION_RECORD_TYPE} recordType 
     * @param {object} item 
     */
    const onAddCommissionButton = async (recordType = COMMISSION_RECORD_TYPE.DEALER, item = false) => {

        const { checkAlreadyHasCommissionRatio } = await prepareModalData(recordType, item);

        onCommissionModalOpen({
            isVisible: true,
            itemType: recordType,
            selectData: checkAlreadyHasCommissionRatio,
            item
        });
    }

    /**
     * Edit button'a tıklandığında bu fonksiyon çalışır.
     * 
     * @param {object} item 
     * @param {COMMISSION_RECORD_TYPE} recordType 
     */
    const onEditButtonClick = async (item, recordType) => {

        const { checkAlreadyHasCommissionRatio } = await prepareModalData(recordType, item);

        onCommissionModalOpen({
            isVisible: true,
            itemType: recordType,
            selectData: checkAlreadyHasCommissionRatio,
            item,
            type: COMMISSIONS_MODAL_STATES.EDIT
        });
    }

    const onSearchChangeModalOpen = async (item) => {
        const product = JSON.parse(item);
        const response = await getProductComission(product.id);

        onCommissionModalOpen({
            isVisible: true,
            itemType: COMMISSION_RECORD_TYPE.PRODUCT,
            selectData: [],
            item: {...response, title: product.title},
            type: COMMISSIONS_MODAL_STATES.EDIT,
            showType: COMMISSIONS_MODAL_VALUE_SHOW_TYPE.READ_ONLY
        })
    }

    return (
        <GLayout>
            <div style={{ paddingBottom: 24 }}>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form form={form} name='product_search_form' layout='horizontal'>
                            <SelectItem
                                placeholder='Ürün Ara...'
                                onChange={ async (item)=> await onSearchChangeModalOpen(item)}
                                name="product"
                                validationErrors={{}}
                                layout={{ ...formItemLayout }}>

                                {products?.content?.length > 0 && products?.content.map((item, index) => {
                                    return (
                                        <Option key={index} value={JSON.stringify(item)}>
                                            {item.title}
                                        </Option>
                                    )
                                })}
                            </SelectItem>
                        </Form>
                    </Col>
                    <Col span={16}>
                        <Button
                            key='1'
                            type='primary'
                            disabled={fetchLoading}
                            onClick={() => onAddCommissionButton()}
                            style={{ float: 'right' }}
                        >
                            <FormatMessage id='commissions.addButtonTitle' />
                        </Button>
                    </Col>
                </Row>
            </div>
            <div style={{ paddingBottom: 24 }}>
                <Table
                    bordered
                    rowKey={(record) => record.id}
                    columns={columns({})}
                    dataSource={commissions ?? []}
                    loading={fetchLoading}
                    scroll={{ x: 1500 }}
                    expandable={{ expandedRowRender }}
                    onExpand={handleExpandBrand}
                    pagination={commissions?.length}
                    sticky
                />
            </div>

            <AddEditCommissions
                {...commissionModalProps}
                addCommissions={addCommissions}
                updateCommissions={updateCommissions}
                fetchCommissions={fetchCommissions}
                onHide={() => { onCommissionModalOpen({ isVisible: false }) }}
                onOk={() => { onCommissionModalOpen({ isVisible: false }) }}
                callbacks={{ handleExpandBrand, handleExpandProduct }}
            />
        </GLayout>
    )
}

export default Commissions;