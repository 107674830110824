import React from 'react';
import { Form, Select } from 'antd';
import { FormatMessage } from '../../hocs';
import PropTypes from 'prop-types';

const TagsItem = ({
  name = '',
  label = '',
  layout = null,
  isRequired = false,
  isLoading = false,
  placeholder = '',
  children,
  validationErrors = null,
}) => {
  return (
    <Form.Item
      name={name}
      {...layout}
      label={<FormatMessage id={label} />}
      validateStatus={validationErrors?.[name] && 'error'}
      help={validationErrors?.[name]}
      rules={[{ required: isRequired }]}
    >
      <Select
        mode='tags'
        disabled={isLoading}
        loading={isLoading}
        placeholder={placeholder}
        optionFilterProp='children'
        filterOption={(input, option) => option.children.toLocaleLowerCase('tr-TR').indexOf(input.toLocaleLowerCase('tr-TR')) >= 0}
      >
        {children}
      </Select>
    </Form.Item>
  );
};

TagsItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  layout: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  isLoading: PropTypes.bool,
  placeholder: PropTypes.string,
  validationErrors: PropTypes.object
};

export default TagsItem;
