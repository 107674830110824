import { useState, useEffect } from 'react';
import { apiGet, apiDelete, apiPost, apiPut } from '../utils/api';
import { RESPONSE_STATS } from '../utils/constants';
import { successMessage, errorMessage, errorResponseMessage } from '../views/elements/ToastMessage';
export default function useBrands(props) {
  const [brandsPage, setBrandsPage] = useState([]);
  const [brandsFilter, setBrandsFilter] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [brandsDropdown, setBrandsDropdown] = useState([]);
  const [pagination, setPagination] = useState({});

  const [isLoadingBrands, setIsLoadingBrands] = useState(false);
  const [isDeletingBrand, setIsDeletingBrand] = useState(false);
  const [isSavingBrand, setIsSavingBrand] = useState(false);

  const [filteredBrands, setFilteredBrands] = useState([]);
  const [brandKeyword, setBrandKeyword] = useState('');

  const [eventTypeEnums, setEventTypeEnums] = useState([]);

  const [brandModal, setBrandModal] = useState({
    isVisible: false,
    type: 'add',
    item: false,
  });

  const [, setFetchBrandResult] = useState(RESPONSE_STATS.NOTR);
  const [saveBrandResult, setSaveBrandResult] = useState(RESPONSE_STATS.NOTR);
  const [editBrandResult, setEditBrandResult] = useState(RESPONSE_STATS.NOTR);
  const [deleteBrandResult, setDeleteBrandResult] = useState(RESPONSE_STATS.NOTR);

  useEffect(() => {
    if (brandsPage && brandsPage.content) {
      setFilteredBrands(brandsPage.content?.filter((item) => item.title.toLowerCase().includes(brandKeyword.toLowerCase()) || brandKeyword.length === 0));
    }
  }, [brandKeyword, brandsPage]);

  const saveBrand = async (brand, type) => {
    setIsSavingBrand(true);
    setSaveBrandResult(RESPONSE_STATS.NOTR);
    setEditBrandResult(RESPONSE_STATS.NOTR);
    const response = type === 'new' ? await apiPost('/brands', brand) : await apiPut('/brands', brand);
    setIsSavingBrand(false);
    if (response && !response.errors && !response.error) {
      setBrandModal({
        ...brandModal,
        item: type === 'edit' ? response.data : response,
        type: 'edit',
        isVisible: false,
      });
      type === 'new' ? setSaveBrandResult(RESPONSE_STATS.SUCCESS) : setEditBrandResult(RESPONSE_STATS.SUCCESS);
      setValidationErrors({});
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error);
    }else {
      setValidationErrors({});
      type === 'new' ? setSaveBrandResult(RESPONSE_STATS.FAIL) : setEditBrandResult(RESPONSE_STATS.FAIL);
    }
  };

  const deleteBrand = async (id) => {
    setIsDeletingBrand(true);
    setDeleteBrandResult(RESPONSE_STATS.NOTR);
    const response = await apiDelete('/brands/' + id);
    setIsDeletingBrand(false);
    if (response) {
      setDeleteBrandResult(RESPONSE_STATS.SUCCESS);
      //fetchBrands();
    } else {
      setDeleteBrandResult(RESPONSE_STATS.FAIL);
    }
  };

  const fetchBrandsPage = async ({ page = 0, pageSize = 10, asc = false } = {}) => {
    setIsLoadingBrands(true);
    setFetchBrandResult(RESPONSE_STATS.NOTR);
    const response = await apiGet(`/brands?page=${page}&pageSize=${pageSize}&asc=${asc}`);
    if (response && !response.errors) {
      setFetchBrandResult(RESPONSE_STATS.SUCCESS);
      setBrandsPage(response);
      if (response.totalElements < 11) {
        setPagination(false);
      } else {
        setPagination({
          current: response.page + 1,
          pageSize: response.pageSize,
          total: response.totalElements,
          showSizeChanger: true,
        });
      }
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else {
      setFetchBrandResult(RESPONSE_STATS.FAIL);
    }
    await fetchEventTypeEnums();
    setIsLoadingBrands(false);
  };

  const fetchBrandsFilter = async ({ featured = false } = {}) => {
    setIsLoadingBrands(true);
    setFetchBrandResult(RESPONSE_STATS.NOTR);
    const response = await apiGet(`/brands/filter?featured=${featured}`);
    if (response === false) {
      setFetchBrandResult(RESPONSE_STATS.FAIL);
    } else {
      setFetchBrandResult(RESPONSE_STATS.SUCCESS);
      setBrandsFilter(response);
    }
    setIsLoadingBrands(false);
  };

  const fetchBrandsDropdown = async () => {
    setIsLoadingBrands(true);
    setFetchBrandResult(RESPONSE_STATS.NOTR);
    const response = await apiGet(`/dropdowns/brands`);
    if (response === false) {
      setFetchBrandResult(RESPONSE_STATS.FAIL);
    } else {
      setFetchBrandResult(RESPONSE_STATS.SUCCESS);
      setBrandsDropdown(response);
    }
    setIsLoadingBrands(false);
  };

  const fetchEventTypeEnums = async () => {
    const response = await apiGet('/enums/EventType');
    setEventTypeEnums(response);
  };

  useEffect(() => {
    if (saveBrandResult === RESPONSE_STATS.SUCCESS) {
      successMessage('saveSuccessMessage');
    } else if (saveBrandResult === RESPONSE_STATS.FAIL) {
      errorMessage('failMessage')
    }
    setSaveBrandResult(RESPONSE_STATS.NOTR);
  }, [saveBrandResult]);

  useEffect(() => {
    if (editBrandResult === RESPONSE_STATS.SUCCESS) {
      successMessage('editSuccessMessage');
    } else if (editBrandResult === RESPONSE_STATS.FAIL) {
      errorMessage('failMessage')
    }
    setEditBrandResult(RESPONSE_STATS.NOTR);
  }, [editBrandResult]);

  useEffect(() => {
    if (deleteBrandResult === RESPONSE_STATS.SUCCESS) {
      successMessage('deleteSuccessMessage');
    } else if (deleteBrandResult === RESPONSE_STATS.FAIL) {
     errorMessage('failMessage')
    }
    setDeleteBrandResult(RESPONSE_STATS.NOTR);
  }, [deleteBrandResult]);

  return {
    brandsPage,
    fetchBrandsPage,
    deleteBrand,
    isLoadingBrands,
    isDeletingBrand,
    brandModal,
    setBrandModal,
    filteredBrands,
    brandKeyword,
    setBrandKeyword,
    saveBrand,
    isSavingBrand,
    saveBrandResult,
    eventTypeEnums,
    pagination,
    brandsFilter,
    fetchBrandsFilter,
    fetchBrandsDropdown,
    setBrandsDropdown,
    brandsDropdown,
    deleteBrandResult,
    editBrandResult,
    validationErrors,
    setValidationErrors,
  };
}
