import { useState } from 'react';
import { apiGet, apiPut } from '../utils/api';
import { errorResponseMessage } from '../views/elements/ToastMessage';

// Status types
export const STATUS_TYPE = {
  NEW: 'NEW',
  PUBLISHED: 'PUBLISHED',
  UNPUBLISHED: 'UNPUBLISHED',
};

// Update parameters
const UPDATE_DTO = {
  active: Boolean,
  customerName: String,
  id: Number,
  namePublic: Boolean,
  productId: Number,
  repliedToId: Number,
  replies: Array,
  star: Number,
  status: STATUS_TYPE,
  text: String,
  writtenAt: String,
};

const useReviews = () => {
  const [allReviews, setAllReviews] = useState([]);
  const [isFetchingReviews, setIsFetchingReviews] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateResponse, setUpdateResponse] = useState({});

  /**
   * Fetch all reviews
   *
   * @param {Boolean} asc
   * @param {String} orderBy
   * @param {Number} page
   * @param {Number} pageSize
   * @returns {Boolean} Boolean
   */
  const fetchReviews = async (asc = true, orderBy = 'id', page = 0, pageSize = 25) => {
    setIsFetchingReviews(true);
    try {
      const response = await apiGet(`/reviews?asc=${asc}&orderBy=${orderBy}&page=${page}&pageSize=${pageSize}`);

      if (response) {
        setAllReviews(response.content);
        setIsFetchingReviews(false);
        return true;
      }

      setAllReviews([]);
      setIsFetchingReviews(false);

      return false;
    } catch (e) {
      setIsFetchingReviews(false);
      setAllReviews([]);
      console.error('ERROR ! When fetched the all reviews.', e);
      return false;
    }
  };

  /**
   * update for review
   *
   * @param {UPDATE_DTO} dto
   * @returns {boolean} Boolean
   */
  const updateReview = async (dto) => {
    try {
      setIsUpdating(true);

      const response = await apiPut(`/reviews`, { ...dto });

      if (response && !response.errors && !response.error) {
        setIsUpdating(false);
        setUpdateResponse(response);
        return true;
      } else if(response.error) {
        setIsUpdating(false);
        errorResponseMessage(response.error);
        return false;
      }

      setIsUpdating(false);
      setUpdateResponse({});

      return false;
    } catch (e) {
      setUpdateResponse({});
      setIsUpdating(false);
    }
  };

  return {
    allReviews,
    isFetchingReviews,
    fetchReviews,
    isUpdating,
    updateResponse,
    updateReview,
  };
};

export default useReviews;
