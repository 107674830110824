import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Input, Space, Button, Modal, Tooltip,  Select,TreeSelect } from 'antd';
import { ExclamationCircleOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import intl from 'react-intl-universal';
import { FormatMessage } from '../../../hocs';
import GLayout from '../../gLayout';

import {  SelectItem } from '../../../components';


 import AddEditPost from './_add';
import styles from './posts.module.css';
import { BasicColumn, BooleanColumn, IdColumn, ImageColumn } from '../../../components';
import useBlogPosts from '../../../hooks/useBlogPosts';
import useBlogCategories from '../../../hooks/useBlogCategories';
const { Option } = Select;

const { confirm } = Modal;
const { Search } = Input;
const BlogPosts = () => {
  const {
    
    filteredPosts,
    postKeyword,
    setPostKeyword,
    savePost,
    loadingPosts,
    deletePost,
    setPostModal,
    postModal,
    isDeletingPost,
    validationErrors,
    setValidationErrors,
    fetchBlogPosts
  } = useBlogPosts();

  const { hierarchyCategories, fetchHierarchyCategories} = useBlogCategories();

  const { SHOW_PARENT } = TreeSelect

  const tProps = {
    treeCheckable: false,
    showCheckedStrategy: SHOW_PARENT,
    treeDefaultExpandAll: true,
    style: {
      width: '100%',
    },
  };

   const preparedTreeData = (cats) => {
    const dummy = [];
    cats.forEach((d) => {
      const o = {
        title: d.title,
        value: d.id,
        key: `${d.id}`,
        children: d.childs && d.childs.length > 0 ? preparedTreeData(d.childs) : undefined,
      };
      dummy.push(o);
    });
    return dummy;
  };



  useEffect(() => {
    
    fetchHierarchyCategories();
    fetchBlogPosts();
  }, []);

  const showDeleteConfirm = (record) => {
    confirm({
      title: <FormatMessage id='blog.posts.delete.confirm.title' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingPost,
      async onOk() {
        return (async (resolve, reject) => {
          await deletePost(record.id, record.parentId);
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const onEditClick = async (record) => {

    setPostModal({
      type: 'edit',
      isVisible: true,
      item: record,
    });
  };

  const handleGroupFilter = (groupId) => {
    
      fetchBlogPosts(groupId);
    
  }


  const columns = ({ level = 0 }) => [
    IdColumn(),
    BasicColumn({ name: 'title' }),
    BasicColumn({ name: 'description' }),
    BasicColumn({ name: 'displayOrder' }),
    ImageColumn({ name: 'thumbnailImageUri', label: 'image' }),
    BooleanColumn(),
   
    {
      title: <FormatMessage id='actions' />,
      key: 'action',
      width: 180,
      render: (text, record) => (
        <Space size='middle'>
          <Tooltip title={<FormatMessage id='edit' />}>
            <Button
              icon={<EditOutlined />}
              onClick={() => onEditClick(record)}
            />
          </Tooltip>

          <Tooltip title={<FormatMessage id='delete' />}>
            <Button danger onClick={() => showDeleteConfirm(record)} icon={<DeleteOutlined />}></Button>
          </Tooltip>
         
        </Space>
      ),
    },
  ];



  return (
    <GLayout>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={10}>
            <div className='d-flex'>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setPostKeyword(e.target.value)}
              value={postKeyword}
            />
            
           
       

        <TreeSelect
            {...tProps}
            onChange={(e)=>{handleGroupFilter(e)}}
            treeData={preparedTreeData( hierarchyCategories, false)}
            placeholder="Gruba gör filtrele"
            autoClearSearchValue={true}
          />

            
            
              
            </div>
          </Col>
          <Col span={14}>
            <Button
              key='1'
              type='primary'
              disabled={loadingPosts}
              onClick={() =>
                setPostModal({
                  isVisible: true,
                  type: 'new',
                  item: {},
                })
              }
              style={{ float: 'right' }}
            >
              <FormatMessage id='blog.posts.list.button' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        bordered
        rowKey={(record) => record.id}
        columns={columns({ level: 0 })}
        dataSource={filteredPosts}
        loading={loadingPosts}
        scroll={{ x: 1500 }}
        pagination={filteredPosts.length > 10 ? true : false}
        sticky
      />
       {postModal.isVisible && (
        <AddEditPost
          onHide={() => {
            setValidationErrors({});
            setPostModal({ ...postModal, isVisible: false });
          }}
          {...postModal}
          savePost={savePost}          
          validationErrors={validationErrors}
        />
      )} 
    </GLayout>
  );
};

export default BlogPosts;
