import { useState, useEffect } from "react";
import { apiGet, apiDelete, apiPost, apiPut } from "../utils/api";
import { RESPONSE_STATS } from "../utils/constants";
import { successMessage, errorMessage, errorResponseMessage } from "../views/elements/ToastMessage";
export default function useVariants(props) {
  const [variants, setVariants] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [loadingVariants, setLoadingVariants] = useState(false);
  const [variantFailed, setVariantFailed] = useState(false);
  const [isDeletingVariant, setIsDeletingVariant] = useState(false);
  const [filteredVariants, setFilteredVariants] = useState([]);
  const [variantKeyword, setVariantKeyword] = useState("");
  const [pagination, setPagination] = useState({});
  const [variantModal, setVariantModal] = useState({
    isVisible: false,
    type: "add",
    item: false,
  });

  const [savingVariant, setSavingVariant] = useState(false);
  const [saveVariantResult, setSaveVariantResult] = useState(
    RESPONSE_STATS.NOTR
  );
  const [editVariantResult, setEditVariantResult] = useState(
    RESPONSE_STATS.NOTR
  );
  const [deleteVariantResult, setDeleteVariantResult] = useState(
    RESPONSE_STATS.NOTR
  );

  useEffect(() => {
    if (variants && variants.content) {
      setFilteredVariants(
        variants.content?.filter(
          (item) =>
            item.title.toLowerCase().includes(variantKeyword.toLowerCase()) || variantKeyword.length === 0
        )
      );
    }
  }, [variantKeyword, variants]);

  const saveVariant = async (variant, type) => {
    setSavingVariant(true);
    const response =
      type === "new"
        ? await apiPost("/variants", variant)
        : await apiPut("/variants", variant);
    setSavingVariant(false);
    if (response && !response.errors && !response.error) {
      setVariantModal({
        ...variantModal,
        item: type === "edit" ? response.data : response,
        type: "edit",
        isVisible: false,
      });
      type === "new"
        ? setSaveVariantResult(RESPONSE_STATS.SUCCESS)
        : setEditVariantResult(RESPONSE_STATS.SUCCESS);
      setValidationErrors({});
      fetchVariants();
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error)
    } else {
      setValidationErrors({});
      type === "new"
        ? setSaveVariantResult(RESPONSE_STATS.FAIL)
        : setEditVariantResult(RESPONSE_STATS.FAIL);
    }
  };

  const deleteVariant = async (id) => {
    setIsDeletingVariant(true);
    const response = await apiDelete("/variants/" + id);
    setIsDeletingVariant(false);
    if (response) {
      setDeleteVariantResult(RESPONSE_STATS.SUCCESS);
      fetchVariants();
    } else {
      setDeleteVariantResult(RESPONSE_STATS.FAIL);
    }
  };

  const fetchVariants = async ({
    page = 0,
    pageSize = 10,
    asc = false,
  } = {}) => {
    setLoadingVariants(true);

    const response = await apiGet(
      `/variants?page=${page}&pageSize=${pageSize}&asc=${asc}`
    );
    if (response === false) {
      setVariantFailed(true);
    } else {
      setVariants(response);
      if (response.totalElements < 11) {
        setPagination(false);
      } else {
        setPagination({
          current: response.page + 1,
          pageSize: response.pageSize,
          total: response.totalElements,
          showSizeChanger: true,
        });
      }
    }
    setLoadingVariants(false);
  };

  useEffect(() => {
    if (saveVariantResult === RESPONSE_STATS.SUCCESS) {
      successMessage("saveSuccessMessage");
    } else if (saveVariantResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setSaveVariantResult(RESPONSE_STATS.NOTR);
  }, [saveVariantResult]);

  useEffect(() => {
    if (editVariantResult === RESPONSE_STATS.SUCCESS) {
      successMessage("editSuccessMessage");
    } else if (editVariantResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setEditVariantResult(RESPONSE_STATS.NOTR);
  }, [editVariantResult]);

  useEffect(() => {
    if (deleteVariantResult === RESPONSE_STATS.SUCCESS) {
      successMessage("deleteSuccessMessage");
    } else if (deleteVariantResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setDeleteVariantResult(RESPONSE_STATS.NOTR);
  }, [deleteVariantResult]);

  return {
    variants,
    fetchVariants,
    deleteVariant,
    loadingVariants,
    isDeletingVariant,
    variantFailed,
    variantModal,
    setVariantModal,
    filteredVariants,
    variantKeyword,
    setVariantKeyword,
    saveVariant,
    savingVariant,
    saveVariantResult,
    pagination,
    validationErrors,
    setValidationErrors,
  };
}
