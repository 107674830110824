import { useState, useEffect } from 'react';
import { apiGet, apiDelete, apiPost, apiPut } from '../utils/api';
import { RESPONSE_STATS } from '../utils/constants';
import { successMessage, errorMessage, errorResponseMessage } from '../views/elements/ToastMessage';

export default function useCmsPages(props) {
  const [cmsPages, setCmsPages] = useState([]);
  const [nestedData, setNestedData] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [loadingCmsPages, setLoadingCmsPages] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [cmsPageFailed, setCmsPageFailed] = useState(false);
  const [isDeletingCmsPage, setIsDeletingCmsPage] = useState(false);
  const [filteredCmsPages, setfilteredCmsPages] = useState([]);
  const [cmsPageKeyword, setCmsPageKeyword] = useState('');
  const [cmsPageModal, setCmsPageModal] = useState({
    isVisible: false,
    type: 'add',
    item: false,
  });

  const [savingCmsPage, setSavingCmsPage] = useState(false);
  const [saveCmsPageResult, setSaveCmsPageResult] = useState(RESPONSE_STATS.NOTR);
  const [editCmsPageResult, setEditCmsPageResult] = useState(RESPONSE_STATS.NOTR);
  const [deleteCmsPageResult, setDeleteCmsPageResult] = useState(RESPONSE_STATS.NOTR);

  useEffect(() => {
    if (cmsPages) {
      setfilteredCmsPages(cmsPages.filter((item) => item.title?.toLowerCase().includes(cmsPageKeyword.toLowerCase()) || cmsPageKeyword.length === 0));
    }
  }, [cmsPageKeyword, cmsPages]);

  const saveCmsPage = async (cmsPage, type) => {
    setSavingCmsPage(true);
    const response = type === 'new' ? await apiPost('/cmspages', cmsPage) : await apiPut('/cmspages', cmsPage);
    setSavingCmsPage(false);
    if (response && !response.errors && !response.error) {
      setCmsPageModal({
        ...cmsPageModal,
        item: type === 'edit' ? response.data : response,
        type: 'edit',
        isVisible: false,
      });
      type === 'new' ? setSaveCmsPageResult(RESPONSE_STATS.SUCCESS) : setEditCmsPageResult(RESPONSE_STATS.SUCCESS);
      setValidationErrors({});
      if(cmsPage.parentId){
        await fetchInnerCmsPages({parentId: cmsPage.parentId});
      }else{
        await fetchCmsPages();
      }
      
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error)
    } else {
      setValidationErrors({});
      type === 'new' ? setSaveCmsPageResult(RESPONSE_STATS.FAIL) : setEditCmsPageResult(RESPONSE_STATS.FAIL);
    }
  };

  const deleteCmsPage = async (id) => {
    setIsDeletingCmsPage(true);
    const response = await apiDelete('/cmspages/' + id);
    setIsDeletingCmsPage(false);
    if (response) {
      setDeleteCmsPageResult(RESPONSE_STATS.SUCCESS);
      fetchCmsPages();
    } else {
      setDeleteCmsPageResult(RESPONSE_STATS.FAIL);
    }
  };

  const fetchCmsPages = async ({ parentId = '', isActive = '' } = {}) => {
    setLoadingCmsPages(true);

    const response = await apiGet(`/cmspages/filter?parentId=${parentId}&isActive=${isActive}`);
    if (response === false) {
      setCmsPageFailed(true);
    } else {
      setCmsPages(response);
    }
    setLoadingCmsPages(false);
  };

  const fetchInnerCmsPages = async ({ parentId = '', isActive = '' } = {}) => {
    setIsLoading({
      [parentId]: true,
    });

    const res = await apiGet(`/cmspages/filter?parentId=${parentId}&isActive=${isActive}`);
    setNestedData((state) => ({
      ...state,
      [parentId]: [...res],
    }));
    setIsLoading({
      [parentId]: false,
    });
  };

  useEffect(() => {
    if (saveCmsPageResult === RESPONSE_STATS.SUCCESS) {
      successMessage('saveSuccessMessage');
    } else if (saveCmsPageResult === RESPONSE_STATS.FAIL) {
      errorMessage('failMessage');
    }
    setSaveCmsPageResult(RESPONSE_STATS.NOTR);
  }, [saveCmsPageResult]);

  useEffect(() => {
    if (editCmsPageResult === RESPONSE_STATS.SUCCESS) {
      successMessage('editSuccessMessage');
    } else if (editCmsPageResult === RESPONSE_STATS.FAIL) {
      errorMessage('failMessage');
    }
    setEditCmsPageResult(RESPONSE_STATS.NOTR);
  }, [editCmsPageResult]);

  useEffect(() => {
    if (deleteCmsPageResult === RESPONSE_STATS.SUCCESS) {
      successMessage('deleteSuccessMessage');
    } else if (deleteCmsPageResult === RESPONSE_STATS.FAIL) {
      errorMessage('failMessage');
    }
    setDeleteCmsPageResult(RESPONSE_STATS.NOTR);
  }, [deleteCmsPageResult]);

  return {
    cmsPages,
    fetchCmsPages,
    deleteCmsPage,
    loadingCmsPages,
    isDeletingCmsPage,
    cmsPageFailed,
    cmsPageModal,
    setCmsPageModal,
    filteredCmsPages,
    cmsPageKeyword,
    setCmsPageKeyword,
    saveCmsPage,
    savingCmsPage,
    saveCmsPageResult,
    validationErrors,
    setValidationErrors,
    fetchInnerCmsPages,
    nestedData,
    isLoading,
  };
}
