import { Form } from 'antd';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { FormatMessage } from '../../hocs';
import './TextEditorItem.css';
import PropTypes from 'prop-types';
import TextArea from 'antd/lib/input/TextArea';

const textEditorModules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'code','image'],
    ['clean'],
  ],
};

const textEditorFormats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'code'];

const TextEditor = ({ value, onChange, placeholder, className="" }) => {
  return (
    <div className={className}>
      <ReactQuill
        theme='snow'
        value={value || ''}
        modules={textEditorModules}
        formats={textEditorFormats}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
};

const TextEditorItem = ({ name = '', label = '', layout = null, isRequired = false, validationErrors = null, editorClassName, disableRichEditor=false }) => {
  return (
    <Form.Item
      name={name}
      rules={[{ required: isRequired }]}
      label={<FormatMessage id={label || name} />}
      {...layout}
      validateStatus={validationErrors?.[name] && 'error'}
      help={validationErrors?.[name]}
    >
     {disableRichEditor ? 
      <TextArea className={editorClassName} /> :  <TextEditor className={editorClassName}/> }
      
    </Form.Item>
  );
};

TextEditorItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  layout: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  validationErrors: PropTypes.object,
  editorClassName: PropTypes.object
};

export default TextEditorItem;
