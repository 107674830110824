import { Button, Space, Row, Col, Input, Modal, Table, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { FormatMessage } from '../../../../hocs';
import intl from 'react-intl-universal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useAttributeValues from '../../../../hooks/useAttributeValues';
import AddEditAttributeValue from './_add';
import { ActionsColumn, BasicColumn, BooleanColumn, IdColumn } from '../../../../components';
const { confirm } = Modal;
const { Search } = Input;

const AttributeValues = ({ attribute }) => {
  const {
    attributeValues,
    loadingAttributeValues,
    fetchAttributeValues,
    setAttributeValueModal,
    isDeletingAttributeValue,
    deleteAttributeValue,
    setAttributeValueKeyword,
    attributeValueKeyword,
    filteredAttributeValues,
    attributeValueModal,
    saveAttributeValue,
    pagination,
    validationErrors,
    setValidationErrors,
  } = useAttributeValues();

  useEffect(() => {
    fetchAttributeValues(attribute.id);
  }, [attribute]);

  const showDeleteConfirm = (id) => {
    confirm({
      title: <FormatMessage id='attributeValues.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingAttributeValue,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteAttributeValue(attribute.id, id);
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const columns = [
    IdColumn(),
    BasicColumn({ name: 'title' }),
    BooleanColumn(),
    ActionsColumn({
      onClickEdit: setAttributeValueModal,
      onClickDelete: showDeleteConfirm,
    }),
  ];

  return (
    <>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setAttributeValueKeyword(e.target.value)}
              value={attributeValueKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              disabled={loadingAttributeValues}
              onClick={() =>
                setAttributeValueModal({
                  isVisible: true,
                  type: 'new',
                  item: {},
                })
              }
              style={{ float: 'right' }}
            >
              <FormatMessage id='attributeValues.addModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        rowKey='id'
        bordered
        loading={loadingAttributeValues}
        title={() =>
          attribute &&
          `${attribute.title} ${intl.formatMessage({
            id: 'attributeValues.title',
          })}`
        }
        pagination={filteredAttributeValues && filteredAttributeValues.length > 10}
        dataSource={filteredAttributeValues}
        columns={columns}
      />
      {attributeValueModal.isVisible && (
        <AddEditAttributeValue
          onHide={() => {
            setAttributeValueModal({
              ...attributeValueModal,
              isVisible: false,
            });
            setValidationErrors({});
          }}
          {...attributeValueModal}
          saveAttributeValue={saveAttributeValue}
          attribute={attribute}
          validationErrors={validationErrors}
        />
      )}
    </>
  );
};

export default AttributeValues;
