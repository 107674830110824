import { useEffect, useState } from 'react';
import { Modal, Row, Col, Image, Divider } from 'antd';
import styles from './_view.image.module.css';
import useProductMedias from '../../../hooks/useProductMedia';
import _ from 'lodash';

const ViewImageProduct = ({ variantValues = [], item = null, isVisible = false, onHide = null }) => {
  const { fetchProductMediasByProductId, productMedias } = useProductMedias();
  const [viewMedias, setViewMedias] = useState([]);

  useEffect(() => {
    fetchProductMediasByProductId({ productId: item.id });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(productMedias)) {
      const tempArray = [];
      productMedias.forEach((pm) => {
        if (!_.find(tempArray, ['variantValueId', pm.variantValueId])) {
          tempArray.push({
            title: _.find(variantValues, ['id', pm.variantValueId])?.title,
            variantValueId: pm.variantValueId,
            images: [`${pm.uploadedFileUri}`],
          });
        } else {
          const foundPm = _.find(tempArray, ['variantValueId', pm.variantValueId]);
          foundPm.images.push(`${pm.uploadedFileUri}`);
        }
      });
      setViewMedias([...tempArray]);
    }
  }, [productMedias]);

  console.log(viewMedias);
  return (
    <Modal title='Basic Modal' visible={isVisible} onCancel={onHide} width={800}>
      {viewMedias.map((viewMedia) => {
        return (
          <Row>
            <Divider orientation='left'>{viewMedia.title}</Divider>
            <Col span={24}>
              {viewMedia.images.map((imageUrl) => (
                <Image className={styles.imageBox} width={120} height={120} src={imageUrl} />
              ))}
            </Col>
          </Row>
        );
      })}
    </Modal>
  );
};

export default ViewImageProduct;
