import React from 'react';
import { Form, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { FormatMessage } from '../../hocs';

const NumberItem = ({ name = '', label = '', layout = null, isRequired = false, step = 1, validationErrors = null, inputProps = {}, rules = {}, disabled = false }) => {

  return (
    <Form.Item
      name={name}
      rules={[ { required: isRequired, type: "number", ...rules} ]}
      label={typeof label === 'string' ? <FormatMessage id={label} /> : label}
      validateStatus={validationErrors?.[name] && 'error'}
      help={validationErrors?.[name]}
      {...layout}
    >
      <InputNumber 
        disabled={disabled}
        step={step}
        {...inputProps} 
      />
    </Form.Item>
  );
};

NumberItem.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  layout: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,
  step: PropTypes.number,
  validationErrors: PropTypes.object,
  inputProps: PropTypes.object,
  rules: PropTypes.object,
};

export default NumberItem;
