import { Button, Row, Col, Input, Modal, Table } from 'antd';
import React, { useEffect } from 'react';
import { FormatMessage } from '../../../../hocs';
import intl from 'react-intl-universal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useVariantValues from '../../../../hooks/useVariantValues';
import AddEditVariantValue from './_add';
import { ActionsColumn, BasicColumn, BooleanColumn, IdColumn } from '../../../../components';
const { confirm } = Modal;
const { Search } = Input;

const VariantValues = ({ variant }) => {
  const {
    loadingVariantValues,
    fetchVariantValues,
    setVariantValueModal,
    isDeletingVariantValue,
    deleteVariantValue,
    setVariantValueKeyword,
    variantValueKeyword,
    filteredVariantValues,
    variantValueModal,
    saveVariantValue,
    validationErrors,
    setValidationErrors,
  } = useVariantValues();

  useEffect(() => {
    fetchVariantValues(variant.id);
  }, [variant]);

  const showDeleteConfirm = (id) => {
    confirm({
      title: <FormatMessage id='variantValues.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingVariantValue,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteVariantValue(variant.id, id);
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const columns = [
    IdColumn(),
    BasicColumn({ name: 'title' }),
    BasicColumn({ name: 'displayOrder' }),
    BasicColumn({ name: 'extraInfo' }),
    BooleanColumn(),
    ActionsColumn({
      onClickEdit: setVariantValueModal,
      onClickDelete: showDeleteConfirm,
    }),
  ];


  return (
    <>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setVariantValueKeyword(e.target.value)}
              value={variantValueKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              disabled={loadingVariantValues}
              onClick={() => setVariantValueModal({ isVisible: true, type: 'new', item: {} })}
              style={{ float: 'right' }}
            >
              <FormatMessage id='variantValues.addModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        rowKey='id'
        bordered
        loading={loadingVariantValues}
        title={() =>
          variant &&
          `${variant.title} ${intl.formatMessage({
            id: 'variantValues.title',
          })}`
        }
        dataSource={filteredVariantValues}
        columns={columns}
        pagination={filteredVariantValues && filteredVariantValues.length > 10}
      />
      {variantValueModal.isVisible && (
        <AddEditVariantValue
          onHide={() => {
            setValidationErrors({});
            setVariantValueModal({ ...variantValueModal, isVisible: false });
          }}
          {...variantValueModal}
          saveVariantValue={saveVariantValue}
          variant={variant}
          validationErrors={validationErrors}
        />
      )}
    </>
  );
};

export default VariantValues;
