import { combineReducers } from 'redux';
import AuthReducer from './auth';
import LocaleReducer from './locale';
import InterestsReducer from './interests';

export default combineReducers({
  auth: AuthReducer,
  locale: LocaleReducer,
  interests: InterestsReducer
});
