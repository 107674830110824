import { useState } from "react";
import { apiGet } from "../utils/api";

export default function useCounties(props) {
  const [counties, setCounties] = useState([]);
  const [isFetchingCounties, setIsFetchingCounties] = useState(false);
  const [fetchingCountiesFailed, setFetchingCountiesFailed] = useState(false);

  const fetchCounties = async ({ cityId }) => {
    setIsFetchingCounties(true);
    const response = await apiGet(`/counties/filter/${cityId}`);
    if (response === false) {
      setFetchingCountiesFailed(true);
    } else {
      setCounties(response);
    }
    setIsFetchingCounties(false);
  };

  return {
    counties,
    fetchCounties,
    isFetchingCounties,
    fetchingCountiesFailed,
    setCounties
  };
}
