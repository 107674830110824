import { Form, DatePicker } from 'antd';
import { FormatMessage } from '../../hocs';
import moment from 'moment';

export const dateFormat = 'YYYY-MM-DD HH:mm';

const DatePickerItem = ({ value = '', name = '', label = '', layout = null, isRequired = false, validationErrors = [] }) => {

  return (
    <Form.Item
      name={name}
      rules={[{ required: isRequired }]}
      label={<FormatMessage id={label} />}
      validateStatus={validationErrors?.[name] && 'error'}
      help={validationErrors?.[name]}
      {...layout}
    >
      <DatePicker showTime defaultValue={value ? moment(value, dateFormat) : null} format={dateFormat} />
    </Form.Item>
  );
};

export default DatePickerItem;
