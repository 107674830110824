import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import { FormatMessage } from '../../../hocs';
import { SwitchItem, NumberItem, TextItem, SpanItem, TextEditorItem } from '../../../components';

const formItemLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 18,
  },
};

const AddEditFaq = ({ isVisible, type, item, onHide, saveFaq, faq, typeEnums, validationErrors }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isVisible && type === 'edit') {
      form.setFieldsValue({ ...item });
    } else form.resetFields();
  }, [item, isVisible, type, form]);

  const handleOk = () => {
    if (type === 'new') {
      form
        .validateFields()
        .then(async (values) => {
          setIsLoading(true);
          const req = faq
            ? {
                parentId: faq.id,
                ...values,
              }
            : { ...values };
          await saveFaq(req, 'new');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    } else if (type === 'edit') {
      form
        .validateFields()
        .then(async (values) => {
          const data = {
            ...values,
            id: item.id,
            parentId: item.parentId,
          };
          setIsLoading(true);
          await saveFaq(data, 'edit');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }
  };

  return (
    <Modal
      title={type === 'edit' ? <FormatMessage id='faqs.editModalTitle' /> : <FormatMessage id='faqs.addModalTitle' />}
      width={800}
      visible={isVisible}
      confirmLoading={isLoading}
      onOk={() => handleOk()}
      okText={<FormatMessage id='save' />}
      onCancel={() => {
        onHide();
      }}
      cancelText={<FormatMessage id='cancel' />}
    >
      <Form form={form} name='control-ref' initialValues={{ ...item }} layout='horizontal'>
        {faq && <SpanItem value={faq.title} label='faqs.parentFaq' layout={{ ...formItemLayout }} />}
        <TextItem name='title' label='title' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <TextEditorItem name='content' label='content' layout={{ ...formItemLayout }} validationErrors={validationErrors} />
        <NumberItem name='displayOrder' label='displayOrder' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <SwitchItem name='active' label='active' validationErrors={validationErrors} value={item.active} layout={{ ...formItemLayout }} />
        <SwitchItem
          name='featured'
          label='featured'
          validationErrors={validationErrors}
          value={item.featured}
          layout={{ ...formItemLayout }}
        />
      </Form>
    </Modal>
  );
};

export default AddEditFaq;
