import { useState, useEffect } from 'react';
import { apiGet, apiDelete, apiPost, apiPut } from '../utils/api';
import { RESPONSE_STATS } from '../utils/constants';
import { successMessage, errorMessage, errorResponseMessage } from '../views/elements/ToastMessage';

export default function useProducts(props) {
  const [products, setProducts] = useState([]);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [productFailed, setProductFailed] = useState(false);
  const [deleteProductFailed, setDeleteProductFailed] = useState(false);
  const [isDeletingProduct, setIsDeletingProduct] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productKeyword, setProductKeyword] = useState('');
  const [productStatusEnums, setProductStatusEnums] = useState([]);
  const [productDeliveryTypeEnums, setProductDeliveryTypeEnums] = useState([]);
  const [productCargoTypeEnums, setProductCargoTypeEnums] = useState([]);
  const [productModal, setProductModal] = useState({
    isVisible: false,
    type: 'add',
    item: false,
  });

  const [addImageModal, setAddImageModal] = useState({
    isVisible: false,
    item: false,
  });

  const [viewImageModal, setViewImageModal] = useState({
    isVisible: false,
    item: false,
  });

  const [pagination, setPagination] = useState({
    current: 0,
    pageSize: 10,
    showSizeChanger: true,
  });

  const [savingProduct, setSavingProduct] = useState(false);

  const fetchPagination = async (customPage, pageSize, query) => {
    let page = customPage === 0 ? 0 : customPage - 1;

    const request = `/products?page=${page}&pageSize=${pageSize}&asc=${false}${query ? `&query=${query}` : ''}`;

    let response = await apiGet(request);

    setFilteredProducts(response.content);

    setPagination({
      current: page + 1,
      pageSize: response.pageSize,
      total: response.totalElements,
      showSizeChanger: response.totalElements > 10,
    });
  };

  useEffect(() => {
    fetchPagination(pagination.current, pagination.pageSize, productKeyword === '' ? undefined : productKeyword);
  }, [pagination.current, productKeyword]);

  const saveProduct = async (product, type) => {
    setSavingProduct(true);
    const response = type === 'new' ? await apiPost('/products', product) : await apiPut('/products', product);
    setSavingProduct(false);
    if (response && !response.errors && !response.error) {
      setProductModal({
        ...productModal,
        item: type === 'edit' ? response.data : response,
        type: 'edit',
        isVisible: false,
      });
      type === 'new' ? successMessage('saveSuccessMessage') : successMessage('editSuccessMessage');
      setValidationErrors({});
      fetchProducts();
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error);
    } else {
      setValidationErrors({});
      errorMessage('failMessage');
    }
  };

  const deleteProduct = async (id) => {
    setIsDeletingProduct(true);
    const response = await apiDelete('/products/' + id);
    setIsDeletingProduct(false);
    if (response) {
      successMessage('deleteSuccessMessage');
      fetchProducts();
    } else {
      errorMessage('failMessage');
    }
  };

  const fetchProducts = async ({ page = 0, pageSize = 10, asc = false } = {}) => {
    setLoadingProducts(true);

    const response = await apiGet(`/products?page=${page}&pageSize=${pageSize}&asc=${asc}`);
    if (response === false) {
      setProductFailed(true);
    } else {
      setProducts(response);
      setFilteredProducts(response.content);
      if (response.totalElements < 11) {
        setPagination(false);
      } else {
        setPagination({
          current: response.page + 1,
          pageSize: response.pageSize,
          total: response.totalElements,
          showSizeChanger: true,
        });
      }
    }
    await fetchProductStatusEnums();
    await fetchCargoTypeEnums();
    await fetchDeliveryTypeEnums();
    setLoadingProducts(false);
  };

  const getProducts = async () => {
    setLoadingProducts(true);

    const response = [
      {
        id: 1,
        active: true,
        code: 'XYZ',
        brandId: 1,
        title: 'Inserted',
        shortDesc: 'ShortDesc',
        fullDesc: 'Guncelle',
        details: 'Urun Detay',
        listPriceIncVat: 600,
        sellingPriceIncVat: 350,
        status: 'INSALE',
        vatType: 'ONE',
        rating: 4.7,
        categoryId: 1,
        unit: 'PIECE',
        badges: [],
        tags: [],
        discounts: [],
        attributeValues: [],
      },
      {
        id: 4,
        active: true,
        code: 'XYZ1',
        brandId: 1,
        title: 'Inserted1',
        shortDesc: 'ShortDesc',
        fullDesc: 'Guncelle',
        details: 'Urun Detay',
        listPriceIncVat: 600,
        sellingPriceIncVat: 350,
        status: 'INSALE',
        vatType: 'ONE',
        rating: 4.7,
        categoryId: 1,
        unit: 'PIECE',
        badges: [],
        tags: [],
        discounts: [],
        attributeValues: [],
      },
    ]; //await apiGet(`/products?page=${page}&pageSize=${pageSize}`);
    if (response === false) {
      setProductFailed(true);
    } else {
      setProducts(response);
    }
    setLoadingProducts(false);
  };

  const fetchProductStatusEnums = async () => {
    const response = await apiGet('/enums/ProductStatus');
    setProductStatusEnums(response);
  };

  const fetchDeliveryTypeEnums = async () => {
    const response = await apiGet('/enums/DeliveryType');
    setProductDeliveryTypeEnums(response);
  };

  const fetchCargoTypeEnums = async () => {
    const response = await apiGet('/enums/CargoType');
    setProductCargoTypeEnums(response);
  };

  return {
    products,
    fetchProducts,
    deleteProduct,
    loadingProducts,
    isDeletingProduct,
    productFailed,
    deleteProductFailed,
    productModal,
    setProductModal,
    filteredProducts,
    productKeyword,
    setProductKeyword,
    saveProduct,
    savingProduct,
    pagination,
    fetchPagination,
    productStatusEnums,
    productDeliveryTypeEnums,
    productCargoTypeEnums,
    getProducts,
    validationErrors,
    setValidationErrors,
    addImageModal,
    setAddImageModal,
    viewImageModal,
    setViewImageModal,
  };
}
