import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import { FormatMessage } from '../../../hocs';
import { TextItem, SwitchItem } from '../../../components';
import PasswordInput from '../../../components/formItems/passwordInput/PasswordInput';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddEditUser = ({ isVisible, type, item, onHide, saveUser, validationErrors }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [hasPasswordValidationError, setHasPasswordValidationError] = useState(true);

  useEffect(() => {
    if (isVisible && type === 'edit') {
      form.setFieldsValue({ ...item });
    } else form.resetFields();
  }, [item, isVisible, type, form]);

  const handleOk = () => {
    if (type === 'new') {
      form
        .validateFields()
        .then(async (values) => {
          const request = {
            ...values,
            type: 'ADMIN',
          };
          setIsLoading(true);
          await saveUser(request, 'new');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    } else if (type === 'edit') {
      form
        .validateFields()
        .then(async (values) => {
          const data = {
            ...values,
            id: item.id,
            type: 'ADMIN',
          };
          setIsLoading(true);
          await saveUser(data, 'edit');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }
  };

  return (
    <Modal
      title={type === 'edit' ? <FormatMessage id='users.editModalTitle' /> : <FormatMessage id='users.addModalTitle' />}
      visible={isVisible}
      confirmLoading={isLoading}
      onOk={() => handleOk()}
      okText={<FormatMessage id='save' />}
      onCancel={() => {
        onHide();
      }}
      okButtonProps={{ disabled: hasPasswordValidationError }}
      cancelText={<FormatMessage id='cancel' />}
    >
      <Form form={form} name='control-ref' initialValues={{ ...item }} layout='horizontal'>
        <TextItem name='email' label='email' layout={{ ...formItemLayout }} validationErrors={validationErrors} />
        {type === 'new' && (
          <PasswordInput
            form={form}
            name='password'
            label='password'
            type='password'
            validationErrors={validationErrors}
            layout={{ ...formItemLayout }}
            hasValidationError={(hasError) => setHasPasswordValidationError(hasError)}
          />
        )}
        <TextItem name='phone' label='phone' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <SwitchItem name='active' label='active' value={item.active} validationErrors={validationErrors} layout={{ ...formItemLayout }} />
      </Form>
    </Modal>
  );
};

export default AddEditUser;
