import { Space, Tooltip, Button } from 'antd';
import { FormatMessage } from '../../hocs';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const ActionsColumn = ({ onClickEdit, onClickDelete, width }) => {
  return {
    title: <FormatMessage id='actions' />,
    key: 'action',
    width: width ? width : 180,
    render: (text, record) => (
      <Space size='middle'>
        <Tooltip title={<FormatMessage id='edit' />}>
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              onClickEdit({ type: 'edit', isVisible: true, item: record });
            }}
          />
        </Tooltip>

        <Tooltip title={<FormatMessage id='delete' />}>
          <Button danger onClick={() => onClickDelete(record.id)} icon={<DeleteOutlined />}></Button>
        </Tooltip>
      </Space>
    ),
  };
};

ActionsColumn.propTypes = {
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
  width: PropTypes.number,
};

export default ActionsColumn;
