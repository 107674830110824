import React, { useEffect, useState } from "react";
import { Form, Modal } from "antd";
import FormatMessage from "../../../hocs/FormatMessage";
import { TextItem } from "../../../components";

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const ChangePasswordModal = ({
  isVisible,
  userId,
  changePassword,
  onHide,
  validationErrors,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [isVisible, form]);

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        setIsLoading(true);
        await changePassword(userId, values);
        setIsLoading(false);
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  return (
    <Modal
      title={<FormatMessage id="changePassword" />}
      visible={isVisible}
      confirmLoading={isLoading}
      onOk={() => handleOk()}
      okText={<FormatMessage id="save" />}
      onCancel={() => {
        onHide();
      }}
      cancelText={<FormatMessage id="cancel" />}
    >
      <Form form={form} name="control-ref" layout="horizontal">
        <TextItem
          name="password"
          label="password"
          layout={{ ...formItemLayout }}
          type="password"
          validationErrors={validationErrors}
        />
        <TextItem
          name="confirm"
          label="confirmPassword"
          layout={{ ...formItemLayout }}
          type="password"
          validationErrors={validationErrors}
        />
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
