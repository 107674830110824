import React, { useEffect, useState } from 'react';
import { Form, Modal, Select } from 'antd';
import { FormatMessage } from '../../../hocs';
import { SwitchItem, NumberItem, TextItem, SpanItem, TextEditorItem, SelectItem } from '../../../components';
import SingleImageItem from '../../../components/formItems/SingleImageItem';

import useSlug from '../../../hooks/useSlug';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddEditCategory = ({ isVisible, type, item, onHide, saveCategory, category, typeEnums, validationErrors, parentCategories }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const {convertToSlug} = useSlug();
  const [slug, setSlug] = useState('');

  useEffect(() => {
    if (isVisible && type === 'edit') {
      form.setFieldsValue({ ...item });
    } else form.resetFields();
  }, [item, isVisible, type, form]);


  const handeBlur = (text) => {
    
    setSlug(convertToSlug(text));
    console.log(slug);
  }

  const handleOk = () => {
    if (type === 'new') {
      form
        .validateFields()
        .then(async (values) => {
          setIsLoading(true);
          const req = category
            ? {
                parentId: category.id,
                ...values,
              }
            : { ...values };
          await saveCategory(req, 'new');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    } else if (type === 'edit') {
      form
        .validateFields()
        .then(async (values) => {
          const data = {
            ...values,
            id: item.id,
          };
          setIsLoading(true);
          await saveCategory(data, 'edit');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }
  };

  const RenderUpdateParentField = () => {
    if (type === 'edit' && item.parentId) {
      return (
        <SelectItem name='parentId' label={'categories.parentCategory'} layout={formItemLayout}>
          {parentCategories.map((parent) => (
            <Option value={parent.id}>{parent.name}</Option>
          ))}
        </SelectItem>
      );
    }
    return <></>;
  };

  return (
    <Modal
      title={type === 'edit' ? <FormatMessage id='blog.categories.form.update.title' /> : <FormatMessage id='blog.categories.form.create.title' />}
      visible={isVisible}
      confirmLoading={isLoading}
      width={1000}
      onOk={() => handleOk()}
      okText={<FormatMessage id='save' />}
      onCancel={onHide}
      cancelText={<FormatMessage id='cancel' />}
    >
      <Form form={form} name='control-ref' initialValues={{ ...item }} layout='horizontal'>
        {category && <SpanItem value={category.title} label='categories.parentCategory' layout={{ ...formItemLayout }} />}
        <RenderUpdateParentField />
        <TextItem name='title' label='title' onBlur={(e)=>{console.log( handeBlur(e.target.value))}} validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <TextItem name='slug' label='slug' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <TextEditorItem disableRichEditor={true} name='description' label='description' layout={{ ...formItemLayout }} validationErrors={validationErrors} />
        <NumberItem name='displayOrder' label='displayOrder' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <SwitchItem name='active' label='active' validationErrors={validationErrors} value={item.active} layout={{ ...formItemLayout }} />
       
        <SingleImageItem
          name='homeImageId'
          label='image'
          layout={{ ...formItemLayout }}
          validationErrors={validationErrors}
          uri={item?.homeImageUri}
          dir='blog/groups'
        />
      </Form>
    </Modal>
  );
};

export default AddEditCategory;
