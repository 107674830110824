export const LOCALES = [
  { id: 'en-US', path: 'en', title: 'English' },
  { id: 'tr-TR', path: 'tr', title: 'Türkçe' },
];


export const RESPONSE_STATS ={
  SUCCESS : 1,
  FAIL : 2,
  NOTR : 0
} 