import { SET_USER, SET_USER_TYPE } from './types';

export const setUser = (data) => ({
  type: SET_USER,
  payload: data,
});

export const setUserType = (data) => ({
  type: SET_USER_TYPE,
  payload: data,
});