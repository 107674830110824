import { useState } from "react";
import { apiGet } from "../utils/api";

export default function useEnums() {
  const [productVatTypes, setProductVatTypes] = useState();

  const fetchProductVatTypes = async () => {
    const response = await apiGet(`/enums/ProductVatType`);
    if (response) {
      setProductVatTypes(response);
    }
  };

  return {
    productVatTypes,
    fetchProductVatTypes,
  };
}
