import React from "react";
import { Layout } from "antd";
const { Footer } = Layout;

const GFooter = () => {
  return (
    <Footer
      style={{
        textAlign: "center",
        background: "#fff",
        /* marginTop: 0,
        marginBottom: 0,
        paddingTop: 24,
        paddingBottom: 24,
        minHeight: 72, */
      }}
    >
      GIVLON ©2021
    </Footer>
  );
};

export default GFooter;
