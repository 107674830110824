import { FormatMessage } from '../../hocs';
import PropTypes from 'prop-types';

const BasicColumn = ({ name, width, label, ellipsis = true, renderType = 'default' }) => {
  return {
    title: <FormatMessage id={label ? label : name} />,
    dataIndex: name,
    key: name,
    width: width,
    ellipsis,
    render: (text) => (renderType === 'html' ? <span dangerouslySetInnerHTML={{ __html: text }} /> : text),
  };
};

BasicColumn.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.number,
  ellipsis: PropTypes.bool,
};

export default BasicColumn;
