import React, { useEffect } from 'react';
import { Table } from 'antd';
import GLayout from '../../gLayout';
import { BasicColumn, BooleanColumn, IdColumn } from '../../../components';
import useReviews, { STATUS_TYPE } from '../../../hooks/useReviews';
import DateColumn from '../../../components/tableColumns/DateColumn';
import ApproveRejectColumn from '../../../components/tableColumns/ApproveRejectColumn';

const Reviews = () => {
  const { isFetchingReviews, fetchReviews, allReviews, isUpdating, updateReview } = useReviews();

  useEffect(() => {
    fetchReviews();
  }, []);

  const handleTableChange = async ({ current, pageSize }) => {};

  const update = async ({ type, record }) => {
    const updateTypes = {
      reject: STATUS_TYPE.UNPUBLISHED,
      approve: STATUS_TYPE.PUBLISHED,
    };

    let dto = { ...record, status: updateTypes[type] };

    const updateReviewResponse = await updateReview(dto);

    if(updateReviewResponse) {
      fetchReviews();
    }
  };

  const columns = [
    IdColumn(),
    BasicColumn({ name: 'customerName', width: 350 }),
    BasicColumn({ name: 'text' }),
    BooleanColumn(),
    DateColumn({ name: 'writtenAt' }),
    ApproveRejectColumn({
      onApproveClick: update,
      onRejectClick: update,
      loading: isUpdating,
      checkKey: STATUS_TYPE.NEW,
      checkField: 'status'
    }),
  ];

  return (
    <GLayout>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={allReviews}
        loading={isFetchingReviews}
        scroll={{ x: 1500 }}
        onChange={handleTableChange}
        pagination={25}
        sticky
      />
    </GLayout>
  );
};

export default Reviews;
