import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Layout, Breadcrumb } from "antd";
import "./BasicLayout.css";
import GLeftMenu from "./common/leftMenu";
import GHeader from "./common/header";
import GFooter from "./common/footer";
import FormatMessage from "../hocs/FormatMessage";

const { Content } = Layout;

const GLayout = ({ children }) => {
  const camelize = (text) => {
    text = text.replace(/[-_\s.]+(.)?/g, (_, c) => (c ? c.toUpperCase() : ""));
    return text.substr(0, 1).toLowerCase() + text.substr(1);
  };

  const { path } = useRouteMatch();
  const paths = path.split("/");
  paths.shift();
  return (
    <Layout>
      <GLeftMenu />
      <Layout className="site-layout" style={{ marginLeft: 200 }}>
        <GHeader />
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
            minHeight: "calc(100% - 100px)",
          }}
        >
          <Breadcrumb style={{ marginBottom: 24 }}>
            {paths.map((p) => (
              <Breadcrumb.Item key={p}>
                <FormatMessage id={`leftMenu.${camelize(p)}`} />
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              textAlign: "center",
              minHeight: "calc(100vh - 270px)",
            }}
          >
            {children}
          </div>
        </Content>
        <GFooter />
      </Layout>
    </Layout>
  );
};

export default GLayout;
