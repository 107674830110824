import { useState, useEffect } from "react";
import { apiGet, apiDelete, apiPost, apiPut } from "../utils/api";
import { RESPONSE_STATS } from "../utils/constants";
import { successMessage, errorMessage, errorResponseMessage } from "../views/elements/ToastMessage";
export default function useInventories(props) {
  const [inventoriesPage, setInventoriesPage] = useState([]);
  const [pagination, setPagination] = useState({
      current: 0,
      pageSize: 10,
      showSizeChanger: true,
  });
  const [validationErrors, setValidationErrors] = useState({});

  const [isLoadingInventories, setIsLoadingInventories] = useState(false);
  const [isSavingInventory, setIsSavingInventory] = useState(false);
  const [isDeletingInventory, setIsDeletingInventory] = useState(false);

  const [filteredInventories, setFilteredInventories] = useState([]);
  const [inventoryKeyword, setInventoryKeyword] = useState("");
  const [inventoryStatusEnums, setInventoryStatusEnums] = useState([]);

  const [inventoryModal, setInventoryModal] = useState({
    isVisible: false,
    type: "add",
    item: false,
  });

  const [importInventoryModal, setImportInventoryModal] = useState({
    isVisible: false,
    type: "add",
    item: false,
  });

  const [updateInventoryModal, setUpdateInventoryModal] = useState({
    isVisible: false,
    type: "update",
    item: false,
  });

  const [, setFetchInventoryResult] = useState(
    RESPONSE_STATS.NOTR
  );
  const [saveInventoryResult, setSaveInventoryResult] = useState(
    RESPONSE_STATS.NOTR
  );
  const [editInventoryResult, setEditInventoryResult] = useState(
    RESPONSE_STATS.NOTR
  );
  const [deleteInventoryResult, setDeleteInventoryResult] = useState(
    RESPONSE_STATS.NOTR
  );

  const fetchPagination = async (customPage, pageSize, query) => {
    
    let page = customPage === 0 ? 0 : customPage-1;

    const request = `/inventories?page=${page}&pageSize=${pageSize}&asc=${false}${query ? `&query=${query}` : ''}`;

    const response = await apiGet(request);
    
    setFilteredInventories(response.content);

    setPagination({
      current: page + 1,
      pageSize: response.pageSize ? response.pageSize : 10,
      total: response.totalElements,
      showSizeChanger: response.totalElements > 10,
    });
  }

  useEffect(()=> {
    fetchPagination(pagination.current, pagination.pageSize, inventoryKeyword === '' ? undefined : inventoryKeyword)
  },[pagination.current, inventoryKeyword])

  const saveInventory = async (inventory, type) => {
    setIsSavingInventory(true);
    const response =
      type === "new"
        ? await apiPost("/inventories", inventory)
        : await apiPut("/inventories", inventory);
    setIsSavingInventory(false);
    if (response && !response.errors && !response.error) {
      setInventoryModal({
        ...inventoryModal,
        item: type === "edit" ? response.data : response,
        type: "edit",
        isVisible: false,
      });
      type === "new"
        ? setSaveInventoryResult(RESPONSE_STATS.SUCCESS)
        : setEditInventoryResult(RESPONSE_STATS.SUCCESS);
      setValidationErrors({});
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error);
    } else {
      setValidationErrors({});
      errorMessage("failMessage");
    }
  };

  const deleteInventory = async (id) => {
    setIsDeletingInventory(true);
    const response = await apiDelete("/inventories/" + id);
    setIsDeletingInventory(false);
    if (response) {
      setDeleteInventoryResult(RESPONSE_STATS.SUCCESS);
    } else {
      setDeleteInventoryResult(RESPONSE_STATS.FAIL);
    }
  };

  const fetchInventoriesPage = async ({
    page = 0,
    pageSize = 10,
    asc = false,
  } = {}) => {
    setIsLoadingInventories(true);
    console.log('xxx',pageSize)
    const response = await apiGet(
      `/inventories?page=${page}&pageSize=${pageSize}&asc=${asc}`
    );
    setIsLoadingInventories(false);
    if (response === false) {
      setFetchInventoryResult(RESPONSE_STATS.FAIL);
    } else {
      setFetchInventoryResult(RESPONSE_STATS.SUCCESS);
      setInventoriesPage(response);
      setFilteredInventories(response.content);
      if (response.totalElements < 11) {
        setPagination(false);
      } else {
        setPagination({
          current: response.page + 1,
          pageSize: response.pageSize,
          total: response.totalElements,
          showSizeChanger: true,
        });
      }
    }
    await fetchInventoryStatusEnums();
  };

  const fetchInventoryStatusEnums = async () => {
    const response = await apiGet("/enums/InventoryStatus");
    setInventoryStatusEnums(response);
  };

  useEffect(() => {
    if (saveInventoryResult === RESPONSE_STATS.SUCCESS) {
      successMessage("saveSuccessMessage");
    } else if (saveInventoryResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setSaveInventoryResult(RESPONSE_STATS.NOTR);
  }, [saveInventoryResult]);

  useEffect(() => {
    if (editInventoryResult === RESPONSE_STATS.SUCCESS) {
      successMessage("editSuccessMessage");
    } else if (editInventoryResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setEditInventoryResult(RESPONSE_STATS.NOTR);
  }, [editInventoryResult]);

  useEffect(() => {
    if (deleteInventoryResult === RESPONSE_STATS.SUCCESS) {
      successMessage("deleteSuccessMessage");
    } else if (deleteInventoryResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setDeleteInventoryResult(RESPONSE_STATS.NOTR);
  }, [deleteInventoryResult]);

  return {
    inventoriesPage,
    fetchInventoriesPage,
    deleteInventory,
    isLoadingInventories,
    isDeletingInventory,
    inventoryModal,
    setInventoryModal,
    importInventoryModal, 
    setImportInventoryModal,
    updateInventoryModal, 
    setUpdateInventoryModal,
    filteredInventories,
    inventoryKeyword,
    setInventoryKeyword,
    saveInventory,
    isSavingInventory,
    pagination,
    fetchPagination,
    inventoryStatusEnums,
    deleteInventoryResult,
    saveInventoryResult,
    editInventoryResult,
    validationErrors,
    setValidationErrors,
  };
}
