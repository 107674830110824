import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Form, Select, Modal, message, Tooltip, Upload, Button } from 'antd';
import { FormatMessage } from '../../../hocs';
import intl from 'react-intl-universal';
import _ from 'lodash';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

import 'react-quill/dist/quill.snow.css';
import { SelectItem } from '../../../components';
import { getToken } from '../../../utils/api';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';
import useProductMedias from '../../../hooks/useProductMedia';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 16,
  },
};
const type = 'DragableUploadList';

const DragableUploadListItem = ({ originNode, moveRow, file, fileList }) => {
  const ref = React.useRef();
  const index = fileList.indexOf(file);
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item) => {
      moveRow(item.index, index);
    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));
  const errorNode = <Tooltip title='Upload Error'>{originNode.props.children}</Tooltip>;
  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', height: '104px', width: '104px' }}
    >
      {file.status === 'error' ? errorNode : originNode}
    </div>
  );
};

const AddEditImageProduct = ({ isVisible, item, onHide, saveProductImage, variantValues, validationErrors }) => {
  const [form] = Form.useForm();
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const token = getToken();
  const { saveProductMedias, productMedias, fetchProductMedias } = useProductMedias();
  const [fileList, setFileList] = useState([]);
  const [preview, setPreview] = useState({ previewVisible: false, previewImage: '', previewTitle: '' });

  useEffect(() => {
    if (isVisible) {
      /* form.setFieldsValue({
        variantValues: variantValues[0].id,
      }); */
      fetchProductMedias({ productId: item.id });
    }
  }, [isVisible]);

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        setIsLoading(true);
        const reqFileList = fileList.map((f, index) => {
          return {
            id: f.id,
            displayOrder: index + 1,
            variantValueId: values.variantValues,
            active: true,
            productId: item.id,
            fileId: f.fileId,
          };
        });
        const request = {
          productId: item.id,
          variantValueId: values.variantValues,
          updateList: reqFileList,
        };
        await saveProductMedias(request);
        setIsLoading(false);
      })
      .catch((errorInfo) => {
        console.log(errorInfo);
      });
  };

  useEffect(() => {
    const newList = productMedias.map((p, index) => {
      return {
        id: p.id,
        uid: index,
        url: `${p.uploadedFileUri}`,
        name: 'image.png',
        status: 'done',
        fileId: p.fileId,
      };
    });
    setFileList([...newList]);
  }, [productMedias]);

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex];
      setFileList(
        update(fileList, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        })
      );
    },
    [fileList]
  );

  const handleVariantValueChange = (value) => {
    fetchProductMedias({ productId: item.id, variantValueId: value });
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handlePreviewCancel = () => setPreview({ previewVisible: false });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreview({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
  };

  const onChange = ({ fileList: newFileList }) => {
    newFileList.forEach((f) => {
      if (!f.fileId) {
        f['fileId'] = f?.response?.id;
      }
    });
    setFileList(newFileList);
  };

  return (
    <>
      <Modal
        title={<FormatMessage id='products.addImage' />}
        visible={isVisible}
        width={900}
        confirmLoading={isLoading}
        onOk={() => handleOk()}
        okText={<FormatMessage id='save' />}
        onCancel={() => {
          onHide();
        }}
        cancelText={<FormatMessage id='cancel' />}
      >
        <Form form={form} name='control-ref' layout='horizontal' style={{ overflow: 'auto' }}>
          <SelectItem
            name='variantValues'
            label='variantValues.title'
            placeholder={intl.formatMessage({ id: 'variantValues.title' })}
            layout={{ ...formItemLayout }}
            validationErrors={validationErrors}
            onChange={handleVariantValueChange}
            allowClear={true}
          >
            {variantValues.map((c) => (
              <Option value={c.id} disabled={!c.active}>
                {c.title}
              </Option>
            ))}
          </SelectItem>
          <Form.Item
            name='imageId'
            label={<FormatMessage id='image' />}
            validateStatus={validationErrors?.imageId && 'error'}
            help={validationErrors?.imageId}
            getValueFromEvent={(e) => {
              return e && e.file?.response?.id;
            }}
            {...formItemLayout}
          >
            <DndProvider backend={HTML5Backend}>
              <Upload
                name='file'
                fileList={fileList}
                listType='picture-card'
                action={process.env.REACT_APP_API_URL + '/files'}
                onChange={onChange}
                headers={{
                  authorization: 'Bearer ' + token,
                  dir: 'productMedias',
                }}
                previewVisible={true}
                onPreview={handlePreview}
                itemRender={(originNode, file, currFileList) => (
                  <DragableUploadListItem originNode={originNode} file={file} fileList={currFileList} moveRow={moveRow} />
                )}
              >
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              </Upload>
            </DndProvider>
          </Form.Item>
        </Form>
      </Modal>
      <Modal visible={preview.previewVisible} title={preview.previewTitle} footer={null} onCancel={handlePreviewCancel}>
        <img alt='example' style={{ width: '100%' }} src={preview.previewImage} />
      </Modal>
    </>
  );
};

export default AddEditImageProduct;
