/* eslint-disable no-console */
import axios from "axios";
import store from "store";
import jwtDecode from "jwt-decode";

export const getToken = () => store.get("_t");
export const setToken = (token) => store.set("_t", token);
export const removeToken = () => store.remove("_t");

const defaultOptions = (isFile) => {
  const headers = {
    "Content-Type": isFile ? "multipart/form-data" : "application/json",
  };

  const token = getToken();

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return { headers };
};

const defaultInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const apiGet = async (url, options = {}) => {
  try {
    const results = await defaultInstance.get(url, {
      ...defaultOptions(false),
      ...options,
    });
    if (results?.data) {
      return results.data;
    }
    return false;
  } catch (ex) {
    console.log(ex);
    // @todo do something smart here cucu
    return false;
  }
};

export const apiPost = async (url, params, options = {}) => {
  try {
    const results = await defaultInstance.post(url, params, {
      ...defaultOptions(false),
      ...options,
    });
    if (results && results.data) return results.data;
    if (results.status >= 200 && results.status < 300) return true;
    return false;
  } catch (error) {
    console.error(error);
    if (error.response.status === 406) {
      const errorResp = {};
      if (error.response.data.errors) {
        const es = [];
        error.response.data.errors.forEach((e) => {
          const key = e.key.split(".")[e.key.split(".").length - 1];
          es.push({
            key: key,
            value: e.value,
          });
        });
        errorResp.errors = [...es];
      }
      return errorResp;
    }
    if (error.response.status === 400) {
      const errorResp = {
        error: ""
      };

      if (error.response.data.errors.length > 0) {
        errorResp.error = error.response.data.errors[0].value;
      }
      return errorResp;
    }
    // @todo do something smart here cucu
    return false;
  }
};

export const apiPut = async (url, params, options = {}) => {
  try {
    const results = await defaultInstance.put(url, params, {
      ...defaultOptions(false),
      ...options,
    });
    if (results && results.data) return results.data;
    if (results.status >= 200 && results.status < 300) return true;
    return false;
  } catch (error) {
    console.error(error);
    if (error.response.status === 406) {
      const errorResp = {};
      if (error.response.data.errors) {
        const es = [];
        error.response.data.errors.forEach((e) => {
          const key = e.key.split(".")[e.key.split(".").length - 1];
          es.push({
            key: key,
            value: e.value,
          });
        });
        errorResp.errors = [...es];
      }
      return errorResp;
    }
    if (error.response.status === 400) {
      const errorResp = {
        error: ""
      };

      if (error.response.data.errors.length > 0) {
        errorResp.error = error.response.data.errors[0].value;
      }
      return errorResp;
    }
    // @todo do something smart here cucu
    return false;
  }
};

export const apiDelete = async (url, options = {}) => {
  try {
    const results = await defaultInstance.delete(url, {
      ...defaultOptions(false),
      ...options,
    });
    if (results.status >= 200 && results.status < 300) return true;
    if (results.data) return results.data;
    return false;
  } catch (ex) {
    console.error(ex);
    // @todo do something smart here cucu
    return ex?.response.data || false;
  }
};

export const apiLogin = async (username, password) => {
  const params = new URLSearchParams();

  params.append("username", username);
  params.append("password", password);
  params.append("grant_type", "password");

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/oauth/token`,
      params,
      {
        headers: {
          Authorization: "Basic Z2l2bG9uOm5vdC1zby1zZWNyZXQ=", // the token is a variable which holds the token
        },
      }
    );
    if (response.data.access_token) {
      // setToken(response.data.access_token);
      // return response.data;

      const decodedToken = jwtDecode(response.data.access_token);

      if (decodedToken.authorities.includes('ROLE_ADMIN')) {
        setToken(response.data.access_token);
        return response.data;
      }

      throw new Error("invalid_grant");
    }

    return response;

  } catch (error) {
    if (Object.keys(error).length > 0) {
      const errors = error.response.data;
      if (errors.error) {
        return { errors: { form: `${errors.error}` } };
      } return errors;
    }

    return { errors: { form: `${error.message}` } };
  };
}

defaultInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response.status;
    if (status === 401) {
      removeToken();
      window.location = "/";
    }
    console.log(error);
    console.log(error.response);
    throw error;
  }
);
