import React, { useState } from 'react';
import { Layout, Menu } from 'antd';
import { CodeOutlined, ReconciliationOutlined } from '@ant-design/icons';
import { Link, useRouteMatch } from 'react-router-dom';
import routes from '../../routes';
import FormatMessage from '../../hocs/FormatMessage';
const { Sider } = Layout;
const { SubMenu } = Menu;

const GLeftMenu = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { path } = useRouteMatch();
  const leftMenuItems = routes.filter((r) => r.leftMenu);
  const currentPath = routes.filter((r) => r.leftMenu && r.path === path).map((r) => `${r.key}`);
  const currentParent = routes.filter((r) => r.key === parseInt(currentPath[0])).map((r) => r.parent);
  return (
    <Sider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
    >
      <div className='logo' style={{ textAlign: 'center' }}>
        <img src='../logo.svg' alt='GIVLON' />
      </div>
      <Menu
        theme='dark'
        mode='inline'
        defaultSelectedKeys={['1']}
        defaultOpenKeys={currentParent}
        selectedKeys={currentPath}
        inlineCollapsed
      >
        <SubMenu key='definitions' icon={<CodeOutlined />} title={<FormatMessage id='definitions' />}>
          {leftMenuItems
            .filter((r) => r.parent === 'definitions')
            .map((r) => (
              <Menu.Item key={r.key} icon={r.icon}>
                <Link to={r.path}>
                  <FormatMessage id={r.title} />
                </Link>
              </Menu.Item>
            ))}
        </SubMenu>
        <SubMenu key='catalogs' icon={<ReconciliationOutlined />} title={<FormatMessage id='catalogs' />}>
          {leftMenuItems
            .filter((r) => r.parent === 'catalogs')
            .map((r) => (
              <Menu.Item key={r.key} icon={r.icon}>
                <Link to={r.path}>
                  <FormatMessage id={r.title} />
                </Link>
              </Menu.Item>
            ))}
        </SubMenu>
        <SubMenu key='blog' icon={<ReconciliationOutlined />} title={<FormatMessage id='blog.menu.header' />}>
          {leftMenuItems
            .filter((r) => r.parent === 'blog')
            .map((r) => (
              <Menu.Item key={r.key} icon={r.icon}>
                <Link to={r.path}>
                  <FormatMessage id={r.title} />
                </Link>
              </Menu.Item>
            ))}
        </SubMenu>
        
        <SubMenu key='cms' icon={<ReconciliationOutlined />} title={<FormatMessage id='cms' />}>
          {leftMenuItems
            .filter((r) => r.parent === 'cms')
            .map((r) => (
              <Menu.Item key={r.key} icon={r.icon}>
                <Link to={r.path}>
                  <FormatMessage id={r.title} />
                </Link>
              </Menu.Item>
            ))}
        </SubMenu>
        <SubMenu key='transactions' icon={<ReconciliationOutlined />} title={<FormatMessage id='transactions.leftMenu.label' />}>
          {leftMenuItems
            .filter((r) => r.parent === 'transactions')
            .map((r) => (
              <Menu.Item key={r.key} icon={r.icon}>
                <Link to={r.path}>
                  <FormatMessage id={r.title} />
                </Link>
              </Menu.Item>
            ))}
        </SubMenu>
      </Menu>
    </Sider>
  );
};

export default GLeftMenu;
