const Locale = {
  username: 'Username',
  password: 'Password',
  login: 'Log In',
  forgotPassword: 'Forgot Password',
  addInterest: 'Add Interest',
  editInterest: 'Edit Interest',
  logout: 'Logout',
  settings: 'Settings',
  adminPanel: 'Admin Panel',
  edit: 'Edit',
  remove: 'Remove',
  add: 'Add',
  imageUri: 'Image',
  id: '#ID',
  title: 'Title',
  eventType: 'Event Type',
  displayOrder: 'Display Order',
  actions: 'Actions',
  delete: 'Delete',
  deleteThis: 'Are you sure you want to delete',
  norevert: 'You cannot revert this',
  deleted: 'It is deleted',
  saveSuccessMessage: 'Saved successfully.',
  editSuccessMessage: 'Updated successfully.',
  deleteSuccessMessage: 'Deleted successfully.',
  failMessage: 'Operation failed.',
  yes: 'Yes',
  cancel: 'Cancel',
  select: 'Select',
  save: 'Save',
  isActive: 'Is Active ?',
  dropFiles: 'Drop Image Here',
  image: 'Image',
  imageMsg: 'You can only upload jpg,png image',
  uploadError: 'Upload Error',
  saveError: 'Error during saving',
  saveOk: 'Saved successfully',
  dashboard: 'Dashboard',
  active: 'Active',
  passive: 'Passive',
  search: 'Search',
  featured: 'Featured',
  logo: 'Logo',
  description: 'Description',
  code: 'Code',
  oneTimeOnly: 'Only One Time',
  startsAt: 'Starts At',
  amount: 'Amount',
  minAmount: 'Min Amount',
  multipleUsage: 'Multiple Usage',
  percentage: 'Percentage',
  validUntil: 'Valid Date',
  starts: 'Start and Valid Date',
  valid: 'Start and Valid Date',
  name: 'Name',
  email: 'Email',
  identityNumber: 'Identity Number',
  mersisNo: 'Mersis No',
  phone: 'Phone',
  tradeRegisterNo: 'Trade Register Number',
  userId: 'User Id',
  dealerId: 'Dealer Id',
  website: 'Website',
  lastLogin: 'Last Login',
  visible: 'Visible',
  type: 'Type',
  extraInfo: 'Extra Info',
  showHome: 'Show Home Menu',
  showTopMenu: 'Show Top Menu',
  definitions: 'Definitions',
  okText: 'Yes',
  cancelText: 'No',
  taxOffice: 'Tax Office',
  city: 'City',
  county: 'County',
  district: 'District',
  postalCode: 'Postal Code',
  address: 'Address',
  checkInputField: 'Please check this field.',
  estimatedDelivery: "Estimated Delivery",
  shipmentAndReturn: "Shipment And Return",
  couponApplicable: "Coupon Applicable",
  leftMenu: {
    dashboard: 'Dashboard',
    interests: 'Interests',
    brands: 'Brands',
    dealers: 'Dealers',
    badges: 'Badges',
    tags: 'Tags',
    variants: 'Variants',
    attributes: 'Attributes',
    categories: 'Categories',
    checklistCategories: 'Check List',
    users: 'Users',
    coupons: 'Coupons',
    faqs: 'FAQ',
    products: 'Products',
    inventories: 'Inventories',
    cms: 'CMS',
    commissions: 'Commissions'
  },
  interests: {
    title: 'Interests',
    editTag: 'Edit Interest',
    addBrand: 'Add Interest',
    deleteConfirmMessage: 'Are you sure delete this Interest?',
    okText: 'Yes',
    cancelText: 'No',
  },
  brands: {
    title: 'Brands',
    editTag: 'Edit Brand',
    addBrand: 'Add Brand',
    deleteConfirmMessage: 'Are you sure delete this Brand?',
    okText: 'Yes',
    cancelText: 'No',
    estimatedDeliveryDays: 'Estimated Delivery Date'
  },
  dealers: {
    title: 'Dealers',
    editDealer: 'Edit Dealer',
    addDealer: 'Add Dealer',
    deleteConfirmMessage: 'Are you sure delete this Dealer?',
    okText: 'Yes',
    cancelText: 'No',
  },
  badges: {
    title: 'Badges',
    editTag: 'Edit Badge',
    addBadge: 'Add Badge',
    deleteConfirmMessage: 'Are you sure delete this Badge?',
    okText: 'Yes',
    cancelText: 'No',
  },
  tags: {
    title: 'Tags',
    editTag: 'Edit Tag',
    addTag: 'Add Tag',
    deleteConfirmMessage: 'Are you sure delete this Tag?',
    okText: 'Yes',
    cancelText: 'No',
  },
  variants: {
    title: 'Variants',
    editVariant: 'Edit Variant',
    addVariant: 'Add Variant',
    deleteConfirmMessage: 'Are you sure delete this Variant?',
    okText: 'Yes',
    cancelText: 'No',
  },
  variantValues: {
    title: 'Variant Values',
    editVariantValue: 'Edit Variant Value',
    addVariantValue: 'Add Variant Value',
    deleteConfirmMessage: 'Are you sure delete this Variant Value?',
    okText: 'Yes',
    cancelText: 'No',
  },
  attributes: {
    title: 'Attributes',
    name: 'Attribute',
    editAttribute: 'Edit Attribute',
    addAttribute: 'Add Attribute',
    deleteConfirmMessage: 'Are you sure delete this Attribute?',
    okText: 'Yes',
    cancelText: 'No',
  },
  attributeValues: {
    title: 'Attribute Values',
    editAttributeValue: 'Edit Attribute Value',
    addAttributeValue: 'Add Attribute Value',
    deleteConfirmMessage: 'Are you sure delete this Attribute Value?',
    okText: 'Yes',
    cancelText: 'No',
  },
  categories: {
    title: 'Categories',
    editCategoryValue: 'Edit Category',
    addCategoryValue: 'Add Category',
    deleteConfirmMessage: 'Are you sure delete this Category?',
    okText: 'Yes',
    cancelText: 'No',
    parentCategory: 'Parent Category',
  },
  checklistCategories: {
    title: 'Checklist Categories',
    editCategoryValue: 'Edit Checklist Category',
    addCategoryValue: 'Add Checklist Category',
    deleteConfirmMessage: 'Are you sure delete this Checklist Category?',
    okText: 'Yes',
    cancelText: 'No',
    parentCategory: 'Parent Category',
    category: 'Category',
    addCategoryPlaceHolder: 'Add Category',
  },

  inventories: {
    title: 'Inventories',
    editModalTitle: 'Edit Inventory',
    addModalTitle: 'Add Inventory',
    importModalTitle: 'Upload Inventories',
    updateModalTitle: 'Update Inventories',
    deleteConfirmMessage: 'Dou you want delete this inventory ?',
    upload: {
      csvTypeDescription: 'Select your type of csv file.',
      radio: {
        semicolon: 'Semicolon',
        comma: 'Comma',
      },
    },
    coupons: {
      title: 'Coupons',
      addButtonTitle: 'Add A New Coupon',
      editModalTitle: 'Edit Coupon',
      addModalTitle: 'Add A New Coupon',
      deleteConfirmMessage: 'Do you want delete this coupon ?',
    },
    NotEmpty: {
      user: {
        password: "User's password is required.",
      },
    },
  },
  userNotFound: 'User not found',
  invalid_grant: "Invalid user's credential",
  commissions: {
    title: "Commission Ratios", 
    addButtonTitle: "Add A New Commission Ratio",
    table: {
      rowActions: {
        hint: {
          edit: "Edit",
          delete: "Delete",
          add: "Add",
        }
      }
    }
  },
};

export default Locale;
