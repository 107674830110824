import { useState, useEffect } from 'react';
import { apiGet, apiDelete, apiPost, apiPut } from '../utils/api';
import _ from 'lodash';
import { successMessage, errorMessage, errorResponseMessage } from '../views/elements/ToastMessage';
export default function useBlogPosts(props) {
  const [posts, setPosts] = useState([]);
  
  const [validationErrors, setValidationErrors] = useState({});
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [postFailed, setPostFailed] = useState(false);
  const [isDeletingPost, setIsDeletingPost] = useState(false);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [postKeyword, setPostKeyword] = useState('');
  const [postModal, setPostModal] = useState({
    isVisible: false,
    type: 'add',
    item: false,
    category: {},
  });
  
  const [savingPost, setSavingPost] = useState(false);

  useEffect(() => {
    if (posts) {
      setFilteredPosts(posts.filter((item) => item.title?.toLowerCase().includes(postKeyword.toLowerCase()) || postKeyword.length === 0));
    }
  }, [postKeyword, posts]);

  const imgTags = [];

  async function getImagesFromContent(content) {
    const imgPattern = /<img[^>]*src="([^"]+)"[^>]*>/;
    const srcPattern = /<img[^>]+src="([^">]+)"/;
    const base64Pattern = /src=\"data:image\/([a-zA-Z]*);base64,([^\"]*)\"/;

    const matches = content.match(new RegExp(imgPattern, 'g')) || [];

    for (const match of matches) {
        
        if(match.match(base64Pattern)){
          const src = match.match(srcPattern)[1];
          const img = new Image();
          img.src = src;

          try {
              const res = await imageUpload(img);
              content = content.replace(match, '<img src="' + res.fullUrl + '"');
          } catch (error) {
              console.error("Image upload failed:", error);
          }
        }
    }

    return content;
  }


  

  const uploadImages = async (post) => {
    return await getImagesFromContent(post); 
  }

  const imageUpload = async (item) => {
    
        const base64 = item.getAttribute('src');
        
          return await fetch(base64)
          .then(res => res.blob())
          .then(blob => {
            const fd = new FormData();
            const file = new File([blob], "filename.jpeg");
            fd.append('file', file)
            return apiPost('/files',fd,{headers:{dir:'blog/pages/'}})
          });
   
    //
  }

  const savePost = async (post, type) => {
    setSavingPost(true);
    const postContent = await uploadImages(post.content);
    post.content = postContent;
    
    const response = type === 'new' ? await apiPost('/blog/pages', post) : await apiPut('/blog/pages', post);
    setSavingPost(false);
    if (response && !response.errors && !response.error) {
      setPostModal({
        ...postModal,
        item: type === 'edit' ? response.data : response,
        type: 'edit',
        isVisible: false,
      });
      type === 'new' ? successMessage('saveSuccessMessage') : successMessage('editSuccessMessage');
      setValidationErrors({});
      fetchBlogPosts();
      
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error)
    } else {
      setValidationErrors({});
      errorMessage('failMessage');
    }
  };

  const deletePost = async (id, parentId) => {
    setIsDeletingPost(true);
    const response = await apiDelete('/blog/pages/' + id);
    setIsDeletingPost(false);
    if (response) {
      successMessage('blog.posts.delete.success.message');
      if (parentId) {
       
      } else {
        fetchBlogPosts();
      }
    } else {
      errorMessage('failMessage');
    }
  };

  const fetchBlogPosts = async (groupId='') => {
    setLoadingPosts(true);
    //&isShowTop=${isShowTop}&isShowHome=${isShowHome}&isActive=${isActive}
    let url = '/blog/pages/';
    if(groupId!='')url +='filter?groupId='+groupId;
    

    const response = await apiGet(
      url
    );
    if (response === false) {
      setPostFailed(true);
    } else {
      if (!_.isEmpty(response)) {
        const sorted = response.content.sort((a, b) => (a.id < b.id ? 1 : b.id < a.id ? -1 : 0));
        setPosts(sorted);
      }
    }
    setLoadingPosts(false);
    return response;
  };






  return {
    posts,
    fetchBlogPosts,
    deletePost,
    loadingPosts,
    isDeletingPost,
    setIsDeletingPost,
    postFailed,
    postModal,
    setPostModal,
    filteredPosts,
    postKeyword,
    setPostKeyword,
    savePost,
    savingPost,
    validationErrors,
    setValidationErrors    
  };
}
