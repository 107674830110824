import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Input, Space, Button, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import intl from 'react-intl-universal';
import { FormatMessage } from '../../../hocs';
import GLayout from '../../gLayout';
import useCategories from '../../../hooks/useCategories';
import AddEditCategory from './_add';
import styles from './categories.module.css';
import { BasicColumn, BooleanColumn, IdColumn, ImageColumn } from '../../../components';

const { confirm } = Modal;
const { Search } = Input;

const Categories = () => {
  const {
    filteredCategories,
    categoryKeyword,
    setCategoryKeyword,
    fetchCategories,
    saveCategory,
    loadingCategories,
    deleteCategory,
    setCategoryModal,
    categoryModal,
    isDeletingCategory,
    categories,
    fetchInnerCategories,
    nestedData,
    isLoading,
    validationErrors,
    setValidationErrors,
    fetchSameDirectoryParentCategories,
    parentCategories,
    setParentCategories,
  } = useCategories();

  useEffect(() => {
    fetchCategories();
  }, []);

  const showDeleteConfirm = (record) => {
    confirm({
      title: <FormatMessage id='categories.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingCategory,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteCategory(record.id, record.parentId);
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const onEditClick = async (record) => {
    if(record.parentId) {
      
      const sameDirectoryParentCategories = await fetchSameDirectoryParentCategories(record.parentId);

      if(!sameDirectoryParentCategories) {
        setParentCategories(categories);
      }

    } else {
      setParentCategories(categories);
    }

    setCategoryModal({
      type: 'edit',
      isVisible: true,
      item: record,
    });
  };

  console.log('parentCategories',parentCategories,categories);

  const columns = ({ level = 0 }) => [
    IdColumn(),
    BasicColumn({ name: 'name' }),
    BasicColumn({ name: 'title' }),
    BasicColumn({ name: 'displayOrder' }),
    ImageColumn({ name: 'homeImageUri', label: 'image' }),
    BooleanColumn(),
    BooleanColumn({ name: 'showHome' }),
    BooleanColumn({ name: 'showTopMenu' }),
    {
      title: <FormatMessage id='actions' />,
      key: 'action',
      width: 180,
      render: (text, record) => (
        <Space size='middle'>
          <Tooltip title={<FormatMessage id='edit' />}>
            <Button
              icon={<EditOutlined />}
              onClick={() => onEditClick(record)}
            />
          </Tooltip>

          <Tooltip title={<FormatMessage id='delete' />}>
            <Button danger onClick={() => showDeleteConfirm(record)} icon={<DeleteOutlined />}></Button>
          </Tooltip>
          {level < 1 && (
            <Tooltip title={<FormatMessage id='categories.addModalTitle' />}>
              <Button
                type='success'
                ghost
                onClick={() =>
                  setCategoryModal({
                    isVisible: true,
                    type: 'new',
                    item: {},
                    category: record,
                  })
                }
                icon={<PlusOutlined />}
              ></Button>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    const level = record.parentId ? 1 : 0;

    const data = nestedData[record.id];
    return (
      <Table
        bordered
        rowKey={(record) => record.id}
        showHeader={false}
        loading={isLoading[record.id]}
        columns={columns({ level: level })}
        pagination={false}
        dataSource={data}
        rowClassName={(record, index) => {
          if (level < 1) {
            return styles.rowGrey;
          }
        }}
        expandable={{ expandedRowRender }}
        onExpand={level < 1 && handleExpand}
      />
    );
  };

  const handleExpand = (expanded, record) => {
    fetchInnerCategories({ parentId: record.id });
  };

  return (
    <GLayout>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setCategoryKeyword(e.target.value)}
              value={categoryKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              disabled={loadingCategories}
              onClick={() =>
                setCategoryModal({
                  isVisible: true,
                  type: 'new',
                  item: {},
                })
              }
              style={{ float: 'right' }}
            >
              <FormatMessage id='categories.addModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        bordered
        rowKey={(record) => record.id}
        columns={columns({ level: 0 })}
        dataSource={filteredCategories}
        loading={loadingCategories}
        scroll={{ x: 1500 }}
        expandable={{ expandedRowRender }}
        onExpand={handleExpand}
        pagination={filteredCategories.length > 10 ? true : false}
        sticky
      />
      {categoryModal.isVisible && (
        <AddEditCategory
          onHide={() => {
            setValidationErrors({});
            setCategoryModal({ ...categoryModal, isVisible: false });
          }}
          {...categoryModal}
          saveCategory={saveCategory}
          parentCategories={parentCategories}
          validationErrors={validationErrors}
        />
      )}
    </GLayout>
  );
};

export default Categories;
