import React from 'react';
import Badges from './views/dashboard/badges';
import Dealers from './views/dashboard/dealers';
import Tags from './views/dashboard/tags';
import Variants from './views/dashboard/variants';
import Attributes from './views/dashboard/attributes';
import Categories from './views/dashboard/categories';
import BlogCategories from './views/blog/categories';
import BlogPosts from './views/blog/posts';

import ChecklistCategories from './views/dashboard/checklist-categories';
import {
  UserOutlined,
  ApiOutlined,
  TagsOutlined,
  AimOutlined,
  DeploymentUnitOutlined,
  PushpinOutlined,
  ProjectOutlined,
  AppstoreOutlined,
  AlignLeftOutlined,
  ApartmentOutlined,
  BuildOutlined,
  GatewayOutlined,
  CompressOutlined,
  CalendarOutlined,
  PercentageOutlined,
  CommentOutlined,
  BranchesOutlined
} from '@ant-design/icons';
import Users from './views/dashboard/users';
import Products from './views/dashboard/products';
import Inventories from './views/dashboard/inventories';
import Faqs from './views/dashboard/faqs';
import Cms from './views/dashboard/cms-pages';
import CmsPages from './views/dashboard/cms-pages';
import Coupons from './views/dashboard/coupons';
import Reviews from './views/dashboard/reviews';
import Commissions from './views/dashboard/commissions';

const Dashboard = React.lazy(() => import('./views/dashboard'));
const Login = React.lazy(() => import('./views/login'));
const Interests1 = React.lazy(() => import('./views/dashboard/interests/index.js'));
const Brands = React.lazy(() => import('./views/dashboard/brands/index.js'));

const routes = [
  {
    path: '/',
    component: Login,
    isPrivate: false,
    exact: true,
  },
  {
  path: '/blog/categories',
  component: BlogCategories,
  title: 'blog.menu.categories',
  isPrivate: true,
  exact: true,
  leftMenu: true,
  icon: <ApiOutlined />,
  key: 55,
  parent: 'blog',
},
{
  path: '/blog/posts',
  component: BlogPosts,
  title: 'blog.menu.posts',
  isPrivate: true,
  exact: true,
  leftMenu: true,
  icon: <ApiOutlined />,
  key: 56,
  parent: 'blog',
},

  {
    path: '/dashboard',
    component: Dashboard,
    isPrivate: true,
    exact: true,
  },
  {
    path: '/dashboard/interests',
    component: Interests1,
    title: 'leftMenu.interests',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <ApiOutlined />,
    key: 1,
    parent: 'definitions',
  },
  {
    path: '/dashboard/brands',
    component: Brands,
    title: 'leftMenu.brands',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <AppstoreOutlined />,
    key: 2,
    parent: 'definitions',
  },
  {
    path: '/dashboard/dealers',
    component: Dealers,
    title: 'leftMenu.dealers',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <ProjectOutlined />,
    key: 3,
    parent: 'definitions',
  },
  {
    path: '/dashboard/badges',
    component: Badges,
    title: 'leftMenu.badges',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <PushpinOutlined />,
    key: 4,
    parent: 'definitions',
  },
  {
    path: '/dashboard/tags',
    component: Tags,
    title: 'leftMenu.tags',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <TagsOutlined />,
    key: 5,
    parent: 'definitions',
  },
  {
    path: '/dashboard/variants',
    component: Variants,
    title: 'leftMenu.variants',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <DeploymentUnitOutlined />,
    key: 6,
    parent: 'definitions',
  },
  {
    path: '/dashboard/attributes',
    component: Attributes,
    title: 'leftMenu.attributes',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <GatewayOutlined />,
    key: 7,
    parent: 'definitions',
  },
  {
    path: '/dashboard/categories',
    component: Categories,
    title: 'leftMenu.categories',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <ApartmentOutlined />,
    key: 8,
    parent: 'definitions',
  },
  {
    path: '/dashboard/checklist-categories',
    component: ChecklistCategories,
    title: 'leftMenu.checklistCategories',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <AlignLeftOutlined />,
    key: 9,
    parent: 'definitions',
  },
  {
    path: '/dashboard/users',
    component: Users,
    title: 'leftMenu.users',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <UserOutlined />,
    key: 10,
    parent: 'definitions',
  },
  {
    path: '/dashboard/products',
    component: Products,
    title: 'leftMenu.products',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <BuildOutlined />,
    key: 11,
    parent: 'catalogs',
  },
  {
    path: '/dashboard/inventories',
    component: Inventories,
    title: 'leftMenu.inventories',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <CompressOutlined />,
    key: 12,
    parent: 'catalogs',
  },
  {
    path: '/dashboard/faqs',
    component: Faqs,
    title: 'leftMenu.faqs',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <UserOutlined />,
    key: 14,
    parent: 'cms',
  },
  {
    path: '/dashboard/cms',
    component: CmsPages,
    title: 'leftMenu.cms',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <UserOutlined />,
    key: 15,
    parent: 'cms',
  },
  {
    path: '/dashboard/coupons',
    component: Coupons,
    title: 'leftMenu.coupons',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <PercentageOutlined />,
    key: 16,
    parent: 'definitions',
  },
  {
    path: '/dashboard/reviews',
    component: Reviews,
    title: 'leftMenu.reviews',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <CommentOutlined />,
    key: 17,
    parent: 'transactions',
  },
  {
    path: '/dashboard/commissions',
    component: Commissions,
    title: 'leftMenu.commissions',
    isPrivate: true,
    exact: true,
    leftMenu: true,
    icon: <BranchesOutlined />,
    key: 18,
    parent: 'definitions',
  },
];

export default routes;
