import React, { useState } from 'react';
import { Form, Input, Tooltip } from 'antd';
import { FormatMessage } from '../../../hocs';
import PropTypes from 'prop-types';
import styles from './password-input.module.css';
import { ExclamationCircleOutlined, EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';
import { passwordValidations } from '../../../helpers/validations';

const PasswordInput = ({ name = '', label = '', layout = null, isRequired = false, type = 'text', validationErrors = null, form, hasValidationError, getInputValue }) => {

    const [validations, setValidations] = useState({ ...passwordValidations });
    const [inputValue, setInputValue] = useState('');

    const validateInput = (value) => {
        if (value.length === 0) {
            Object.keys(validations).forEach(key => {
                validations[key].status = false;
                return validations[key];
            });

            return;
        };
        Object.keys(validations).forEach((key) => {
            validations[key].status = validations[key].rule(value) === validations[key].value;
            setValidations(validations);
        });

        if (Object.keys(validations).every(key => validations[key].status)) {
            hasValidationError(false);
        } else {
            hasValidationError(true)
        }
    }

    const onInputChange = (event) => {
        const { value } = event.target;
        validateInput(value);
        setInputValue(value);
        getInputValue(value);
        form.setFieldsValue({ [name]: value });
    }


    const tooltipText = React.useCallback(() => {
        return Object.keys(validations).map((key, index) => {
            return (
                <li key={index}>
                    <span className={!(validations[key].status) ? styles.passwordTooltipActiveContent : ""}>{validations[key].text}</span>
                </li>
            )
        })
    }, [inputValue]);

    const RenderIcons = visible => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 10 }}>
                <div>
                    {visible ? <EyeTwoTone style={{ marginLeft: 10 }} /> : <EyeInvisibleOutlined />}
                </div>
                <div>
                    <Tooltip placement="right" title={tooltipText()}>
                        <ExclamationCircleOutlined />
                    </Tooltip>
                </div>
            </div>
        )
    }


    return (
        <Form.Item
            name={name}
            rules={[{ required: isRequired }]}
            label={<FormatMessage id={label ? label : name} />}
            validateStatus={validationErrors?.[name] && 'error'}
            help={validationErrors?.[name] ? FormatMessage({ id: validationErrors?.[name] }) ?? validationErrors?.[name] : null}
            {...layout}
        >
            <div className={styles.inputContainer}>
                <Input.Password
                    type={type}
                    onChange={onInputChange}
                    iconRender={RenderIcons}
                />


            </div>
        </Form.Item>
    );
};

PasswordInput.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    layout: PropTypes.object.isRequired,
    isRequired: PropTypes.bool,
    type: PropTypes.string,
    validationErrors: PropTypes.object,
    getInputValue: PropTypes.func
};

export default PasswordInput;
