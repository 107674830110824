import React, { useEffect } from 'react';
import { Layout, Menu, Avatar } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import store from 'store';
import { setUser } from '../../actions/setUser';
import { setLocale } from '../../actions/setLocale';
import { apiGet } from '../../utils/api';
import { config } from '../../utils/config';
const { SubMenu } = Menu;
const { Header } = Layout;

const GHeader = (props) => {
  const user = useSelector((state) => state.auth.user);
  const currentLocale = useSelector((state) => state.locale);
  const history = useHistory();
  const dispatch = useDispatch();

  const languages = config.i18n.languages;
  const currentLanguage = languages.find((item) => item.value === currentLocale);

  const logout = () => {
    store.remove('_t');
    dispatch(setUser(false));
    history.push('/');
  };

  useEffect(() => {
    const fetchUser = async () => {
      const user = await apiGet('/api/about/principal');
      if (user && user.userAuthentication) {
        dispatch(setUser(user.userAuthentication));
      }
    };

    if (!user) {
      fetchUser();
    }
  }, []);

  const handleClickMenu = (e) => {
    e.key === 'SignOut' && logout();
  };

  return (
    <Header className='site-layout-background'>
      <Menu
        key='user'
        mode='horizontal'
        defaultSelectedKeys={['1']}
        onClick={(e) => handleClickMenu(e)}
        style={{ float: 'right', height: 65 }}
        {...props}
      >
        <SubMenu
          title={
            <Menu.Item>
              <span style={{ color: '#999', marginRight: 4 }}>Hi, {user.name}</span>
              <Avatar style={{ marginLeft: 8 }} />
            </Menu.Item>
          }
        >
          <Menu.Item key='SignOut'>Sign out</Menu.Item>
        </SubMenu>
      </Menu>
      {/*<Menu
        key="language"
        selectedKeys={[currentLanguage]}
        onClick={(data) => {
          dispatch(setLocale(data.key));
        }}
        mode="horizontal"
        style={{ float: "right", height: 65 }}
      >
        <SubMenu title={<Avatar size="small" src={currentLanguage.flag} />}>
          {languages.map((item) => (
            <Menu.Item key={item.value}>
              <Avatar size="small" style={{ marginRight: 8 }} src={item.flag} />
              {item.title}
            </Menu.Item>
          ))}
        </SubMenu>
      </Menu>*/}
    </Header>
  );
};

export default GHeader;
