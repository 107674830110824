export const passwordValidations = {
    minCharacterCount: {
        status: false,
        text: '- En az 6 karakter içermelidir.',
        value: true,
        rule: (check) => check.length >= 6,
    },
    maxCharacterCount: {
        status: false,
        text: '- En fazla 25 karakter içermelidir.',
        value: true,
        rule: (check) => check.length > 0 && check.length <= 25,
    },
    hasUppercase: {
        status: false,
        text: '- En az bir büyük harf içermelidir.',
        value: true,
        rule: (check) => /[A-Z]/.test(check),
    },
    hasLowercase: {
        status: false,
        text: '- En az bir küçük harf içermelidir.',
        value: true,
        rule: (check) => /[a-z]/.test(check),
    },
    hasNumber: {
        status: false,
        text: '- En az bir rakam içermelidir.',
        value: true,
        rule: (check) => /[0-9]/.test(check),
    },
    // hasSpecialCharacter: {
    //     status: false,
    //     text: '- En az bir özel karakter içermelidir.',
    //     value: true,
    //     rule: (check) => /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(check),
    // },

    hasNotConsecutiveCharacter: {
        status: false,
        text: '- Aynı karakterleri tekrarlanamaz.',
        value: false,
        rule: (check) => /([a-zA-Z0-9])\1\1/.test(check),
    },

    hasNotOrderedCharacter: {
        status: false,
        text: '- Tekrarlanan sayı veya karakter içermelidir. (Örn: 123,abc)',
        value: false,
        rule: (check) => /(abc|bcd|cde|def|efg|fgh|ghi|hij|ijk|jkl|klm|lmn|mno|nop|opq|pqr|qrs|rst|stu|tuv|uvw|vwx|wxy|xyz|012|123|234|345|456|567|678|789)+/.test(check),
    }

}