export const SET_USER = 'SET_USER';
export const SET_USER_TYPE = 'SET_USER_TYPE';
export const SET_LOCALE = 'SET_LOCALE';
export const SET_TOKEN = 'SET_TOKEN';
export const GET_INTERESTS = 'INTERESTS/GET_INTERESTS';
export const LOADING_INTERESTS = 'INTERESTS/INTERESTS_LOADING';
export const LOADED_INTERESTS = 'INTERESTS/INTERESTS_LOADED';
export const EDIT_INTEREST = 'INTERESTS/EDIT_INTERESTS';

export const OPEN_ADD_EDIT_INTEREST_MODAL = 'INTERESTS/OPEN_ADD_EDIT_INTEREST_MODAL';
export const CLOSE_ADD_EDIT_INTEREST_MODAL = 'INTERESTS/CLOSE_ADD_EDIT_INTEREST_MODAL';

export const FETCH_INTERESTS_BEGIN = 'INTERESTS/FETCH_INTERESTS_BEGIN';
export const FETCH_INTERESTS_SUCCESS = 'INTERESTS/FETCH_INTERESTS_SUCCESS';
export const FETCH_INTERESTS_FAILURE = 'INTERESTS/FETCH_INTERESTS_FAILURE';


export const SAVE_INTEREST_BEGIN = 'INTERESTS/SAVE_INTEREST_BEGIN';
export const SAVE_INTEREST_SUCCESS = 'INTERESTS/SAVE_INTEREST_SUCCESS';
export const SAVE_INTEREST_FAILURE = 'INTERESTS/SAVE_INTEREST_FAILURE';

export const DELETE_INTEREST_BEGIN = 'INTERESTS/DELETE_INTEREST_BEGIN';
export const DELETE_INTEREST_SUCCESS = 'INTERESTS/DELETE_INTEREST_SUCCESS';
export const DELETE_INTEREST_FAILURE = 'INTERESTS/DELETE_INTEREST_FAILURE';

