import { useEffect, useState } from "react";
import { apiDelete, apiGet, apiPost, apiPut } from "../utils/api";
import { errorMessage } from "../views/elements/ToastMessage";

export const COMMISSIONS_MODAL_STATES = {
    ADD: "add",
    EDIT: "edit",
}

export const COMMISSION_RECORD_TYPE = {
    DEALER: 'DEALER',
    BRAND: 'BRAND',
    PRODUCT: 'PRODUCT'
}

export const LOADING_STATES = {
    NOTR: 'NOTR',
    LOADING: 'LOADING',
    LOADED: 'LOADED',
    ERROR: 'ERROR'
}

export const COMMISSIONS_MODAL_VALUE_SHOW_TYPE = {
    READ_ONLY: 'READ_ONLY',
    EDITABLE: 'EDITABLE'
}

export default function useCommissions() {
    const [fetchLoading, setFetchLoading] = useState(true);
    const [commissions, setCommissions] = useState(null);

    const [filteredCommissions, setFilteredCommissions] = useState(null);
    const [filterTerm, setFilterTerm] = useState("");

    const [brandLoading, setBrandLoading] = useState(true);
    const [brandData, setBrandData] = useState({});

    const [brandProductsLoading, setBrandProductsLoading] = useState(true);
    const [brandProductsData, setBrandProductsData] = useState({});

    const [productCommissionsLoading, setProductCommissionsLoading] = useState(true);
    const [productCommissions, setProductCommissions] = useState(null);

    const [updateCommissionsLoading, setUpdateCommissionsLoading] = useState(LOADING_STATES.NOTR);
    const [updateCommissionsData, setUpdateCommissionsData] = useState(null);

    const [commissionModalProps, setCommissionModalProps] = useState({
        isVisible: false,
        type: COMMISSIONS_MODAL_STATES.ADD,
        item: false,
        itemType: COMMISSION_RECORD_TYPE.DEALER,
        selectData: [],
        showType: COMMISSIONS_MODAL_VALUE_SHOW_TYPE.EDITABLE,
    });

    const [filteredProducts, setFilteredProducts] = useState([])
    const [filteredProductsLoading, setFilteredProductsLoading] = useState(false)

    useEffect(() => {
        (async function () {
            const response = await fetchCommissions({});
            setCommissions(response);
            setFetchLoading(false);
        })()
    }, []);

    useEffect(() => {
        if ((filterTerm === "" || !filterTerm) && filteredCommissions !== null) {
            fetchCommissions({});
        }
    }, [filterTerm]);

    /**
     * Komisyon tutarları bu fonksiyon üzerinden alınır.
     */
    const fetchCommissions = async ({ page = 0, pageSize = 25 }) => {
        const url = `/commission-ratios/filter?`;
        const response = await apiGet(url);

        setCommissions(response.sort((a, b) => a.id - b.id));
        setFetchLoading(false);

        return response;
    }

    /**
     * Satıcının markalarını getirir
     */
    const fetchDealerBrands = async ({ dealerId = null, isActive = true }) => {
        let url = `/commission-ratios/filter?dealerId=${dealerId}`;

        const response = await apiGet(url);

        setBrandData({ ...brandData, [dealerId]: response });
        setBrandLoading(false);

        return response;
    }

    /**
     * Satıcının markasına göre ürünleri getirir
     * 
     * @param {*} param0 
     * @returns 
     */
    const fetchBrandProducts = async ({ dealerId, brandId }) => {
        const url = `/commission-ratios/filter?dealerId=${dealerId}&brandId=${brandId}`;

        const response = await apiGet(url);
        setBrandProductsLoading(false);
        setBrandProductsData({ ...brandProductsData, [brandId]: response });

        return response;
    }

    /**
     * Ürün komisyonları bu fonksiyon üzerinden alınır.
     * 
     * @param {{productId: number}} productId ürün id'si 
     * @returns 
     */
    const fetchProductCommissions = async ({ productId }) => {
        const url = `/commission-ratios/products/${productId}`;
        const response = await apiGet(url);

        setProductCommissions(response);
        setProductCommissionsLoading(false);

        return response;
    }

    /**
     * Komisyon tutarlarının güncellenmesi için kullanılır.
     * 
     * @param {{active: boolean, dealerId: number, brandId: number, productId: number, ratio: number}} updateDTO 
     */
    const updateCommissions = async (updateDTO) => {
        setUpdateCommissionsLoading(LOADING_STATES.LOADING);
        const url = `/commission-ratios`;
        const response = await apiPut(url, { ...updateDTO });

        setUpdateCommissionsData(response);
        setUpdateCommissionsLoading(LOADING_STATES[response.error ? 'ERROR' : 'LOADED']);
        return response;
    }

    /**
     * Komisyon tutarlarının eklenmesi için kullanılır.
     * 
     * @param {{active: boolean, dealerId: number, brandId: number, productId: number, ratio: number}} addDTO 
     */
    const addCommissions = async (addDTO) => {
        const url = `/commission-ratios`;
        const response = await apiPost(url, { ...addDTO });

        setUpdateCommissionsData(response);
        setUpdateCommissionsLoading(false);

        return response;
    }

    /**
     * Tüm komisyon oranlarını dönen fonksiyon.
     * 
     * @param {'DEALER' | 'BRAND' | 'PRODUCT' | 'ALL'} returnType
     * @returns {object} Tüm komisyon oranlarını döner.
     */
    const getCommissions = async (returnType = 'ALL') => {
        const response = await apiGet('/commission-ratios');

        const typeMap = {
            DEALER: 'dealerId',
            BRAND: 'brandId',
            PRODUCT: 'productId',
        }
        setFilteredProductsLoading(false)
        if (typeMap[returnType]) {
            return response.content.filter(content => content[typeMap[returnType]] !== 0);
        }

        return response;
    }

    const getProductComission = async (productId) => {
        const response = await apiGet(`/commission-ratios/products/${productId}`);

        return response;
    }

    /**
     * 
     * @param {{delaerName: string}} filterDTO 
     */
    const filterCommissions = async (filterDTO) => {
        setFilteredProductsLoading(true);
        const getProductCommissions = await getCommissions('PRODUCT');
        setFilteredProductsLoading(true);
        setFilteredProducts(getProductCommissions);
        setFilterTerm(filterDTO.delaerName);
        setCommissions(prev => {
            if (commissions) {
                return commissions.filter(commission => {
                    if (commission.dealerName?.toLowerCase().includes(filterDTO.delaerName.toLowerCase()));
                    return commission;
                });
            }
        });
    }

    /**
     * Komisyon modalı açıldığında çalışan fonksiyon.
     * 
     * @param {Object} commissionModalPropsDTO 
     */
    const onCommissionModalOpen = ({
        isVisible = false,
        item = false,
        type = COMMISSIONS_MODAL_STATES.ADD,
        itemType = COMMISSION_RECORD_TYPE.DEALER,
        selectData = [],
        showType = COMMISSIONS_MODAL_VALUE_SHOW_TYPE.EDITABLE 
    }) => {
        debugger;
        setCommissionModalProps({
            isVisible,
            item,
            type,
            itemType,
            selectData,
            showType
        })
    }

    const deleteCommissions = async (id) => {
        const url = `/commission-ratios/${id}`;
        const response = await apiDelete(url);

        if (response.errors) {
            errorMessage(response.errors?.[0].value)
        }

        return response;
    }

    return {
        commissions,
        setCommissions,
        fetchCommissions,
        fetchLoading,
        brandLoading,
        fetchDealerBrands,
        brandData,
        fetchProductCommissions,
        productCommissions,
        productCommissionsLoading,
        updateCommissionsLoading,
        updateCommissionsData,
        addCommissions,
        updateCommissions,
        fetchBrandProducts,
        brandProductsLoading,
        brandProductsData,
        filterCommissions,
        filteredCommissions,
        onCommissionModalOpen,
        commissionModalProps,
        setBrandData,
        setBrandProductsData,
        deleteCommissions,
        getCommissions,
        filteredProducts,
        filteredProductsLoading,
        setFilteredProductsLoading,
        getProductComission
    }
}