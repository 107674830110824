import _ from "lodash";
import { useState, useEffect } from "react";
import { apiGet, apiDelete, apiPost, apiPut } from "../utils/api";
import { RESPONSE_STATS } from "../utils/constants";
import { successMessage, errorMessage, errorResponseMessage } from "../views/elements/ToastMessage";

export default function useVariantValues(props) {
  const [variantValues, setVariantValues] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [loadingVariantValues, setLoadingVariantValues] = useState(false);
  const [variantValuesFailed, setVariantValuesFailed] = useState(false);
  const [isDeletingVariantValue, setIsDeletingVariantValue] = useState(false);
  const [filteredVariantValues, setFilteredVariantValues] = useState([]);
  const [variantValueKeyword, setVariantValueKeyword] = useState("");
  const [pagination, setPagination] = useState({});
  const [variantValueModal, setVariantValueModal] = useState({
    isVisible: false,
    type: "add",
    item: false,
  });

  const [savingVariantValue, setSavingVariantValue] = useState(false);
  const [saveVariantValueResult, setSaveVariantValueResult] = useState(
    RESPONSE_STATS.NOTR
  );
  const [editVariantValueResult, setEditVariantValueResult] = useState(
    RESPONSE_STATS.NOTR
  );
  const [deleteVariantValueResult, setDeleteVariantValueResult] = useState(
    RESPONSE_STATS.NOTR
  );

  useEffect(() => {
    console.log(variantValues);
    if (variantValues) {
      setFilteredVariantValues(
        variantValues.filter(
          (item) =>
            item.title?.toLowerCase().includes(variantValueKeyword.toLowerCase()) ||
            variantValueKeyword.length === 0
        )
      );
    }
  }, [variantValueKeyword, variantValues]);

  const saveVariantValue = async (variantId, variantValue, type) => {
    setSavingVariantValue(true);
    const response =
      type === "new"
        ? await apiPost(`/variants/${variantId}/values`, variantValue)
        : await apiPut(`/variants/${variantId}/values`, variantValue);
    setSavingVariantValue(false);
    if (response && !response.errors && !response.error) {
      setVariantValueModal({
        ...variantValueModal,
        item: type === "edit" ? response.data : response,
        type: "edit",
        isVisible: false,
      });
      type === "new"
        ? setSaveVariantValueResult(RESPONSE_STATS.SUCCESS)
        : setEditVariantValueResult(RESPONSE_STATS.SUCCESS);
      setValidationErrors({});
      fetchVariantValues(variantId);
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error)
    } else {
      setValidationErrors({});
      type === "new"
        ? setSaveVariantValueResult(RESPONSE_STATS.FAIL)
        : setEditVariantValueResult(RESPONSE_STATS.FAIL);
    }
  };

  const deleteVariantValue = async (variantId, id) => {
    setIsDeletingVariantValue(true);
    const response = await apiDelete(`/variants/${variantId}/values/${id}`);
    setIsDeletingVariantValue(false);
    if (response) {
      setDeleteVariantValueResult(RESPONSE_STATS.SUCCESS);
      fetchVariantValues(variantId);
    } else {
      setDeleteVariantValueResult(RESPONSE_STATS.FAIL);
    }
  };

  const fetchVariantValues = async (variantId) => {
    setLoadingVariantValues(true);

    const response = await apiGet(`/variants/${variantId}/values`);
    if (response === false) {
      setVariantValuesFailed(true);
    } else {
      if (!_.isEmpty(response)) {
        const sorted = response.sort((a, b) =>
          a.id < b.id ? 1 : b.id < a.id ? -1 : 0
        );
        setVariantValues(sorted);
        if (sorted.length < 11) {
          setPagination(false);
        } else {
          setPagination(true);
        }
      } else {
        setVariantValues([]);
      }
    }
    setLoadingVariantValues(false);
  };

  useEffect(() => {
    if (saveVariantValueResult === RESPONSE_STATS.SUCCESS) {
      successMessage("saveSuccessMessage");
    } else if (saveVariantValueResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setSaveVariantValueResult(RESPONSE_STATS.NOTR);
  }, [saveVariantValueResult]);

  useEffect(() => {
    if (editVariantValueResult === RESPONSE_STATS.SUCCESS) {
      successMessage("editSuccessMessage");
    } else if (editVariantValueResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setEditVariantValueResult(RESPONSE_STATS.NOTR);
  }, [editVariantValueResult]);

  useEffect(() => {
    if (deleteVariantValueResult === RESPONSE_STATS.SUCCESS) {
      successMessage("deleteSuccessMessage");
    } else if (deleteVariantValueResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setDeleteVariantValueResult(RESPONSE_STATS.NOTR);
  }, [deleteVariantValueResult]);

  return {
    variantValues,
    loadingVariantValues,
    fetchVariantValues,
    setVariantValueModal,
    isDeletingVariantValue,
    deleteVariantValue,
    variantValueKeyword,
    setVariantValueKeyword,
    filteredVariantValues,
    saveVariantValue,
    variantValueModal,
    pagination,
    validationErrors,
    setValidationErrors,
  };
}
