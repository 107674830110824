import { Button, Space, Row, Col, Input, Modal, Table, Tooltip } from 'antd';
import React, { useEffect } from 'react';
import { FormatMessage } from '../../../../hocs';
import intl from 'react-intl-universal';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ActionsColumn, BasicColumn, BooleanColumn, IdColumn, ImageColumn } from '../../../../components';
import useCmsContents from '../../../../hooks/useCmsContents';
import AddEditCmsContent from './_add';
const { confirm } = Modal;
const { Search } = Input;

const CmsContents = ({ cmsPage }) => {
  const {
    cmsContents,
    loadingCmsContents,
    fetchCmsContents,
    setCmsContentModal,
    isDeletingCmsContent,
    deleteCmsContent,
    setCmsContentKeyword,
    cmsContentKeyword,
    filteredCmsContents,
    cmsContentModal,
    saveCmsContent,
    pagination,
    validationErrors,
    setValidationErrors,
  } = useCmsContents();

  useEffect(() => {
    fetchCmsContents({ cmsPageId: cmsPage.id });
  }, [cmsPage]);

  const showDeleteConfirm = (id) => {
    confirm({
      title: <FormatMessage id='cmsContents.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingCmsContent,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteCmsContent({ id: id, cmsPageId: cmsPage.id });
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const columns = [
    IdColumn(),
    BasicColumn({ name: 'title' }),
    BasicColumn({ name: 'content' }),
    BasicColumn({ name: 'link' }),
    ImageColumn({ name: 'imageUri', label: 'image' }),
    BooleanColumn(),
    ActionsColumn({
      onClickEdit: setCmsContentModal,
      onClickDelete: showDeleteConfirm,
    }),
  ];

  return (
    <>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setCmsContentKeyword(e.target.value)}
              value={cmsContentKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              disabled={loadingCmsContents}
              onClick={() =>
                setCmsContentModal({
                  isVisible: true,
                  type: 'new',
                  item: {},
                })
              }
              style={{ float: 'right' }}
            >
              <FormatMessage id='cmsContents.addModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        rowKey='id'
        bordered
        loading={loadingCmsContents}
        title={() =>
          cmsPage &&
          `${cmsPage.title} ${intl.formatMessage({
            id: 'cmsContents.title',
          })}`
        }
        pagination={filteredCmsContents && filteredCmsContents.length > 10}
        dataSource={filteredCmsContents}
        columns={columns}
      />
      {cmsContentModal.isVisible && (
        <AddEditCmsContent
          onHide={() => {
            setCmsContentModal({
              ...cmsContentModal,
              isVisible: false,
            });
            setValidationErrors({});
          }}
          {...cmsContentModal}
          saveCmsContent={saveCmsContent}
          cmsPage={cmsPage}
          validationErrors={validationErrors}
        />
      )}
    </>
  );
};

export default CmsContents;
