import React, { useEffect, useState } from 'react';
import { Form, InputNumber, Input, Select, Switch, Modal, Row, Col, Divider } from 'antd';
import { FormatMessage } from '../../../hocs';
import intl from 'react-intl-universal';
import useCities from '../../../hooks/useCities';
import useCounties from '../../../hooks/useCounties';
import useDistricts from '../../../hooks/useDistricts';
import PasswordInput from '../../../components/formItems/passwordInput/PasswordInput';
const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddEditDealer = ({ isVisible, type, item, onHide, saveDealer, validationErrors }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [inputCities, setInputCities] = useState([]);
  const [inputCounties, setInputCounties] = useState([]);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [hasPasswordValidationError, setHasPasswordValidationError] = useState(true);
  const [passwordValue, setPasswordValue] = useState(null);
  const [deliveryType, setDeliveryType] = useState(item.deliveryType || null);

  const { cities, fetchCities, isFetchingCities, fetchingCitiesFailed } = useCities();

  const { fetchCounties, counties, isFetchingCounties, setCounties } = useCounties();
  const { fetchDistricts, districts, isFetchingDistricts, setDistricts } = useDistricts();

  useEffect(() => {
    setIsSaveButtonDisabled(passwordValue?.length > 0 ? hasPasswordValidationError : false )
  }, [passwordValue])

  useEffect(() => {
    fetchCities({ countryId: 1 });
  }, []);

  useEffect(() => {
    if (cities && cities.length > 0) {
      const result = cities.map((c) => {
        return {
          id: c.id,
          value: c.id,
          label: c.name,
        };
      });
      setInputCities([...result]);
    }
  }, [cities]);

  useEffect(() => {
    if (counties && counties.length > 0) {
      const result = counties.map((c) => {
        return {
          value: c.id,
          label: c.name,
        };
      });
      setInputCities([...result]);
    }
  }, [counties]);

  useEffect(() => {
    if (isVisible && type === 'edit') {

      var formValues = { ...item };
      formValues.address = item.address?.address;
      formValues.cityId = item.address?.cityId;
      formValues.countyId = item.address?.countyId;
      formValues.districtId = item.address?.districtId;
      formValues.phone = item.address?.phone

      form.setFieldsValue({ ...formValues });

      fillAddressField()

    } else form.resetFields();
  }, [item, isVisible, type, form]);


  const fillAddressField = async () => {
    if ( item.address && Object.keys(item.address).includes('cityId')) {
      await fetchCities({ countryId: 1 });

      if (Object.keys(item.address).includes('countyId')) {
        if (item.address.cityId) {
          await fetchCounties({ cityId: item.address.cityId });
        }
      }

      if (Object.keys(item.address).includes('districtId')) {
        if (item.address.countyId) {
          await fetchDistricts({ countyId: item.address.countyId })
        }
      }
    }
  }

  const handleOk = () => {
    if (type === 'new') {
      form
        .validateFields()
        .then(async (values) => {
          setIsLoading(true);
          console.log(values);
          const req = {
            ...values,
            cargoFirm: values.deliveryType === 'GIVLON' ? 'YURTICI' : null,
            address: {
              active: true,
              address: values.address,
              cityId: values.cityId,
              countyId: values.countyId,
              districtId: values.districtId,
              // postalCode: values.postalCode,
              phone: values.phone
            },
          };
          await saveDealer(req, 'new');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    } else if (type === 'edit') {
      form
        .validateFields()
        .then(async (values) => {
          const data = {
            ...values,
            id: item.id,
            userId: item.userId,
            cargoFirm: values.deliveryType === 'GIVLON' ? 'YURTICI' : null,
            address: {
              active: true,
              id: item.address?.id ?? null,
              address: values.address,
              cityId: values.cityId,
              countyId: values.countyId,
              districtId: values.districtId,
              // postalCode: values.postalCode,
              phone: values.phone
            },
          };
          setIsLoading(true);
          await saveDealer(data, "edit");
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }
  };

  const onDeliveryTypeChange = (e) => {
    setDeliveryType(e);
  }

  console.log('**-deliveryType', deliveryType);

  return (
    <Modal
      title={type === 'edit' ? <FormatMessage id='dealers.editModalTitle' /> : <FormatMessage id='dealers.addModalTitle' />}
      width={1000}
      visible={isVisible}
      confirmLoading={isLoading}
      onOk={() => handleOk()}
      okText={<FormatMessage id='save' />}
      onCancel={() => {
        onHide();
      }}
      okButtonProps={{ disabled:  isSaveButtonDisabled }}
      cancelText={<FormatMessage id='cancel' />}
    >
      <Form form={form} name='control-ref' initialValues={{ ...item }} layout='horizontal'>
        <Row>
          <Col span={12}>
            <FormItem
              name='name'
              validateStatus={validationErrors?.name && 'error'}
              help={validationErrors?.name}
              label={<FormatMessage id='name' />}
              {...formItemLayout}
            >
              <Input />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name='phone'
              label={<FormatMessage id='phone' />}
              validateStatus={validationErrors?.phone && 'error'}
              help={validationErrors?.phone}
              {...formItemLayout}
            >
              <InputNumber style={{ width: '100%' }} />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <FormItem
              name='identityNumber'
              label={<FormatMessage id='identityNumber' />}
              validateStatus={validationErrors?.identityNumber && 'error'}
              help={validationErrors?.identityNumber}
              {...formItemLayout}
            >
              <InputNumber style={{ width: '100%' }} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name='website'
              label={<FormatMessage id='website' />}
              validateStatus={validationErrors?.website && 'error'}
              help={validationErrors?.website}
              {...formItemLayout}
            >
              <Input />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <FormItem
              name='taxOffice'
              label={<FormatMessage id='taxOffice' />}
              validateStatus={validationErrors?.taxOffice && 'error'}
              help={validationErrors?.taxOffice}
              {...formItemLayout}
            >
              <Input />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem
              name='email'
              label={<FormatMessage id='email' />}
              validateStatus={validationErrors?.email && 'error'}
              help={validationErrors?.email}
              {...formItemLayout}
            >
              <Input />
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <FormItem
              name='mersisNo'
              label={<FormatMessage id='mersisNo' />}
              validateStatus={validationErrors?.mersisNo && 'error'}
              help={validationErrors?.mersisNo}
              {...formItemLayout}
            >
              <InputNumber style={{ width: '100%' }} />
            </FormItem>
          </Col>
          <Col span={12}>
            <PasswordInput
              form={form}
              name='password'
              type='password'
              label={'password'}
              validateStatus={validationErrors?.password && 'error'}
              help={validationErrors?.password}
              getInputValue={(value) => { setPasswordValue(value) }}
              hasValidationError={(hasError) => setHasPasswordValidationError(hasError)}
              layout={formItemLayout}
            />

          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <FormItem
              name='tradeRegisterNo'
              label={<FormatMessage id='tradeRegisterNo' />}
              validateStatus={validationErrors?.tradeRegisterNo && 'error'}
              help={validationErrors?.tradeRegisterNo}
              {...formItemLayout}
            >
              <InputNumber style={{ width: '100%' }} />
            </FormItem>
          </Col>
          <Col span={12}>
            <Form.Item
              name={'active'}
              label={<FormatMessage id='active' />}
              validateStatus={validationErrors?.active && 'error'}
              help={validationErrors?.active}
              {...formItemLayout}
            >
              <Switch defaultChecked={item.active} />
            </Form.Item>
          </Col>
        </Row>

        <Col>
          <Col span={12}>
            <FormItem
              name='deliveryType'
              label={<FormatMessage id='deliveryType' />}
              validateStatus={validationErrors?.deliveryType && 'error'}
              help={validationErrors?.deliveryType}
              {...formItemLayout}
            >
              <Select onChange={onDeliveryTypeChange}>
                <Option value={"DEALER"}>Tedarikçi</Option>
                <Option value={"GIVLON"}>Givlon</Option>
              </Select>
            </FormItem>
          </Col>

          {deliveryType === 'GIVLON' && <Col span={12}>
            <FormItem
              name='cargoFirm'
              label={<FormatMessage id='cargoFirm' />}
              validateStatus={validationErrors?.cargoFirm && 'error'}
              help={validationErrors?.cargoFirm}
              {...formItemLayout}
            >
              <Select defaultValue={"YURTICI"} disabled placeholder="Yurtiçi Kargo">
                <Option value={"YURTICI"}>Yurtiçi Kargo</Option>
              </Select>
            </FormItem>
          </Col>}
        </Col>
        <Divider orientation='left' plain style={{ fontWeight: 'bold' }}>
          Adres Bilgileri
        </Divider>
        <Row>
          <Col span={8}>
            <FormItem
              name='address'
              validateStatus={validationErrors?.address && 'error'}
              help={validationErrors?.address}
              {...formItemLayout}
              wrapperCol={{ span: 22 }}
            >
              <Input placeholder={intl.formatMessage({ id: 'address' })} />
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              name='cityId'
              validateStatus={validationErrors?.cityId && 'error'}
              help={validationErrors?.cityId}
              {...formItemLayout}
              wrapperCol={{ span: 22 }}
            >
              <Select
                showSearch
                onSelect={(id) => {
                  fetchCounties({ cityId: id });
                }}
                onChange={(id) => {
                  setCounties([]);
                  setDistricts([]);
                  form.setFieldsValue({
                    countyId: undefined,
                    districtId: undefined,
                  });
                }}
                placeholder={intl.formatMessage({ id: 'city' })}
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLocaleLowerCase('tr-TR').indexOf(input.toLocaleLowerCase('tr-TR')) >= 0}
              >
                {cities.map((c) => (
                  <Option value={c.id}>{c.name}</Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              name='countyId'
              validateStatus={validationErrors?.countyId && 'error'}
              help={validationErrors?.countyId}
              {...formItemLayout}
              wrapperCol={{ span: 22 }}
            >
              <Select
                disabled={!(counties && counties.length > 0 && !isFetchingCounties)}
                loading={isFetchingCounties}
                showSearch
                onSelect={(id) => fetchDistricts({ countyId: id })}
                onChange={(id) => {
                  setDistricts([]);
                  form.setFieldsValue({
                    districtId: undefined,
                  });
                }}
                placeholder={intl.formatMessage({ id: 'county' })}
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLocaleLowerCase('tr-TR').indexOf(input.toLocaleLowerCase('tr-TR')) >= 0}
              >
                {counties.map((c) => (
                  <Option value={c.id}>{c.name}</Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          <Col span={4}>
            <FormItem
              name='districtId'
              validateStatus={validationErrors?.districtId && 'error'}
              help={validationErrors?.districtId}
              {...formItemLayout}
              wrapperCol={{ span: 22 }}
            >
              <Select
                showSearch
                disabled={!(districts && districts.length > 0 && !isFetchingDistricts)}
                loading={isFetchingDistricts}
                placeholder={intl.formatMessage({ id: 'district' })}
                optionFilterProp='children'
                filterOption={(input, option) => option.children.toLocaleLowerCase('tr-TR').indexOf(input.toLocaleLowerCase('tr-TR')) >= 0}
              >
                {districts.map((c) => (
                  <Option value={c.id}>{c.name}</Option>
                ))}
              </Select>
            </FormItem>
          </Col>
          {/* <Col span={4}>
            <FormItem
              name='postalCode'
              validateStatus={validationErrors?.postalCode && 'error'}
              help={validationErrors?.postalCode}
              {...formItemLayout}
              wrapperCol={{ span: 22 }}
            >
              <Input placeholder={intl.formatMessage({ id: 'postalCode' })} />
            </FormItem>
          </Col> */}
        </Row>
      </Form>
    </Modal>
  );
};

export default AddEditDealer;
