import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'antd';
import { FormatMessage } from '../../../hocs';
import { SwitchItem, TextItem } from '../../../components';

const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};

const AddEditVariant = ({ isVisible, type, item, onHide, saveVariant, validationErrors }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isVisible && type === 'edit') {
      form.setFieldsValue({ ...item });
    } else form.resetFields();
  }, [item, isVisible, type, form]);

  const handleOk = () => {
    if (type === 'new') {
      form
        .validateFields()
        .then(async (values) => {
          setIsLoading(true);
          await saveVariant(values, 'new');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    } else if (type === 'edit') {
      form
        .validateFields()
        .then(async (values) => {
          const data = {
            ...values,
            id: item.id,
          };
          setIsLoading(true);
          await saveVariant(data, 'edit');
          setIsLoading(false);
        })
        .catch((errorInfo) => {
          console.log(errorInfo);
        });
    }
  };

  return (
    <Modal
      title={type === 'edit' ? <FormatMessage id='variants.editModalTitle' /> : <FormatMessage id='variants.addModalTitle' />}
      visible={isVisible}
      confirmLoading={isLoading}
      onOk={() => handleOk()}
      okText={<FormatMessage id='save' />}
      onCancel={() => {
        onHide();
      }}
      cancelText={<FormatMessage id='cancel' />}
    >
      <Form form={form} name='control-ref' initialValues={{ ...item }} layout='horizontal'>
        <TextItem name='title' label='title' validationErrors={validationErrors} layout={{ ...formItemLayout }} />
        <SwitchItem name='active' label='active' validationErrors={validationErrors} value={item.active} layout={{ ...formItemLayout }} />
      </Form>
    </Modal>
  );
};

export default AddEditVariant;
