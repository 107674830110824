import React, { useEffect } from 'react';
import { Table, Row, Col, Input, Space, Button, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined, EditOutlined, DeleteOutlined, LockOutlined } from '@ant-design/icons';

import intl from 'react-intl-universal';
import { FormatMessage } from '../../../hocs';
import GLayout from '../../gLayout';
import useUsers from '../../../hooks/useUsers';
import AddEditUser from './_add';
import ChangePasswordModal from './_changePassword';
import { RESPONSE_STATS } from '../../../utils/constants';
import { BasicColumn, BooleanColumn, IdColumn } from '../../../components';
const { confirm } = Modal;
const { Search } = Input;

const Users = () => {
  const {
    filteredUsers,
    userKeyword,
    setUserKeyword,
    fetchUsers,
    saveUser,
    isLoadingUsers,
    deleteUser,
    setUserModal,
    userModal,
    isDeletingUser,
    changePassword,
    changePasswordModal,
    setChangePasswordModal,
    saveUserResult,
    editUserResult,
    deleteUserResult,
    validationErrors,
    setValidationErrors,
  } = useUsers();

  useEffect(() => {
    fetchUsers({ type: 'ADMIN' });
  }, []);

  useEffect(() => {
    if (saveUserResult === RESPONSE_STATS.SUCCESS || editUserResult === RESPONSE_STATS.SUCCESS) fetchUsers({ type: 'ADMIN' });
  }, [saveUserResult, editUserResult]);

  useEffect(() => {
    if (deleteUserResult === RESPONSE_STATS.SUCCESS) fetchUsers({ type: 'ADMIN' });
  }, [deleteUserResult]);

  const showDeleteConfirm = (id) => {
    confirm({
      title: <FormatMessage id='users.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingUser,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteUser(id);
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const columns = [
    IdColumn(),
    BasicColumn({ name: 'email' }),
    BasicColumn({ name: 'phone' }),
    BasicColumn({ name: 'roles' }),
    BooleanColumn(),
    {
      title: <FormatMessage id='actions' />,
      key: 'action',
      width: 180,
      render: (text, record) => (
        <Space size='middle'>
          <Tooltip title={<FormatMessage id='edit' />}>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setUserModal({ type: 'edit', isVisible: true, item: record });
              }}
            />
          </Tooltip>

          <Tooltip title={<FormatMessage id='delete' />}>
            <Button danger onClick={() => showDeleteConfirm(record.id)} icon={<DeleteOutlined />}></Button>
          </Tooltip>
          <Tooltip title={<FormatMessage id='changePassword' />}>
            <Button
              onClick={() =>
                setChangePasswordModal({
                  isVisible: true,
                  userId: record.id,
                })
              }
              icon={<LockOutlined />}
            ></Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <GLayout>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setUserKeyword(e.target.value)}
              value={userKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              disabled={isLoadingUsers}
              onClick={() => setUserModal({ isVisible: true, type: 'new', item: {} })}
              style={{ float: 'right' }}
            >
              <FormatMessage id='users.addModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={filteredUsers}
        loading={isLoadingUsers}
        scroll={{ x: 1500 }}
        pagination={filteredUsers && filteredUsers.length > 10}
        sticky
      />
      {userModal.isVisible && (
        <AddEditUser
          onHide={() => {
            setUserModal({ ...userModal, isVisible: false });
            setValidationErrors({});
          }}
          {...userModal}
          saveUser={saveUser}
          validationErrors={validationErrors}
        />
      )}
      {changePasswordModal.isVisible && (
        <ChangePasswordModal
          onHide={() => setChangePasswordModal({ isVisible: false })}
          {...changePasswordModal}
          changePassword={changePassword}
          validationErrors={validationErrors}
        />
      )}
    </GLayout>
  );
};

export default Users;
