import React from "react";
import intl from "react-intl-universal";
import { message } from "antd";


export const successMessage = (id) => {
  message.success(intl.get(id, {}) || id);
};


export const errorMessage = (id) => {
  if(intl.get(id, {})){
    message.error(intl.get(id, {}));
  }else{
    message.error(id);
  }
  
};

export const errorResponseMessage = (msg) => {
  message.error(msg);
}

export const successResponseMessage = (msg) => {
  message.success(msg);
}