import React, { useEffect, useState } from 'react';
import { Form, Modal, Select } from 'antd';
import { FormatMessage } from '../../../hocs';
import { NumberItem, SelectItem, TextItem } from '../../../components';
import { COMMISSION_RECORD_TYPE, COMMISSIONS_MODAL_STATES, COMMISSIONS_MODAL_VALUE_SHOW_TYPE } from '../../../hooks/useCommissions';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 14,
    },
};

/**
 * Komisyon ekle / düzenle modal
 * 
 * @param {{itemType: COMMISSION_RECORD_TYPE}} param0 
 * @returns 
 */
const AddEditCommissions = ({
    isVisible, type, item, onHide, selectData, validationErrors, itemType,
    addCommissions, updateCommissions, fetchCommissions, callbacks, showType
}) => {

    const isModalReadOnly = showType === COMMISSIONS_MODAL_VALUE_SHOW_TYPE.READ_ONLY;

    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        form.resetFields();
        if (isVisible && type === 'edit') {
            form.setFieldsValue({ ...item });
        }
    }, [item, isVisible, type, form]);

    const addOrUpdateCommissions = async () => {
        setIsLoading(true);

        form.validateFields()
            .then(async (values) => {

                const { handleExpandBrand, handleExpandProduct } = callbacks;

                if (type === COMMISSIONS_MODAL_STATES.ADD) {

                    if(itemType === COMMISSION_RECORD_TYPE.DEALER){
                        const DTO = {
                            ratio: values.ratio,
                            dealerId: values.dealerId,
                            active: true
                        }

                        await addCommissions(DTO);
                        await fetchCommissions({})
                    } else if(itemType === COMMISSION_RECORD_TYPE.BRAND) {
                        const DTO = {
                            dealerId: item.dealerId,
                            active: true,
                            brandId: values.brandId,
                            ratio: values.ratio,
                        }

                        await addCommissions(DTO);
                        await handleExpandBrand(true, { dealerId: item.dealerId });
                    } else if(itemType === COMMISSION_RECORD_TYPE.PRODUCT) {
                        const DTO = {
                            dealerId: item.dealerId,
                            active: true,
                            brandId: item.brandId,
                            productId: values.productTitle,
                            ratio: values.ratio,
                        }

                        await addCommissions(DTO);
                        await handleExpandProduct(true, { dealerId: item.dealerId, brandId: item.brandId })
                    }

                    if (itemType === COMMISSION_RECORD_TYPE.BRAND) {
                        await handleExpandBrand(true, { dealerId: item.dealerId });
                    } else if (itemType === COMMISSION_RECORD_TYPE.PRODUCT) {
                        await handleExpandProduct(true, { dealerId: item.dealerId, brandId: item.brandId })
                    }

                    onHide()

                } else {
                    const response = await updateCommissions({ id: item.id, ratio: values.ratio });
                    await fetchCommissions({});

                    if (response.error) {
                        console.log('error', response.error)
                    }

                    if (itemType === COMMISSION_RECORD_TYPE.BRAND) {
                        await handleExpandBrand(true, { dealerId: item.dealerId });
                    } else if (itemType === COMMISSION_RECORD_TYPE.PRODUCT) {
                        handleExpandProduct(true, { dealerId: item.dealerId, brandId: item.brandId })
                    }

                    onHide()
                }
            })
            .catch(e => {
                console.log('e', e);
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    const configurations = {
        [COMMISSION_RECORD_TYPE.DEALER]: {
            inputFields: {
                select: {
                    label: 'dealerName',
                    name: 'dealerId',
                    disabled: isModalReadOnly
                },
            },
            modalTitle: {
                [COMMISSIONS_MODAL_STATES.ADD]: "Yeni Komisyon Ekle",
                [COMMISSIONS_MODAL_STATES.EDIT]: "Komisyonu Düzenle"
            },
            onSubmit: addOrUpdateCommissions
        },

        [COMMISSION_RECORD_TYPE.BRAND]: {
            inputFields: {
                select: {
                    label: 'brandTitle',
                    name: 'brandId',
                },
            },
            modalTitle: {
                [COMMISSIONS_MODAL_STATES.ADD]: "Yeni Komisyon Ekle",
                [COMMISSIONS_MODAL_STATES.EDIT]: "Komisyonu Düzenle"
            },
            onSubmit: addOrUpdateCommissions
        },

        [COMMISSION_RECORD_TYPE.PRODUCT]: {
            inputFields: {
                select: {
                    label: 'productTitle',
                    name: 'productTitle',
                },
                option: {
                    label: 'productTitle',
                }
            },
            modalTitle: {
                [COMMISSIONS_MODAL_STATES.ADD]: "Yeni Komisyon Ekle",
                [COMMISSIONS_MODAL_STATES.EDIT]: "Komisyonu Düzenle"
            },
            onSubmit: addOrUpdateCommissions
        }
    }

    const modalTitle = configurations[itemType].modalTitle[type];
    const onSubmit = configurations[itemType].onSubmit;
    const inputFields = configurations[itemType].inputFields;

    return (
        <Modal
            title={isModalReadOnly ? 'Komisyon Bilgileri': modalTitle }
            visible={isVisible}
            confirmLoading={isLoading}
            width={1000}
            onOk={() => onSubmit()}
            okText={<FormatMessage id={isModalReadOnly ? 'ok' : 'save'} />}
            onCancel={onHide}
            cancelText={<FormatMessage id='cancel' />} >

            <Form form={form} name='control-ref' initialValues={isVisible && type === COMMISSIONS_MODAL_STATES.EDIT ? { ...item } : {}} layout='horizontal'>
                {isModalReadOnly 
                ? <TextItem disabled label='productTitle' layout={{...formItemLayout}} name='title' /> 
                :<SelectItem {...inputFields.select} validationErrors={validationErrors} layout={{ ...formItemLayout }}>
                    {
                        selectData?.length > 0 && selectData.map((item, index) => {
                            return <Option disabled={item.disabled} key={index} value={item.id}>
                                { item[inputFields?.option?.label] ||  (item.title ?? item.name)}
                            </Option>
                        })
                    }
                </SelectItem>}

                <NumberItem
                    disabled={showType !== COMMISSIONS_MODAL_VALUE_SHOW_TYPE.EDITABLE}
                    name='ratio'
                    label='ratio'
                    validationErrors={validationErrors}
                    layout={{ ...formItemLayout }}
                    rules={{ min: 0, max: 0.99 }}
                />

            </Form>

        </Modal>
    );
};

export default AddEditCommissions;
