import { useState, useEffect } from "react";
import { apiGet, apiDelete, apiPost, apiPut } from "../utils/api";
import { RESPONSE_STATS } from "../utils/constants";
import _ from "lodash";
import { successMessage, errorMessage, errorResponseMessage } from "../views/elements/ToastMessage";

export default function useAttributeValues(props) {
  const [attributeValues, setAttributeValues] = useState([]);
  const [loadingAttributeValues, setLoadingAttributeValues] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [, setAttributeValuesFailed] = useState(false);
  const [isDeletingAttributeValue, setIsDeletingAttributeValue] = useState(
    false
  );
  const [filteredAttributeValues, setFilteredAttributeValues] = useState([]);
  const [attributeValueKeyword, setAttributeValueKeyword] = useState("");
  const [pagination, setPagination] = useState({});
  const [attributeValueModal, setAttributeValueModal] = useState({
    isVisible: false,
    type: "add",
    item: false,
  });

  const [, setSavingAttributeValue] = useState(false);
  const [saveAttributeValueResult, setSaveAttributeValueResult] = useState(
    RESPONSE_STATS.NOTR
  );
  const [editAttributeValueResult, setEditAttributeValueResult] = useState(
    RESPONSE_STATS.NOTR
  );
  const [deleteAttributeValueResult, setDeleteAttributeValueResult] = useState(
    RESPONSE_STATS.NOTR
  );

  useEffect(() => {
    console.log(attributeValues);
    if (attributeValues) {
      setFilteredAttributeValues(
        attributeValues.filter(
          (item) =>
            item.title?.toLowerCase().includes(attributeValueKeyword.toLowerCase()) ||
            attributeValueKeyword.length === 0
        )
      );
    }
  }, [attributeValueKeyword, attributeValues]);

  const saveAttributeValue = async (attributeId, attributeValue, type) => {
    setSavingAttributeValue(true);
    const response =
      type === "new"
        ? await apiPost(`/attributes/${attributeId}/values`, attributeValue)
        : await apiPut(`/attributes/${attributeId}/values`, attributeValue);
    setSavingAttributeValue(false);
    if (response && !response.errors && !response.error) {
      setAttributeValueModal({
        ...attributeValueModal,
        item: type === "edit" ? response.data : response,
        type: "edit",
        isVisible: false,
      });
      type === "new"
        ? setSaveAttributeValueResult(RESPONSE_STATS.SUCCESS)
        : setEditAttributeValueResult(RESPONSE_STATS.SUCCESS);
      setValidationErrors({});
      fetchAttributeValues(attributeId);
    } else if (response.errors) {
      const validErrors = {};
      response.errors.forEach((e) => {
        validErrors[e.key] = e.value;
      });
      setValidationErrors(validErrors);
    } else if(response.error) {
      errorResponseMessage(response.error)
    } else {
      setValidationErrors({});
      type === "new"
        ? setSaveAttributeValueResult(RESPONSE_STATS.FAIL)
        : setEditAttributeValueResult(RESPONSE_STATS.FAIL);
    }
  };

  const deleteAttributeValue = async (attributeId, id) => {
    setIsDeletingAttributeValue(true);
    const response = await apiDelete(`/attributes/${attributeId}/values/${id}`);
    setIsDeletingAttributeValue(false);
    if (response) {
      setDeleteAttributeValueResult(RESPONSE_STATS.SUCCESS);
      fetchAttributeValues(attributeId);
    } else {
      setDeleteAttributeValueResult(RESPONSE_STATS.FAIL);
    }
  };

  const fetchAttributeValues = async (
    attributeId,
    { page = 0, pageSize = 10 } = {}
  ) => {
    setLoadingAttributeValues(true);

    const response = await apiGet(
      `/attributes/${attributeId}/values?page=${page}&pageSize=${pageSize}`
    );
    if (response === false) {
      setAttributeValuesFailed(true);
    } else {
      if (!_.isEmpty(response)) {
        const sorted = response.sort((a, b) =>
          a.id < b.id ? 1 : b.id < a.id ? -1 : 0
        );
        setAttributeValues(sorted);
        if (sorted.length < 11) {
          setPagination(false);
        } else {
          setPagination(true);
        }
      } else {
        setAttributeValues([]);
      }
    }
    setLoadingAttributeValues(false);
  };

  useEffect(() => {
    if (saveAttributeValueResult === RESPONSE_STATS.SUCCESS) {
      successMessage("saveSuccessMessage");
    } else if (saveAttributeValueResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setSaveAttributeValueResult(RESPONSE_STATS.NOTR);
  }, [saveAttributeValueResult]);

  useEffect(() => {
    if (editAttributeValueResult === RESPONSE_STATS.SUCCESS) {
      successMessage("editSuccessMessage");
    } else if (editAttributeValueResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setEditAttributeValueResult(RESPONSE_STATS.NOTR);
  }, [editAttributeValueResult]);

  useEffect(() => {
    if (deleteAttributeValueResult === RESPONSE_STATS.SUCCESS) {
      successMessage("deleteSuccessMessage");
    } else if (deleteAttributeValueResult === RESPONSE_STATS.FAIL) {
      errorMessage("failMessage");
    }
    setDeleteAttributeValueResult(RESPONSE_STATS.NOTR);
  }, [deleteAttributeValueResult]);

  return {
    attributeValues,
    loadingAttributeValues,
    fetchAttributeValues,
    setAttributeValueModal,
    isDeletingAttributeValue,
    deleteAttributeValue,
    attributeValueKeyword,
    setAttributeValueKeyword,
    filteredAttributeValues,
    saveAttributeValue,
    attributeValueModal,
    pagination,
    validationErrors,
    setValidationErrors,
  };
}
