import React, { useEffect } from 'react';
import { Table, Row, Col, Input, Space, Button, Modal, Tooltip } from 'antd';
import { ExclamationCircleOutlined, EditOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';

import intl from 'react-intl-universal';
import { FormatMessage } from '../../../hocs';
import GLayout from '../../gLayout';
import useFaqs from '../../../hooks/useFaqs';
import AddEditFaq from './_add';
import styles from './faqs.module.css';
import { BasicColumn, BooleanColumn, IdColumn } from '../../../components';

const { confirm } = Modal;
const { Search } = Input;

const Faqs = () => {
  const {
    filteredFaqs,
    faqKeyword,
    setFaqKeyword,
    fetchFaqs,
    saveFaq,
    loadingFaqs,
    deleteFaq,
    setFaqModal,
    faqModal,
    isDeletingFaq,
    fetchInnerFaqs,
    nestedData,
    isLoading,
    validationErrors,
    setValidationErrors,
  } = useFaqs();

  useEffect(() => {
    fetchFaqs();
  }, []);

  const showDeleteConfirm = (record) => {
    confirm({
      title: <FormatMessage id='faqs.deleteConfirmMessage' />,
      icon: <ExclamationCircleOutlined />,
      okText: <FormatMessage id='okText' />,
      okType: 'danger',
      cancelText: <FormatMessage id='cancelText' />,
      confirmLoading: isDeletingFaq,
      async onOk() {
        return (async (resolve, reject) => {
          await deleteFaq(record.id, record.parentId);
          resolve();
        })().catch(() => console.log('Oops errors!'));
      },
      onCancel() {
        console.log('Close');
      },
    });
  };

  const columns = ({ level = 0 }) => [
    IdColumn(),
    BasicColumn({ name: 'title' }),
    {
      title: <FormatMessage id={'content'} />,
      dataIndex: 'content',
      key: 'content',
      ellipsis: true,
    },
    BasicColumn({ name: 'displayOrder' }),
    BooleanColumn(),
    BooleanColumn({ name: 'featured' }),
    {
      title: <FormatMessage id='actions' />,
      key: 'action',
      width: 180,
      render: (text, record) => (
        <Space size='middle'>
          <Tooltip title={<FormatMessage id='edit' />}>
            <Button
              icon={<EditOutlined />}
              onClick={() => {
                setFaqModal({
                  type: 'edit',
                  isVisible: true,
                  item: record,
                });
              }}
            />
          </Tooltip>

          <Tooltip title={<FormatMessage id='delete' />}>
            <Button danger onClick={() => showDeleteConfirm(record)} icon={<DeleteOutlined />} />
          </Tooltip>
          {level < 1 && (
            <Tooltip title={<FormatMessage id='faqs.addModalTitle' />}>
              <Button
                type='success'
                ghost
                onClick={() =>
                  setFaqModal({
                    isVisible: true,
                    type: 'new',
                    item: {},
                    faq: record,
                  })
                }
                icon={<PlusOutlined />}
              />
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    const level = record.parentId ? 1 : 0;

    const data = nestedData[record.id];
    return (
      <Table
        bordered
        showHeader={false}
        loading={isLoading[record.id]}
        columns={columns({ level: level })}
        pagination={false}
        dataSource={data}
        rowClassName={(record, index) => {
          if (level < 1) {
            return styles.rowGrey;
          }
        }}
        expandable={level < 1 && { expandedRowRender }}
        onExpand={level < 1 && handleExpand}
      />
    );
  };

  const handleExpand = async (expanded, record) => {
    console.log(expanded);
    fetchInnerFaqs({ parentId: record.id });
  };

  return (
    <GLayout>
      <div style={{ paddingBottom: 24 }}>
        <Row gutter={24}>
          <Col span={4}>
            <Search
              allowClear
              placeholder={intl.formatMessage({ id: 'search' })}
              onChange={(e) => setFaqKeyword(e.target.value)}
              value={faqKeyword}
            />
          </Col>
          <Col span={20}>
            <Button
              key='1'
              type='primary'
              disabled={loadingFaqs}
              onClick={() =>
                setFaqModal({
                  isVisible: true,
                  type: 'new',
                  item: {},
                })
              }
              style={{ float: 'right' }}
            >
              <FormatMessage id='faqs.addModalTitle' />
            </Button>
          </Col>
        </Row>
      </div>
      <Table
        bordered
        rowKey={(record) => record.id}
        columns={columns({ level: 0 })}
        dataSource={filteredFaqs}
        loading={loadingFaqs}
        scroll={{ x: 1500 }}
        expandable={{ expandedRowRender }}
        onExpand={handleExpand}
        pagination={filteredFaqs.length > 10}
        sticky
      />
      {faqModal.isVisible && (
        <AddEditFaq
          onHide={() => {
            setValidationErrors({});
            setFaqModal({ ...faqModal, isVisible: false });
          }}
          {...faqModal}
          saveFaq={saveFaq}
          validationErrors={validationErrors}
        />
      )}
    </GLayout>
  );
};

export default Faqs;
