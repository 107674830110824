import React from 'react';
import ReactDOM from 'react-dom';
import ReduxThunk from 'redux-thunk';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore, compose } from 'redux';
import reducers from './reducers';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import trTR from 'antd/lib/locale/tr_TR';

const Index = () => {
  const middlewares = [ReduxThunk];
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const composed = [applyMiddleware(...middlewares)];
  const store = createStore(reducers, {}, composeEnhancers(...composed));

  const validateMessages = { 
    min: "'${label}' en az ${$min} olmalıdır.",
    max: "'${label}' is Required!",
  };

  return (
    <React.StrictMode>
      <ConfigProvider locale={trTR} form={{validateMessages}}>
        <Provider store={store}>
          <App />
        </Provider>
      </ConfigProvider>
    </React.StrictMode>
  );
};

ReactDOM.render(<Index />, document.getElementById('root'));

reportWebVitals();
serviceWorker.unregister();
